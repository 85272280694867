(function() {

    var PowerUpViewController = function ($scope, $timeout, $sce, $location, $stateParams, filterTextFactory, dataTransferFactory, userFactory, powerupInit, userInit, colorInit, $state, animateSavedButtonFactory) {
        $scope.user = userInit;
        $scope.pUp = powerupInit.fields;
        $scope.colors = colorInit;
        dataTransferFactory.set('completedMessage', $scope.pUp.completedMessage);

        $scope.contentfuiId = powerupInit.sys.id;

        // Log this page view for analytics.
        analytics.page({
            title: 'PowerUpViewController',
            url: $location.absUrl(),
            path: $location.path(),
            referrer: document.referrer
        });

        // Add subtitles to video
        filterTextFactory.addSubtitlesToVideo($scope.pUp.video).then(function(video) {
            $scope.pUp.video = video;
        });

        /*if(!Array.isArray($scope.pUp.core)) {
            $scope.pUp.core = $scope.pUp.core.split(' + ');
        }*/

        if($state.current.name == 'powerUpViewWidget') {
            $scope.widget = true;

            $scope.chefHatLevels = ['newbie-color','fearless-foodie-color','fearless-foodie-p-color','fearless-foodie-pp-color',
                'confident-cook-color','confident-cook-p-color','confident-cook-pp-color','super-chef-color',
                'super-chef-p-color','super-chef-pp-color','monj-master-filled'];
        }

        $scope.waiting = false;
        $scope.saved = false;
        $scope.primary = false;
        $scope.secondary = false;
        $scope.tertiary = false;

        function componentToHex(c) {
            var hex = c.toString(16);
            return hex.length == 1 ? "0" + hex : hex;
        }

        function getHex(value) {
            if(value.length > 6) {
                value = value.replace('rgba', '');
                value = value.replace('rgb', '');
                value = value.replace('(', '');
                value = value.replace(')', '');
                value = value.split(',');

                return componentToHex(parseInt(value[0])) + componentToHex(parseInt(value[1])) + componentToHex(parseInt(value[2]));
            }

            return value;
        }

        if($scope.colors) {
            if($scope.colors.primary) {
                $scope.primary = getHex($scope.colors.primary.replace('#', ''));
            }
            if($scope.colors.secondary) {
                $scope.secondary = getHex($scope.colors.secondary.replace('#', ''));
            }
            if($scope.colors.tertiary) {
                $scope.tertiary = getHex($scope.colors.tertiary.replace('#', ''));
            }
        }

        /*************************************************
         * filterTextFactory methods for use in view
         */

        $scope.filterText = function(text) {
            if(text) {
                return filterTextFactory.filterText(text);
            }
            return null;
        };

        $scope.flowplayerFilter = function(embedCode) {
            return $sce.trustAsHtml(filterTextFactory.flowplayerFilter(embedCode));
        };


        $scope.updateProtocols = function(original) {
            return $sce.trustAsHtml(filterTextFactory.updateProtocols(original));
        };

        /**
         * End filterTextFactory methods
         *************************************************/

        function init() {

            $scope.saved = $scope.pUp.isSaved;

            $scope.closeFrame = dataTransferFactory.get('applicationCloseFrame');
            $scope.stringify = dataTransferFactory.get('stringify');

            if(dataTransferFactory.get('primaryColorChange')) {
                $scope.primary = dataTransferFactory.get('primaryColorChange');
            }
            if(dataTransferFactory.get('secondaryColorChange')) {
                $scope.secondary = dataTransferFactory.get('secondaryColorChange');
            }
            if(dataTransferFactory.get('tertiaryColorChange')) {
                $scope.tertiary = dataTransferFactory.get('tertiaryColorChange');
            }

            // Update colors if they have been included and is a widget
            $timeout(function() {
                if($scope.widget && $scope.primary) {
                    $('.slider-container .slider-menu').css('background-color', '#' + $scope.primary);
                    $('.drilldown > header').css('background-color', '#' + $scope.primary);
                    $('.secondary-btn').css('background-color', '#' + $scope.primary);
                }
                if($scope.widget && $scope.secondary) {
                    $('.primary-btn').css('background-color', '#' + $scope.secondary);
                    $('.border-button.primary').css('color', '#' + $scope.secondary);
                    $('.border-button.primary').css('border', '2px solid #' + $scope.secondary);
                    $('.border-button.primary').hover(function() {
                        $(this).css('background-color', '#' + $scope.secondary);
                        $(this).css('color', '#FFFFFF');
                    }, function() {
                        $(this).css('background-color', '#FFFFFF');
                        $(this).css('color', '#' + $scope.secondary);
                    });
                }
                if($scope.widget && $scope.tertiary) {
                    $('.completed-animation').css('background-color', '#' + $scope.tertiary);
                    $('.completed-animation-circle p').css('color', '#' + $scope.tertiary);
                }
            });

            dataTransferFactory.set('contentTitle', $scope.pUp.title);

            userFactory.viewPowerUp($scope.user.userMonj.userId, $stateParams.powerupId);

            /*if($scope.stringify) {
                window.parent.postMessage(JSON.stringify({event: 'widgetReady', closeWindow: false, completed: false, stringy: true}), "*");
            }
            else {
                window.parent.postMessage({event: 'widgetReady', closeWindow: false, completed: false}, "*");
            }*/
        };

        init();

        $scope.completePowUp = function() {
            $scope.answerBox = $('#answer-text-form')[0];
            var checkedValues = $('.powerup-form input[type="checkbox"]:checked');
            var submitString = "";
            for(var x = 0; x < checkedValues.length; x++) {
                submitString += checkedValues[x].value + ',';
            }
            submitString += $scope.answerBox.value;

            $scope.waiting = true;
            //console.log($scope.user.userMonj.userId, $scope.pUp.monjId, submitString);
            //console.log($scope.pUp);
            userFactory.completePowerUp($scope.user.userMonj.userId, $scope.pUp.monjId, submitString).then(function(data) {
                $scope.waiting = false;
                if($scope.widget) {
                    $timeout(function () {
                        if ($scope.closeFrame) {
							if($scope.stringify) {
								window.parent.postMessage(JSON.stringify({/*event: 'widgetClosed', */closeWindow: true, completed: true, stringy: true}), "*");
                                window.ReactNativeWebView.postMessage(JSON.stringify({/*event: 'widgetClosed', */closeWindow: true, completed: true, stringy: true}), "*");
							}
							else {
								window.parent.postMessage({/* event: 'widgetClosed', */closeWindow: true, completed: true }, "*");
                                window.ReactNativeWebView.postMessage({/* event: 'widgetClosed', */closeWindow: true, completed: true }, "*");
							}
                            //$timeout(function() {
                                //$location.path($state.href('powerUpViewWidget', {powerupId: $stateParams.powerupId}).replace('#', ''));
                                $('.completed-animation').removeClass('active');
                                $location.url($location.path());
                            //}, 400);
                        } else {
                            //$location.path($state.href('powerUpViewWidget', {powerupId: $stateParams.powerupId}).replace('#', ''));
                            $('.completed-animation').removeClass('active');
                            $location.url($location.path());
                        }
                    }, 3000);
                }
            });
        };

        $scope.goBack = function() {
            if($scope.widget) {
				if($scope.stringify) {
					window.parent.postMessage(JSON.stringify({/*event: 'widgetClosed', */closeWindow: true, completed: false, stringy: true}), "*");
                    window.ReactNativeWebView.postMessage(JSON.stringify({/*event: 'widgetClosed', */closeWindow: true, completed: false, stringy: true}), "*");
				}
				else {
					window.parent.postMessage({/*event: 'widgetClosed', */closeWindow: true, completed: false}, "*");
                    window.ReactNativeWebView.postMessage({/*event: 'widgetClosed', */closeWindow: true, completed: false}, "*");
				}
            } else {
                $location.path($state.href('powerUpViewWidget', {powerupId: $stateParams.powerupId}).replace('#', ''));
            }
        };

        $(document).ready(function() {
            $scope.placeholderImageUrl = 'url(\'' + $scope.pUp.heroImage + ($scope.isPhone ? '?' + $scope.user.userMonj.fourToThree : '?' + $scope.user.userMonj.sixteenToNine) + '&fm=jpg&q=100' + '\')';
        });

        $('#share').on("focus", function(e) {
            e.target.select();
            $(e.target).one('mouseup', function(e) {
                e.preventDefault();
            });
        });

        $scope.save = function() {
            if(!$scope.saved) {
                $scope.saved = true;
                animateSavedButtonFactory.animateOnSave();
                userFactory.savePowerUp($scope.user.userMonj.userId, $stateParams.powerupId);
                var convertedPowerUpData = [{
                    'timestamp': new Date().toLocaleString(),
                    'contentId': $scope.monjPowerUpData.powerUpId,
                    'contentType': 'PowerUp',
                    'contentTypeEnum': 7,
                    'contentName': $scope.monjPowerUpData.name,
                    'body': null,
                    'imageUrl': $scope.monjPowerUpData.thumbUrl,
                    'gameValue': $scope.monjPowerUpData.gameValue
                }];
                dataTransferFactory.set('lastSavedPowerUp', convertedPowerUpData);
            } else {
                $scope.saved = false;
                $('.action-container .action-icon.save.selected').css('transform', 'translate(0px, 0px)');
                userFactory.unsavePowerUp($scope.user.userMonj.userId, $stateParams.powerupId);
                dataTransferFactory.remove('lastSavedPowerUp');
            }
        };

        $scope.closeFrame = dataTransferFactory.get('applicationCloseFrame');
        $scope.stringify = dataTransferFactory.get('stringify');

        $scope.isMobile = function() {
            return $(window).width() <= 768;
        };
    };

    angular.module('monjApp')
        .controller('PowerUpViewController', ['$scope', '$timeout', '$sce', '$location', '$stateParams', 'filterTextFactory', 'dataTransferFactory', 'userFactory', 'powerupInit', 'userInit', 'colorInit', '$state', 'animateSavedButtonFactory', PowerUpViewController]);

}());
