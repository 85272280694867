(function() {

    var SignUpConfirmedController = function ($scope, $location, $timeout, $translate, dataTransferFactory, userFactory, $state, slidesFactory, userInit) {
        $scope.user = userInit;

        // Log this page view for analytics.
        analytics.page({
            title: 'SignUpConfirmedController',
            url: $location.absUrl(),
            path: $location.path(),
            referrer: document.referrer
        });

        $scope.currIdx = false;
        $scope.currStep = [];
        $scope.buildIdx = 'null';

        // If user is signed in an authenticated, build page objects
        $scope.pages = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

        /************************************************
         * sliderFactory methods - Used to connect back-end functionality with the view
         */

        $timeout(function() {
            $scope.steps = slidesFactory.setSteps($scope.pages);
        });

        $scope.selectStep = function(index) {
            // Skip onboarding screen if user already passed through onboarding
            checkForOnboarding();
            var shouldAnimate = Math.abs($scope.currIdx - index);
            if(shouldAnimate > 1) {
                // Stop slide animation before switching pages
                $('.slider-container .slider-content').css('transition', 'none');
                $timeout(function() {
                    // Re-enable slide animation after switching pages
                    $('.slider-container .slider-content').css('transition', 'all 400ms ease');
                }, 400);
            }

            var movingRight = $scope.currIdx - index <= -1;

            // ----------------------------
            // submit preferences to server
            //

            if($scope.currIdx == 1 && $scope.buildIdx && movingRight) {
                // Submit disease prefs
                userFactory.diseasePrefs($scope.user.userMonj.userId, $scope.userLowSalt, $scope.userLowSugar, $scope.userLowCarb, $scope.userLowCal)
                .then(function() {
                    $scope.pathStatement = getUserPrefPath($scope.user, false);
                    var diseasePrefsArr = [];
                    if($scope.user.userMonj.pref2LowSalt) diseasePrefsArr.push("salt");
                    if($scope.user.userMonj.pref2LowSugar) diseasePrefsArr.push("sugar");
                    if($scope.user.userMonj.pref2LowCarbs) diseasePrefsArr.push("carbs");
                    if($scope.user.userMonj.pref2LowCalorie) diseasePrefsArr.push("calories");
                    // $scope.diseasePath = diseasePrefsArr.join(", ");
                    let pathWithAnd = "";
                    if (diseasePrefsArr.length > 0) {
                      for (let index = 0; index < diseasePrefsArr.length; index++) {
                        const element = diseasePrefsArr[index];
                        if (index == diseasePrefsArr.length - 1) {
                          pathWithAnd += pathWithAnd ? " and " : "";
                        } else {
                          pathWithAnd += pathWithAnd ? ", " : "";
                        }
                        pathWithAnd += element;
                      }
                    }
                    $scope.diseasePath = pathWithAnd;
                    $scope.waiting = false;
                });
            }

            if($scope.currIdx == 3 && $scope.buildIdx && movingRight) {
                // Submit allergy prefs
                userFactory.allergyPrefs($scope.user.userMonj.userId, $scope.userNoDairy, $scope.userNoGluten, $scope.userNoShellfish, $scope.userNoSoy, $scope.userNoTreeNuts, $scope.userNoEggs, $scope.userNoNightshades, $scope.userNoCorn, $scope.userNoGrains, $scope.userNoMeat)
                .then(function() {
                    $scope.pathStatement = getUserPrefPath($scope.user, false);
                    $scope.waiting = false;
                });
            }
            if($scope.currIdx == 8 && $scope.buildIdx && movingRight) {
              // Submit built purpose
              userFactory.postPurpose($scope.user.userMonj.userId, $scope.buildIdx, $('.statement textarea').val());
            }
            /* 
            if($scope.currIdx == 3 && $scope.buildIdx && movingRight) {
                // Submit protein prefs
                userFactory.proteinPrefs($scope.user.userMonj.userId, $scope.userProtein=='pescatarian', $scope.userProtein=='vegan', $scope.userProtein=='vegetarian')
                .then(function() {
                    $scope.pathStatement = getUserPrefPath($scope.user);
                    $scope.waiting = false;
                });
            }

            if($scope.currIdx == 4 && $scope.buildIdx && movingRight) {
                // Submit wisdom prefs
                userFactory.setWisdomGoal($scope.user.userMonj.userId, $scope.wisdomGoalTopicId);
            } */

            // Update slides
            $scope.currStep = slidesFactory.selectStep(index);
            $scope.currIdx = slidesFactory.getCurrentIndex();
            // Custom fields
            $scope.isFinalStep = $scope.currStep[2] == undefined;
        };

        /**
         * End sliderFactory methods
         ************************************************/

        $scope.nextStep = function() {
            $scope.selectStep(slidesFactory.getCurrentIndex() + 1);
        };
        $scope.prevStep = function() {
            $scope.selectStep(slidesFactory.getCurrentIndex() - 1);
        };
        $scope.uploadImage = function() {
            $('#image-upload').click();
        }

        /**************************************************
         * Touch Navigation Directive Tools
         */
        $(document).unbind('selectNextStep').bind('selectNextStep', function () {
            $scope.nextStep();
            $scope.$apply();
        });
        $(document).unbind('selectPreviousStep').bind('selectPreviousStep', function () {
            $scope.prevStep();
            $scope.$apply();
        });
        /**
         * End Touch Navigation Directive Tools
         ************************************************/

        $scope.getStarted = function () {
            userFactory.updateTouredPages("customize", $scope.user.userMonj.userId);
            $location.path($state.get('authExperience').url);
        };

        function getUserPrefPath(user, isOnlyComma = true) {
          let prefTextArr = [];
          if (user.userMonj.pref2LowSalt) {
            prefTextArr.push("reduced salt");
          }
          if (user.userMonj.pref2LowSugar) {
            prefTextArr.push("reduced sugar");
          }
          if (user.userMonj.pref2LowCarbs) {
            prefTextArr.push("reduced carbs");
          }
          if (user.userMonj.pref2LowCalorie) {
            prefTextArr.push("reduced calories");
          }
          if (user.userMonj.pref2DairyFree) {
            prefTextArr.push("dairy free");
          }
          if (user.userMonj.pref2GlutenFree) {
            prefTextArr.push("gluten free");
          }
          if (user.userMonj.pref2ShellfishFree) {
            prefTextArr.push("shellfish free");
          }
          if (user.userMonj.pref2MeatFree) {
            prefTextArr.push("meat free");
          }
          if (user.userMonj.pref2TreeNutFree) {
            prefTextArr.push("treenut free");
          }
          if (isOnlyComma) {
            return prefTextArr.length > 0 ? prefTextArr.join(", ") : "";
          } else {
            let pathWithAnd = "";
            if (prefTextArr.length > 0) {
              for (let index = 0; index < prefTextArr.length; index++) {
                const element = prefTextArr[index];
                if (index == prefTextArr.length - 1) {
                  pathWithAnd += pathWithAnd ? " and " : "";
                } else {
                  pathWithAnd += pathWithAnd ? ", " : "";
                }
                pathWithAnd += element;
              }
            }
            if(pathWithAnd==""){
              pathWithAnd= "Anything Goes";
            }
            return pathWithAnd;
          }
        }
        function init () {
            if($scope.user.userMonj) {

                // Skip onboarding screen if user already passed through onboarding
                checkForOnboarding();
                // Initialize preferences
                $scope.wisdomGoalTopicId = $scope.user.userMonj.wisdomGoalTopicId ? $scope.user.userMonj.wisdomGoalTopicId : null;

                $scope.userLowSalt = $scope.user.userMonj.pref2LowSalt ? $scope.user.userMonj.pref2LowSalt : false;
                $scope.userLowSugar = $scope.user.userMonj.pref2LowSugar ? $scope.user.userMonj.pref2LowSugar : false;
                $scope.userLowCarb = $scope.user.userMonj.pref2LowCarbs ? $scope.user.userMonj.pref2LowCarbs : false;
                $scope.userLowCal = $scope.user.userMonj.pref2LowCalorie ? $scope.user.userMonj.pref2LowCalorie : false;

                $scope.userNoDairy = $scope.user.userMonj.pref2DairyFree ? $scope.user.userMonj.pref2DairyFree : false;
                $scope.userNoGluten = $scope.user.userMonj.pref2GlutenFree ? $scope.user.userMonj.pref2GlutenFree : false;
                $scope.userNoShellfish = $scope.user.userMonj.pref2ShellfishFree ? $scope.user.userMonj.pref2ShellfishFree : false;
                $scope.userNoSoy = $scope.user.userMonj.pref2SoyFree ? $scope.user.userMonj.pref2SoyFree : false;
                $scope.userNoTreeNuts = $scope.user.userMonj.pref2TreeNutFree ? $scope.user.userMonj.pref2TreeNutFree : false;
                $scope.userNoEggs = $scope.user.userMonj.pref2EggFree ? $scope.user.userMonj.pref2EggFree : false;
                $scope.userNoNightshades = $scope.user.userMonj.pref2NightshadeFree ? $scope.user.userMonj.pref2NightshadeFree : false;
                $scope.userNoCorn = $scope.user.userMonj.pref2CornFree ? $scope.user.userMonj.pref2CornFree : false;
                $scope.userNoGrains = $scope.user.userMonj.pref2GrainFree ? $scope.user.userMonj.pref2GrainFree : false;
                $scope.userNoMeat = $scope.user.userMonj.pref2MeatFree ? $scope.user.userMonj.pref2MeatFree : false;

                userPescatarian = $scope.user.userMonj.pref2Pescatarian ? $scope.user.userMonj.pref2Pescatarian : false;
                userVegan = $scope.user.userMonj.pref2Vegan ? $scope.user.userMonj.pref2Vegan : false;
                userVegetarian = $scope.user.userMonj.pref2Vegetarian ? $scope.user.userMonj.pref2Vegetarian : false;

                if(userPescatarian) {
                    $scope.userProtein = 'pescatarian';
                }
                else if (userVegan) {
                    $scope.userProtein = 'vegan';
                }
                else if (userVegetarian) {
                    $scope.userProtein = 'vegetarian';
                }
                else {
                    $scope.userProtein = 'any';
                }

                $scope.firstName = $scope.user.userMonj.firstName;
                $scope.pathStatement = getUserPrefPath($scope.user, false);
            }

            $scope.page = 1;
            $scope.currIdx = 0;

            $timeout(function() {
                $scope.selectStep($scope.currIdx);
            });

            // profile image uploader
            $('#image-upload').fileupload({
                dataType: 'json',
                add: function (e, data) {
                    $(".path-box #profile-image").css("background-image", "");
                    $('.path-box .my-account-item .profile-pic').css("background-image", "");
                    $('.path-box #image-progress-container').css("visibility", "visible");
                    $(".path-box #image-progress").css("width", "0").removeClass("processing").show();
                    data.submit();
                },
                progressall: function (e, data) {
                    var progress = parseInt(data.loaded / data.total * 100, 10);
                    if (progress < 100) {
                        $(".path-box #image-progress").css("width", progress + "%");
                    }
                    else {
                        $(".path-box #image-progress").css("width", "100%").addClass("processing");
                    }
                },
                done: function (e, data) {
                    $('.path-box #image-progress-container').css("visibility", "hidden");
                    $(".path-box #image-progress").hide().removeClass("processing");
                    $.each(data.result, function (index, file) {
                        $(".path-box #profile-image")
                            .css("background-image", "url(" + file.thumbnail_url + ")")
                            .show();
                        $(".ae-header-container .image-container .image-thumb")
                            .css("background-image", "url(" + file.thumbnail_url + ")")
                            .show();
                        $('.my-account-item .profile-pic')
                            .css("background-image", "url(" + file.thumbnail_url + ")")
                            .show();
                        $scope.user.userMonj.profileImageUrl = file.thumbnail_url;
                    });
                    $('#image-uploaded-message').css("display", "block");
                    
                }
            });
            // set upload to apply to that user
            var uploadUrl = userFactory.getImageUploadURL();
            $("#image-upload").fileupload("option", "url", uploadUrl);

        }

        // Skip onboarding screen if user already passed through onboarding
        function checkForOnboarding(){            
            if($scope.user.userMonj.touredCustomize) {
                $location.path($state.get('authExperience').url);
            }
        }

        init();

    };

    angular.module('monjApp')
        .controller('SignUpConfirmedController', ['$scope', '$location', '$timeout', '$translate', 'dataTransferFactory',
            'userFactory', '$state', 'slidesFactory', 'userInit', SignUpConfirmedController]);

}());
