(function () {

	angular.module('monjApp')
		.directive('term', function () {
			return {
				restrict: 'E',
				transclude: true,
				template: function(elem, attr) {
					var template = '<span ng-transclude class="link" ng-click="openTerm($event,null)"></span>';
					for(var i = 0; i < elem[0].attributes.length; i++) {
						if(elem[0].attributes[i].name=='href'){
							template = '<span ng-transclude class="link" ng-click="openTerm($event,\''+ elem[0].attributes[i].value.replace(/[']/g, "\\$&") +'\')"></span>';
							return template;
						}
					}
					return template;
				}
			};
		})
		.directive('recipeLink', function ($location, $state, userFactory) {
			return {
				restrict: 'E',
				transclude: false,
				template: function(elem, attr) {

				    var stateName = $state.current.name;
                    var template = elem.context.innerText;

				    if(attr.id){
                        template = '<a class="' + attr.id + '" target="_self">' + elem.context.innerText + '</a>';
                    }

                    userFactory.getRecipeId(userFactory.getUserData().userMonj.userId, attr.id).then(function (rid) {
                        if(stateName === 'widget404' || stateName === 'dailyMissionWidget' || stateName === 'essentialsWidget'
                            || stateName === 'powerUpViewWidget' || stateName === 'expandedRecipeWidget' || stateName === 'guidedRecipeWidget'
                            || stateName === 'expandedLessonWidget' || stateName === 'guidedLessonWidget') {
                            $('.' + attr.id).attr('href', $state.href('guidedRecipeWidget', {recipeId: rid}));
                        } else {
                            $('.' + attr.id).attr('href', $state.href('expandedRecipe', {recipeId: rid}));
                        }
                    });

                    return template;
				}
			};
		})

		.directive('powerupLink', function ($location, $state, userFactory) {
			return {
				restrict: 'E',
				transclude: false,
				template: function(elem, attr) {

				    var stateName = $state.current.name;
                    var template = elem.context.innerText;

				    if(attr.id){
                        template = '<a class="' + attr.id + '" target="_self">' + elem.context.innerText + '</a>';
                    }

                    userFactory.getPowerupId(userFactory.getUserData().userMonj.userId, attr.id).then(function (rid) {
                        if(stateName === 'widget404' || stateName === 'dailyMissionWidget' || stateName === 'essentialsWidget'
                            || stateName === 'powerUpViewWidget' || stateName === 'expandedRecipeWidget' || stateName === 'guidedRecipeWidget'
                            || stateName === 'expandedLessonWidget' || stateName === 'guidedLessonWidget') {
                            $('.' + attr.id).attr('href', $state.href('powerUpViewWidget', {powerupId: rid}));
                        } else {
                            $('.' + attr.id).attr('href', $state.href('powerUpView', {powerupId: rid}));
                        }
                    });

                    return template;
				}
			};
		})

		.directive('lessonLink', function ($location, $state, userFactory) {
			return {
				restrict: 'E',
				transclude: false,
				template: function(elem, attr) {

				    var stateName = $state.current.name;
                    var template = elem.context.innerText;

				    if(attr.id){
                        template = '<a class="' + attr.id + '" target="_self">' + elem.context.innerText + '</a>';
                    }

                    userFactory.getLessonId(userFactory.getUserData().userMonj.userId, attr.id).then(function (rid) {
                        if(stateName === 'widget404' || stateName === 'dailyMissionWidget' || stateName === 'essentialsWidget'
                            || stateName === 'powerUpViewWidget' || stateName === 'expandedRecipeWidget' || stateName === 'guidedRecipeWidget'
                            || stateName === 'expandedLessonWidget' || stateName === 'guidedLessonWidget') {
                            $('.' + attr.id).attr('href', $state.href('expandedLessonWidget', {id: rid}));
                        } else {
                            $('.' + attr.id).attr('href', $state.href('lesson', {id: rid}));
                        }
                    });

                    return template;
				}
			};
		})

		.directive('onFinishRender', ['$timeout', function ($timeout) {
			return {
				restrict: 'A',
				link: function (scope, element, attr) {
					if (scope.$last === true) {
						$timeout(function () {
							scope.$emit(attr.onFinishRender);
						});
					}
				}
			}
		}])

		.directive('ieItem', function () {
			var template = '<span class="link" ng-click="openIEItem($event)"></span>';

			return {
				restrict: 'E',
				template: template
			};
		})

		.directive('addStyle', ['$parse', function ($parse) {
			return {
				restrict: 'A',
				link: function(scope, element, attr) {
					var val = $parse(attr.addStyle)(scope).replace(/&myQuote;/g, "\'");
					if (val.length > 0) {
						element.attr('style', val);
					}
					element.removeAttr('add-style');
				}
			};
		}])

		.directive('compile', ['$compile', function ($compile) {
		    return function(scope, element, attrs) {
		        scope.$watch(
		            function(scope) {
		                // watch the 'compile' expression for changes
		                return scope.$eval(attrs.compile);
		            },
		            function(value) {
		                // when the 'compile' expression changes
		                // assign it into the current DOM
		                element.html(value ? value.toString() : '<p></p>');

		                // compile the new DOM and link it to the current scope.
		                // NOTE: we only compile .childNodes so that
		                // we don't get into infinite loop compiling ourselves
		                $compile(element.contents())(scope);
		            }
		        );
		    };
		}])

		.directive('elemReady', ['$parse', '$timeout', function($parse, $timeout) {
			return {
				restrict: 'A',
				link: function(scope, elem, attrs) {
					elem.ready(function () {
						$timeout(function() {
							var func = $parse(attrs.elemReady);
							func(scope);
						});
					})
				}
			};
		}])

        .directive('initVideo', ['$parse', '$timeout', '$state', function($parse, $timeout, $state) {
            return {
                restrict: 'A',
                link: function(scope, elem, attrs) {
                    elem.ready(function () {
                        $timeout(function() {
                        	if(attrs.initVideo) {
                                elem.css({
                                    'background-image': 'url(' + attrs.initVideo + ')'
                                });
                            }
                            var player = elem.flowplayer();
                            var api = player.data("flowplayer");

                        	if($state.current.name == 'guidedRecipe' || $state.current.name == 'guidedRecipeWidget' || $state.current.name == 'guidedLessonWidget' || $state.current.name == 'guidedLesson') {
                                if(api) {
                                    api.on("resume", function(e, api) {
                                    	player.css({ 'transform': 'translatey(15%)', 'border-radius': '0' });
                                        player.find('.fp-ratio').css('padding-top', '56.25%');
                                    });
                                }
							}

                            $(document).bind('pauseVideo', function () {
                                api.pause();
                            });
                        });
                    })
                }
            };
        }])

        .directive('initVimeo', ['$parse', '$timeout', '$state', function($parse, $timeout, $state) {
            return {
                restrict: 'A',
                link: function(scope, elem, attrs) {
                    elem.ready(function () {
                        $timeout(function() {
                            var options = {
                                id: attrs.initVimeo,
                                responsive: 1
                            };

                            var player = new Vimeo.Player(attrs.id, options);
                            var backgroundImage = elem.parent().css('background-image').replace('url(','').replace(')','').replace(/\"/gi, "");

                            player.on('play', function() {
                                jQuery.event.trigger('stopCarousel');

                                /*************************************************
                                 * Video Player API Hack
                                 */

                                elem.parent().css({ 'background-image': 'none' });
                                elem.addClass('play');

                                /**
                                 * End Video Player API Hack
                                 *************************************************/
                            });

                            $(document).bind('pauseVideo', function () {
                                player.pause();
                            });

                            player.on('ended', function () {
                                // Reset background image.
                                elem.removeClass('play');
                                elem.parent().css({ 'background-image': 'url("' + backgroundImage + '")' });
                            });
                        });
                    })
                }
            };
        }])

        .directive('sliderTouch', ['$swipe', '$timeout', function($swipe, $timeout) {
            return {
                scope: {
                    value: '=?'	// Optional data bind in both directions
                },
                link: function(scope, elem, attrs) {
                    var startPosition = 0;
                    var movement = 0;

                    function init() {
                        $swipe.bind(elem, {
                            'start': function (event) {
                                movement = 0;
                                startPosition = event.x;
                                // Remove any transition CSS to prevent movement lag
                                $('.slider-content').css('transition', 'initial');
                            },
                            'move': function (event) {
                                updateCenterPosition(event);
                                updateLeftPosition(event);
                                updateRightPosition(event);
                            },
                            'end': function (event) {
                                // Re-enable transition effects
                                $('.slider-content').css('transition', 'left 400ms, transform 400ms');
                                if (Math.abs(movement) > (document.body.clientWidth / 8)) {
                                    if (movement < 0) {
                                        jQuery.event.trigger('selectNextStep');
                                    } else {
                                        jQuery.event.trigger('selectPreviousStep');
                                    }
                                    $timeout(function() {
                                        resetPositions();
                                    }, 200);
                                } else {
                                    resetPositions();
                                }
                            }
                        });

                        /*elem.context.addEventListener("mouseleave", function(event) {
                            console.log(event);
                            //elem.context.trigger('mouseup');
                        }, false);*/
                    }

                    function updateLeftPosition(event) {
                        calcMovement(event);
                        $('.slider-content.left').css('transform', 'translate(' + movement + 'px)');
                    }

                    function updateCenterPosition(event) {
                        calcMovement(event);
                        $('.slider-content.center').css({'transform': 'translate(' + movement + 'px)' });
                    }

                    function updateRightPosition(event) {
                        calcMovement(event);
                        $('.slider-content.right').css('transform', 'translate(' + movement + 'px)');
                    }

                    function calcMovement(event) {
                        movement = event.x - startPosition;
                    }

                    function resetPositions() {
                        $('.slider-content.left').css('transform', 'translate(0%)');
                        $('.slider-content.center').css('transform', 'translate(0%)');
                        $('.slider-content.right').css('transform', 'translate(0%)');
                    }

                    elem.ready(function () {
                        init();
                    });
                }
            };

        }])

        .directive('sliderCarousel', ['$parse', '$timeout', '$interval', '$rootScope', function($parse, $timeout, $interval, $rootScope) {
            return {
                restrict: 'E',
                scope: {
                	'images': '=',
                    'video': '=',
				},
                template: '<div class="carousel-image image-{{$index}}" ng-repeat="image in images" ng-if="image" add-style="\'background-image: url(&myQuote;\' + image + \'?' + $rootScope.fourToThree + '&q=100&myQuote;)\'"></div> <div class="carousel" ng-if="images.length > 1"> <div class="slide-icon slide-{{$index}}" ng-click="goToSlide($index, true)" ng-repeat="slide in images" add-style="images[$index] ? \'background-image: url(\' + images[$index] + \'?w=64&h=48&q=75)\' : \'\'"></div> </div>',
                link: function(scope, elem, attrs) {
                	var carouselInterval = false;

                    function stopCarousel() {
                        if(carouselInterval) {
                            $interval.cancel(carouselInterval);
                        }
                    }

                    scope.goToSlide = function(index, stop) {
                        elem.find('.slide-icon').removeClass('active');
                        elem.find('.slide-' + index).addClass('active');
                        elem.find('.carousel-image').removeClass('active');
                        elem.find('.image-' + index).addClass('active');
                        if(stop) {
                            stopCarousel();
                        }
                    };

                    function initCarousel() {
                        stopCarousel();
                        $timeout(function() {
                            var images = elem.find('.carousel-image');
                            images.addClass('active');
                            elem.find('.slide-0').addClass('active');
                            var length = images.length;
                            var index = 1;
                            if(length > 1) {
                                // Start with first image;
                                scope.goToSlide(0);
                                carouselInterval = $interval(function () {
                                    var pos = index++ % length;
                                    scope.goToSlide(pos);
                                }, 5000);
                            }
                        }, 200);
                    }

                    elem.ready(function () {
                        initCarousel();
                    });
                }
            };
        }])

        .directive('contentfulSliderCarousel', ['$parse', '$timeout', '$interval', 'filterTextFactory', '$sce', '$rootScope', function($parse, $timeout, $interval, filterTextFactory, $sce, $rootScope) {
            return {
                restrict: 'A',
                /*scope: {
                    'images': '=',
                    'video': '=',
                },*/
                //template: '<div class="carousel-image image-{{$index}}" ng-repeat="image in images" ng-if="image.fields.file.url" add-style="\'background-image: url(&myQuote;\' + updateProtocols(image.fields.file.url) + \'?' + $rootScope.fourToThree + '&q=100&myQuote;)\'"><div ng-if="video && $index == images.length - 1" compile="video"></div></div> <div class="carousel" ng-if="images.length > 1"> <div class="slide-icon slide-{{$index}}" ng-click="goToSlide($index, true)" ng-repeat="slide in images" add-style="images[$index].fields.file.url ? \'background-image: url(\' + updateProtocols(images[$index].fields.file.url) + \'?w=64&h=48&q=75)\' : \'\'"></div> </div>',
                link: function(scope, elem, attrs) {
                    var carouselInterval = false;

                    function stopCarousel() {
                        if(carouselInterval) {
                            $interval.cancel(carouselInterval);
                        }
                    }

                    function startCarousel() {
                        var images = elem.find('.carousel-image');
                        images.addClass('active');
                        elem.find('.slide-0').addClass('active');
                        var length = images.length;
                        var index = 1;
                        if(length > 1) {
                            // Start with first image;
                            scope.goToSlide(0);
                            carouselInterval = $interval(function () {
                                var pos = index++ % length;
                                scope.goToSlide(pos);
                            }, 5000);
                        }
                    }

                    $(document).bind('stopCarousel', function () {
                        stopCarousel();
                    });

                    $(document).bind('startCarousel', function () {
                        startCarousel();
                    });

                    scope.goToSlide = function(index, stop) {
                        elem.find('.slide-icon').removeClass('active');
                        elem.find('.slide-' + index).addClass('active');
                        elem.find('.carousel-image').removeClass('active');
                        elem.find('.image-' + index).addClass('active');
                        if(stop) {
                            stopCarousel();
                            jQuery.event.trigger('pauseVideo');
                        }
                    };

                    scope.updateProtocols = function(original) {
                        return $sce.trustAsHtml(filterTextFactory.updateProtocols(original));
                    };

                    function initCarousel() {
                        stopCarousel();
                        $timeout(function() {
                            startCarousel();
                        }, 200);
                    }

                    elem.ready(function () {
                        initCarousel();
                    });
                }
            };
        }])

		.directive('badges', function () {
			return {
				restrict: 'A',
				scope: {
					'data': '='	// Bind data in both directions
				},
				link: function (scope, element, attr) {
					var progressCircleChildren = element.children().children();

					var outerChild = $(progressCircleChildren[0]).children();
					var outerChildCurr = $(outerChild[1]);
					var outerLimit = 628 * (50 - 11.25 / 2) / 100;

					function updateValues (item) {
						if (!item)
							return;

						var currValue = outerLimit * (1 - item.percentComplete);
						//outerChildCurr.css('stroke-dashoffset', currValue + "%");
					}

					// Find the current badge and apply the updates.
					updateValues(angular.element(element).scope().$parent.item);
				}
			};
		})

		.directive('loadingRemover', ['$timeout', '$location', function ($timeout, $location) {
		    return {
		    	restrict: 'A',
		    	priority: 100000,
		    	link: function (scope, element, attrs) {

		    		$timeout(function () {
		    			var load = $('#loading');
			            if (load) {
							// Set a global timeout to let the user know that there was a problem loading the page and to click the reload button.
							var timeToWait = 30000; // 15 seconds
							var loadPromise = $timeout(function() {
                                if($location.path().indexOf("widget") !== -1) {
                                    load.find('.initial-load-error-widget').addClass('active');
                                } else {
                                    load.find('.initial-load-error').addClass('active');//css({'opacity': 1, 'visibility': 'visible'});
                                }
								Raygun.send(new Error('Load Screen timeout expired. Hanging on API call.'));
							}, timeToWait);
			                scope.viewContentLoaded = scope.$on('$viewContentLoaded', function () {
					            // REMOVE THE LOAD SCREEN ANIMATION
					            var load = $('#loading');
					            if (load) {
					                load.removeClass('loading');
                                    if($location.path().indexOf("widget") !== -1) {
                                        load.find('.initial-load-error-widget').removeClass('active');
                                    } else {
                                        load.find('.initial-load-error').removeClass('active');//css({'opacity': 0, 'visibility': 'visible'});
                                    }
									$timeout.cancel(loadPromise);
					                setTimeout(function () {
					                  load.remove();
					                }, 550);
					                scope.viewContentLoaded();
					            }
					        });
			            }
		    		}, 0);
		    	}
		    };
		}]);

}());
