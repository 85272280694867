//hello world
(function() {

    var SavedList = function ($scope, $location, $timeout, $sce, dataTransferFactory, filterFactory, userFactory, $state, queueMeals, queueLessons, queueMissions, queuePowerups, userInit) {
        $scope.user = userInit;

        // Log this page view for analytics.
        analytics.page({
            title: 'SavedList',
            url: $location.absUrl(),
            path: $location.path(),
            referrer: document.referrer
        });

        $scope.savedDataExists = [false,false,false,false];
        $scope.noDataPhrase = ["nosavedmeals", "nosavedmissions", "nosavedpowerups", "nosaveddeepdives"];
        $scope.unsaveID;
        $scope.currTab = 1;
        $scope.shoppingList = [];

        function checkForCachedSave(cachedSaved, result) {
            // Compare locally saved data to what's returned and append that data
            if(cachedSaved) {
                var tempData = result.filter(function(data) {
                    return data.contentId == cachedSaved[0].contentId;
                });
                if(tempData.length == 0) {
                    result.push(cachedSaved[0]);
                }
                cachedSaved = result;
            } else {
                // If no data was saved recently, assign the result like normal
                cachedSaved = result;
            }
            return cachedSaved;
        }

        // Get the immediate data (in case the API call hasn't had a chance to update yet)
        // Then compare that data to make sure they don't repeat and append the remainder data to the object

        $scope.missions = dataTransferFactory.get('lastSavedMission');
        $scope.savedMeals = dataTransferFactory.get('lastSavedMeal');
        $scope.savedDeepDives = dataTransferFactory.get('lastSavedLesson');
        $scope.savedPowUps = dataTransferFactory.get('lastSavedPowerUp');

        $scope.savedMeals = checkForCachedSave($scope.savedMeals, queueMeals);
        $scope.savedPowUps = checkForCachedSave($scope.savedPowUps, queuePowerups);
        $scope.savedDeepDives = checkForCachedSave($scope.savedDeepDives, queueLessons);
        $scope.missions = checkForCachedSave($scope.missions, queueMissions);

        dataTransferFactory.remove('lastSavedMission');
        dataTransferFactory.remove('lastSavedMeal');
        dataTransferFactory.remove('lastSavedLesson');
        dataTransferFactory.remove('lastSavedPowerUp');

        $scope.calcMissionHeightsOnSwitch = function() {
            if($scope.missions) {
                $timeout(function () {
                    for (i = 0; i <= $scope.missions.length; i++) {
                        calcMissionHeights(i);
                    }
                });
            }
        };

        $( window ).resize(function() {
            filterFactory.updateLabelHeights($('.item-grid'));
            $scope.calcMissionHeightsOnSwitch();
        });

        $scope.missionFlipToggle = function(index) {
            calcMissionHeightsOnFlip(index);
            var selectedElem = $('.flip-index-'+index);
            selectedElem.toggleClass('flipped');
            selectedElem.addClass('animate-experience');
        };

        $scope.setUnsaveId = function(id) {
          if(id){
            $scope.unsaveID = id;
          }
        };

        // Unsave mission when the user clicks the ACCEPT  button a second time.
        $scope.unsaveMission = function() {
            if($scope.unsaveID) {
                userFactory.postUnsaveMission($scope.user.userMonj.userId, $scope.unsaveID);
                $scope.missions.forEach(function(data, index) {
                    if(data.contentId == $scope.unsaveID) {
                        $scope.missions.splice(index, 1);
                        calcMissionHeights();
                        if($scope.missions.length < 1) {
                            $scope.savedDataExists[1] = false;
                        }
                    }
                });
            }
            $scope.unsaveID = null;
        };

        $scope.completeDailyMission = function(contentId, isAlreadyComp) {
          if(!isAlreadyComp) {
            if (!contentId) {
                // complete daily
                userFactory.postCompleteMission($scope.user.userMonj.userId, $scope.mission.content.missionId);
            } else {
                // complete specific mission from saved list
                userFactory.postCompleteMission($scope.user.userMonj.userId, contentId);
            }

            // if missions are now empty, return to empty state
            if ($scope.missions.length == 0) {
              $scope.savedDataExists[1] = false;
            }

            // return all missions to their unflipped state
            $('.ae-mission-flipper').each(function(i, e) {
                if ($(e).hasClass('flipped')) {
                    $scope.missionFlipToggle(i);
                }
            });
          }
        };

        // Calculate Mission Heights on flip
        function calcMissionHeights(index) {
            var container = $('.flip-index-'+index);
            var frontHeight = container.find('.front').outerHeight();
            var backHeight = container.find('.back').outerHeight();

            if(frontHeight && backHeight) {
                if (container.hasClass('flipped')) {
                    container.height(backHeight);
                } else {
                    container.height(frontHeight);
                }

                $('#missions-tab').masonry('layout');
            }

            $scope.waiting = false;
        }

        function calcMissionHeightsOnFlip(index) {
            var container = $('.flip-index-'+index);
            var frontHeight = container.find('.front').outerHeight();
            var backHeight = container.find('.back').outerHeight();

            if(frontHeight && backHeight) {
                if (!container.hasClass('flipped')) {
                    container.height(backHeight);
                } else {
                    container.height(frontHeight);
                }

                $('#missions-tab').masonry('layout');
            }
        }

        $scope.getHtml = function(html){
            return $sce.trustAsHtml(html);
        };

        $scope.redirectToPowerUpView = function (powerUpId) {
            $scope.waiting = true;
            $location.path($state.href('powerUpView', {powerupId: powerUpId}).replace('#', ''));
        };

        $scope.redirectToExpandedRecipe = function(recipeId) {
            $scope.waiting = true;
            $location.path($state.href('expandedRecipe', {recipeId: recipeId}).replace('#', ''));
        };

        $scope.redirectToDeepDive = function (id) {
            $scope.waiting = true;
            $location.path($state.href('lesson', {id: id}).replace('#', ''));
        };

        $scope.switchTab = function(tNum) {
            $('.tab-link').removeClass('active');
            $('#tab-' + tNum).addClass('active');
            $('.saved-num').removeClass('active-num');
            $('#saved' + tNum).toggleClass('active-num');
            $scope.currTab = tNum;
            // Check for heights of recipe labels after data loads
            filterFactory.updateLabelHeights($('.item-grid'));
        };

        $scope.flip = function(id) {
            $(id).toggleClass('hover');
            $(id).toggleClass('mission-front');
            $(id).toggleClass('mission-back');

            setTimeout(function(){
                $(id).children().children().toggleClass('dont-show');
            }, 300);
        };

        $scope.removeListItem = function(recipeId) {
            $scope.waiting = true;
            // Delete item from list
            userFactory.deleteShoppingList($scope.user.userMonj.userId, recipeId).then(function() {
                // Update list
                userFactory.getShoppingLists($scope.user.userMonj.userId).then(function(result) {
                    $scope.waiting = false;
                    $scope.shoppingList = result;
                    checkAgainstShoppingList(result);
                });
            });
        };

        $scope.clearListItems = function() {
            $scope.waiting = true;
            // Delete item from list
            userFactory.deleteShoppingLists($scope.user.userMonj.userId).then(function() {
                // Update list
                userFactory.getShoppingLists($scope.user.userMonj.userId).then(function(result) {
                    $scope.waiting = false;
                    $scope.shoppingList = result;
                    checkAgainstShoppingList(result);
                });
            });
        };

        $scope.shoppingListSent = false;
        $scope.sendLists = function() {
            $scope.waiting = true;
            userFactory.sendShoppingLists($scope.user.userMonj.userId).then(function() {
                $scope.waiting = false;
                $scope.shoppingListSent = true;
                $timeout(function() {
                    $scope.toggleShoppingList();
                    $scope.shoppingListSent = false;
                }, 4000);
            });
        };

        $scope.displayShoppingList = false;
        $scope.toggleShoppingList = function() {
            if($scope.displayShoppingList) {
                $scope.displayShoppingList = false;
            } else {
                $scope.displayShoppingList = true;
            }
        };

        function checkAgainstShoppingList(shoppingList) {
            for(var x = 0; x < $scope.savedMeals.length; x++) {
                $scope.savedMeals[x].isOnShoppingList = false;
                for(var y = 0; y < shoppingList.length; y++) {
                    if($scope.savedMeals[x].contentId === shoppingList[y].recipeId) {
                        $scope.savedMeals[x].isOnShoppingList = true;
                    }
                }
            }
        }

        $scope.addToShoppingList = function(recipeId) {
            $scope.waiting = true;
            userFactory.saveShoppingList($scope.user.userMonj.userId, recipeId).then(function() {
                userFactory.getShoppingLists($scope.user.userMonj.userId).then(function(result) {
                    $scope.waiting = false;
                    $scope.shoppingList = result;
                    checkAgainstShoppingList(result);
                    animateShoppingList();
                });
            });
        };

        function animateShoppingList() {
            // Add notification number to shopping list and animate it
            $('.shopping-list-notifications').addClass('active');
            $timeout(function() {
                $('.shopping-list-notifications').removeClass('active');
            }, 400);

        }

        function init() {

            userFactory.getShoppingLists($scope.user.userMonj.userId).then(function(result) {
                $scope.shoppingList = result;
                checkAgainstShoppingList(result);
            });

            if($scope.savedMeals) {
                $scope.queueMealsCompleted = true;
                if($scope.savedMeals.length > 0) { $scope.savedDataExists[0] = true; }
            }
            if($scope.savedDeepDives) {
                $scope.queueEssentialsCompleted = true;
                if($scope.savedDeepDives.length > 0) { $scope.savedDataExists[3] = true; }
            }
            if($scope.missions) {
                $scope.queueMissionsCompleted = true;
                $scope.calcMissionHeightsOnSwitch();
                if($scope.missions.length > 0) { $scope.savedDataExists[1] = true; }
            }
            if($scope.savedPowUps) {
                $scope.queuePowerUpCompleted = true;
                if($scope.savedPowUps.length > 0) { $scope.savedDataExists[2] = true; }
            }

            for(var i = 0; i <= $scope.savedDataExists.length; i++) {
                if($scope.savedDataExists[i] == true) {
                    $timeout(function() {
                        $scope.switchTab(i);
                        $('#tab-' + i).parent().addClass('active');
                    });
                    break;
                } else {
                    $timeout(function() {
                        $('#tab-1').parent().addClass('active');
                    });
                }
            }

            // Check for heights of recipe labels after data loads
            $timeout(function() {
                filterFactory.updateLabelHeights($('.item-grid'));
            });

            if($location.hash() === "savedList") {
                $scope.toggleShoppingList();
            }
        };

        init();
    };

    angular.module('monjApp')
        .controller('SavedList', ['$scope', '$location', '$timeout', '$sce',
            'dataTransferFactory', 'filterFactory', 'userFactory', '$state', 'queueMeals', 'queueLessons', 'queueMissions', 'queuePowerups', 'userInit', SavedList]);

}());
