/**
 * Created by Jared Beckerman on 4/20/2017.
 */

(function () {

    var animateSavedButtonFactory = function ($timeout) {
        var factory = {};

        factory.animateOnSave = function(id, iconElem, hamburgerElem) {
            // Default Values if nothing is passed in
            id = id ? id : '#saved-list';
            iconElem = iconElem ? iconElem : '.action-container .action-icon.save.selected';
            hamburgerElem = hamburgerElem ? hamburgerElem : '.header-container .hamburger-menu-icon';
            // Calculate positions and Animate star icon
            var savedListElem = $(id);
            var savedIconElem = $(iconElem);
            var hamIconElem = $(hamburgerElem);
            var x = 0;
            var y = 0;
            if($(window).width() < 1006) {
                x = hamIconElem.offset().left - savedIconElem.offset().left - 20;
                y = hamIconElem.offset().top - savedIconElem.offset().top;
                // Flash the hamburger menu section
                $timeout(function() {
                    hamIconElem.toggleClass('flash');
                }, 400).then(function() {
                    $timeout(function() {
                        hamIconElem.removeClass('flash');
                    }, 800);
                });
            } else {
                x = savedListElem.offset().left - savedIconElem.offset().left + 20;
                y = savedListElem.offset().top - savedIconElem.offset().top + 20;
                // Flash the saved section
                $timeout(function() {
                    savedListElem.addClass('flash');
                }, 400).then(function() {
                    $timeout(function() {
                        savedListElem.removeClass('flash');
                    }, 400);
                });
            }
            savedIconElem.css('transform', 'translate('+x+'px, '+y+'px)');
            // End star icon animation
        }

        return factory;
    };

    angular.module('monjApp').factory('animateSavedButtonFactory', ['$timeout', animateSavedButtonFactory]);

}());
