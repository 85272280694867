(function () {

    var RegistrationRedirectController = function ($scope, $location, $state) {
        $location.path($state.get('publicExperience').url);
    };

    angular.module('monjApp')
        .controller('RegistrationRedirectController', ['$scope', '$location', '$state', RegistrationRedirectController]);

}());
