(function() {

    var SignUpController = function ($scope, $log, $location, $timeout, $translate, dataTransferFactory, userFactory, $state) {
        $scope.user = userFactory.getUser();
        $scope.workEmail = "";
        $scope.email = "";
        $scope.errorMsg = { visible: false, msg: "" };
        $scope.waiting = false;
        $scope.isRecipe = false;
        $scope.activationEmailSent = false;

        // Log this page view for analytics.
        analytics.page({
            title: 'SignUpController',
            url: $location.absUrl(),
            path: $location.path(),
            referrer: document.referrer
        });

        function init () {
            $scope.workEmail = dataTransferFactory.get('workEmail');
            $scope.email = dataTransferFactory.get('email');
            $scope.company = dataTransferFactory.get('company');
            $scope.demoReset = dataTransferFactory.get('demoReset');

            if($scope.demoReset) {
                // Wait 20 seconds before allowing user to continue
                $timeout(function() {
                    $scope.demoReset = false;
                    dataTransferFactory.set('demoReset', false);
                    //$('.welcome-screen-animation').
                }, 5000);

                jQuery({ Counter: 5 }).animate({ Counter: 0 }, {
                    duration: 5000,
                    easing: "linear",
                    step: function () {
                        $('.welcome-screen-animation .demo-counter').text(Math.ceil(this.Counter));
                    }
                });
            }

            dataTransferFactory.remove('company');
            dataTransferFactory.remove('workEmail');
            dataTransferFactory.remove('email');

            if ($location.path() == $state.get('signUpConfirmed').url) {
                //$('body').css({'overflow-x': 'hidden'});

                if($scope.user.userMonj) {
                    // Initialize dietary values
                    $scope.userDairy = $scope.user.userMonj.dairyFreePref;
                    $scope.userGluten = $scope.user.userMonj.glutenFreePref;
                    $scope.userSugar = $scope.user.userMonj.lowSugarPref;
                    $scope.userMeat = $scope.user.userMonj.noRedMeatPref;
                    $scope.userVeg = $scope.user.userMonj.vegetarianPref;
                }

                //$('#app-wrapper').addClass('pe-max-width-override');
                $scope.page = 1;

                // $scope.user = userFactory.updateMonj();
                $scope.user = userFactory.getUserData();

                // Reset badge snapshot or remove it if there is nothing to take a snapshot with (no user object)
                $scope.user.badgeFlag = false;
                /*if($scope.user) {
                    dataTransferFactory.set('oldUserBadges', $scope.user.userMonj.badges);
                } else {
                    dataTransferFactory.remove('oldUserBadges');
                }*/

                var recId = dataTransferFactory.get('signUpRecipeId');
                $scope.recipeId = recId;

                if (recId) {
                    dataTransferFactory.remove('signUpRecipeId');

                    //$scope.recipe = recipesFactory.getRecipe(recId);
                }
            }
            else if ($location.path() == $state.get('whySignUp').url) {
                $scope.showFeatureMsg = dataTransferFactory.get('showFeatureMsg');
                dataTransferFactory.remove('showFeatureMsg');

                var recipeId = dataTransferFactory.get('recipeId');
                if (recipeId) {
                    //$scope.recipe = recipesFactory.getRecipe(recipeId);
                    $scope.isRecipe = true;
                }
            }
            else if ($location.path() == $state.get('signUpCheck').url) {
                $scope.company = dataTransferFactory.get('checkedCompany');
                dataTransferFactory.remove('checkedCompany');
                $scope.fromPublicSignup = $scope.company ? true : false;
            }

            // get all translated error messages
            $scope.ermsg = [];
            $translate('errorMsgs.emailAlreadyRegistered').then(function (translation) {
                $scope.ermsg[0] = translation;
            });
            $translate('errorMsgs.noServer').then(function (translation) {
                $scope.ermsg[1] = translation;
            });
            $translate('errorMsgs.invalidCompany').then(function (translation) {
                $scope.ermsg[2] = translation;
            });
            $translate('errorMsgs.invalidEmail').then(function (translation) {
                $scope.ermsg[3] = translation;
            });
        }

        init();

        $scope.redirectTo = function (url, id, prev) {
            if (id)
                $location.path($state.href(url, {id: id}).replace('#', ''));
            else if (prev) {
                var prevURL = dataTransferFactory.get('prevPage');
                var parentURL = dataTransferFactory.get('parentPage');
                var currURL = $location.path();

                if (prevURL && prevURL.url && parentURL && currURL != parentURL.url)
                    $location.path($state.get(prevURL.url).url);
                else
                    $location.path($state.get(url).url);
            }
            else
                $location.path($state.get(url).url);
        };

        function scrollToTop() {
          //$('#welcome-tour-parent')[0].scrollTop = 0;
          /*if($('#welcome-tour-parent')[0].scrollTop != 0) {
            $('#welcome-tour-parent')[0].scrollTop = $('#welcome-tour-parent')[0].scrollTop - 50;
            $timeout(function(){
              scrollToTop();
            }, 10);
          }*/
        }

        $scope.goToPage = function(num) {
            $scope.page = num;
      			/*if($('.skillbox'+$scope.page).length > 0 && !$scope.isSet[$scope.page - 1]){
      				$scope.isSet[$scope.page - 1] = !$scope.isSet[$scope.page - 1];
      				$scope.setHeights();
      			}*/
            scrollToTop();
        };

        $scope.signUpBtn = function (workEmail) {
            if ($scope.waiting == false) {
                $scope.waiting = true;
                if (workEmail) {
                    $scope.errorMsg.visible = false;
                    $scope.errorMsg.msg = "";
                    $scope.ssoEnabled = false;

                    var company = workEmail.split('@')[1];
                    // check if the email was already used
                    userFactory.checkUserByCompanyEmail(workEmail).then(function (response) {
                        if (response.found) {
                            $scope.errorMsg.msg = $scope.ermsg[0];
                            //$scope.errorMsg.msg = "Sorry, but that email address has already been registered.";
                            $scope.errorMsg.visible = true;
                            $scope.waiting = false;
                        } else {
                            // check if the company is registered
                            userFactory.checkCompanyDomain(company).then(function (data) {
                                dataTransferFactory.set('workEmail', workEmail);

                                if (data.found) {
                                    $scope.companyId = data.companyId;
                                    if(data.ssoEnabled) {
                                        //dataTransferFactory.set('company', data);
                                        $scope.waiting = false;
                                        $scope.ssoEnabled = true;
                                        $scope.ssoUrl = data.ssoUrl;
                                        $scope.ssoCompanyName = data.companyName;
                                        $scope.ssoPlatformName = data.ssoPlatformName;
                                        $('.modal-background').click(function() {
                                            $scope.resetForms();
                                        });
                                        $('.popup-modal .close-btn').click(function() {
                                            $scope.resetForms();
                                        });
                                        /*$timeout(function() {
                                            $scope.waiting = false;
                                            $scope.ssoEnabled = false;
                                            $scope.ssoUrl = false;
                                            $scope.ssoCompanyName = false;
                                            $scope.ssoPlatformName = false;
                                        }, 1000);*/
                                    } else {
                                        userFactory.sendActivationCode(data.companyId, workEmail).then(function() {
                                            $scope.activationEmailSent = true;
                                            $scope.waiting = false;
                                            $scope.companyName = data.companyName;
                                        }, function (error) {
                                            $scope.errorMsg.msg = $scope.ermsg[1];
                                            //$scope.errorMsg.msg = "Sorry, there was a problem contacting the server. Please try again in a few minutes.";
                                            $scope.errorMsg.visible = true;
                                            $scope.waiting = false;
                                        });
                                        dataTransferFactory.set('company', data);
                                        /*$location.path('/signup-sponsored');
                                        $scope.waiting = false;*/
                                    }
                                } else {
                                    /*dataTransferFactory.remove('company');
                                    $location.path('/signup-not-sponsored');*/
                                    $scope.errorMsg.msg = $scope.ermsg[2];
                                    //$scope.errorMsg.msg = "Sorry, but your company is not yet eligible.";
                                    $scope.errorMsg.visible = true;
                                    $scope.waiting = false;
                                }
                            });
                        }
                    });
                } else {
                    $scope.errorMsg.msg = $scope.ermsg[4];
                    //$scope.errorMsg.msg = "Sorry, but that email address is invalid.";
                    $scope.errorMsg.visible = true;
                    $scope.waiting = false;
                }
            }
        };

        $scope.resetForms = function() {
            $timeout(function() {
                $scope.waiting = false;
                $scope.ssoEnabled = false;
                $scope.ssoUrl = false;
                $scope.ssoCompanyName = false;
                $scope.ssoPlatformName = false;
            }, 300);
        };

        $scope.ssoRedirect = function(url) {
            window.location.assign(url);
        };

        $scope.getStarted = function (userGluten, userVeg, userDairy, userSugar, userMeat) {
            //$scope.waiting = true;
            $scope.saveDietaryPrefs(userGluten, userVeg, userDairy, userSugar, userMeat);
            userFactory.updateTouredPages("customize", $scope.user.userMonj.userId);
            $location.path($state.get('authExperience').url);
            //location.reload();
        };

        $scope.saveDietaryPrefs = function(userGluten, userVeg, userDairy, userSugar, userMeat) {
            $scope.waiting = true;
            /* userFactory.dietaryPrefs($scope.user.userMonj.userId, userGluten, userVeg, userDairy, userSugar, userMeat).then(function() {
                //$scope.waiting = false;
            }); */

            //Update the new preference flags to reflact to mygoals page preferences
            userFactory.diseasePrefs($scope.user.userMonj.userId, false, userSugar, false, false)
            .then(function() {
            });
            //Update the new preference flags to reflact to mygoals page preferences
            userFactory.allergyPrefs($scope.user.userMonj.userId, userDairy, userGluten, false, false, false, false, false, false, false, userVeg)
            .then(function() {
            });
        }

    };

    angular.module('monjApp')
      .controller('SignUpController', ['$scope', '$log', '$location', '$timeout', '$translate', 'dataTransferFactory',
                                'userFactory', '$state', SignUpController]);

}());
