(function() {

    var ChallengeAllTeamsController = function ($scope, userFactory, teamsInit, userInit) {
        $scope.user = userInit;
        $scope.teams = teamsInit;

        $scope.waiting = false;

        function init() {
        }

        init();
    };

    angular.module('monjApp')
        .controller('ChallengeAllTeamsController', ['$scope', 'userFactory', 'teamsInit', 'userInit', ChallengeAllTeamsController]);

}());
