(function () {

    var RibbonController = function ($scope, $stateParams, $location, $timeout, userFactory, dataTransferFactory, $state) {
        $scope.user = userFactory.getUserData();
    	$scope.closing = false;
        $scope.showConfirm = false;
        $scope.awardData = [];
        $scope.waiting = false;

        $scope.user.chefHatLevels = ['newbie-color','fearless-foodie-color','fearless-foodie-p-color','fearless-foodie-pp-color',
            'confident-cook-color','confident-cook-p-color','confident-cook-pp-color','super-chef-color',
            'super-chef-p-color','super-chef-pp-color','monj-master-filled'];

        var commentWasSent = false; // To prevent duolications

        function flipRecipe(data) {
            if(data) {
                $('.flip-container').addClass('flipped');
                $('.flip-container .badge-item').addClass('show');
                // Initialize Awards Animation?
                $scope.user.widgetAwardData = data.outcome;
                //$timeout(function() {
                    $location.path($state.href('expandedRecipeWidget', {recipeId: $stateParams.recipeId}).replace('#', ''));
                //}, 3000);
            } else {
                // if there is no outcome data, return to expanded recipe widget page
                $location.path($state.href('expandedRecipeWidget', {recipeId: $stateParams.recipeId}).replace('#', ''));
            }
        }

        $scope.ribbonBtnClick = function (type, id) {

            dataTransferFactory.set('removeFollowup', true);
            dataTransferFactory.remove('guidedRecipeCarouselPosition');

            $('#ribbon').addClass('closed');
            $('#' + id).addClass('clicked');

            var like = false;
            if(type == 'yes') { like = true; } else { like = false; }

            var monjRecipeId = $stateParams.recipeId;
            document.cookie = ""+ monjRecipeId +"=; expires=Thu, 01 Jan 1970 00:00:00 UTC";
            $scope.waiting = true;
            userFactory.postCookMeal($scope.user.userMonj.userId, monjRecipeId, like).then(function(data) {
                $scope.waiting = false;
                if($state.current.name == 'guidedRecipeWidget') {
                    // If user is using the widget, do not redirect them to the home page.
                    flipRecipe(data);
                }
            });

            if (type == 'yes') {
                $scope.closing = true;
                /*if($state.current.name != 'guidedRecipeWidget') {
                    $location.path($state.get('authExperience').url);
                }*/
            }
            else { // type == 'no'
                var commentBox = $('#gr-comment');
                commentBox.removeClass('ng-hide');
            }
        };

        $scope.goBack = function () {
            if ($scope.finished == false)
                $scope.user = userFactory.updateMonj();

            $scope.ribbonOppened = false;
            //if(localStorage) {
                var monjRecipeId = dataTransferFactory.get('monjRecipeId');
                //localStorage.removeItem(monjRecipeId);
            document.cookie = ""+ monjRecipeId +"=; expires=Thu, 01 Jan 1970 00:00:00 UTC";
            //}
            if($state.current.name != 'guidedRecipeWidget') {
                $location.path($state.href('expandedRecipe', {recipeId: $stateParams.recipeId}).replace('#', ''));
            } else {
                // If user is using the widget, do not redirect them to the home page.
                flipRecipe();
            }
        };

        $scope.closeCommentBox = function () {
            var commentBox = $('#gr-comment');
            commentBox.addClass('ng-hide');

            if($state.current.name != 'guidedRecipeWidget') {
                $location.path($state.get('authExperience').url);
            } else {
                // If user is using the widget, do not redirect them to the home page.
                flipRecipe();
            }
        };

        $scope.sendComment = function (comment) {
            if (!comment || comment.length == 0) {
                $scope.commentFieldError = true;
                return;
            }

            dataTransferFactory.set('removeFollowup', true);

            if (!commentWasSent) {
                commentWasSent = true;

                userFactory.submitComment($scope.user.userMonj.userId, $scope.recipe.recipeId, comment)
                    .then(function () { });

                $scope.showConfirm = true;
                $scope.closing = true;

                $timeout(function () {
                    $scope.closeCommentBox();
                    commentWasSent = false;
                }, 2000);
            }
        }
    };

    angular.module('monjApp')
        .controller('RibbonController', ['$scope', '$stateParams', '$location', '$timeout', 'userFactory', 'dataTransferFactory', '$state', RibbonController]);

}());
