(function() {

    var HowToController = function ($scope, $timeout, $translate, $location, dataTransferFactory, filterFactory, userFactory, $state, resourceLibraryInit, termsInit, userInit) {
        $scope.user = userInit;
        $scope.terms = termsInit;
        $scope.videos = resourceLibraryInit;

        // Log this page view for analytics.
        analytics.page({
            title: 'HowToController',
            url: $location.absUrl(),
            path: $location.path(),
            referrer: document.referrer
        });

        $scope.waiting = false;

        // Check to see if the user was routed from a challenge, and if so, grab the ID data passed in
        /*$scope.challengeData = dataTransferFactory.get('challengeData');
        // Remove challenge data once it's been retrieved. User should only see challenge data if routed from their team challenge page
        dataTransferFactory.remove('challengeData');*/

        var chalObj = $scope.user.userMonj.challenge;
        if(chalObj) {
            if(chalObj.daysLeftToEnroll <= 0 && chalObj.daysUntilChallengeEnds > 0 && chalObj.team.teamId) {
                for(var x = 0; x < chalObj.team.teamRules.length; x++) {
                    if(chalObj.team.teamRules[x].contentType == "essential") {
                        $scope.challengeData = chalObj.team.teamRules[x].validContentIds;
                    }
                }
            }
        }

        $scope.chalDataArray = [];
        $scope.chalFilt = false;

        if($scope.challengeData){
          $scope.chalFilt = true;
          $('.tab-link').removeClass('active');
          for(var x = 0; x < $scope.challengeData.length; x++){
            $scope.chalDataArray[$scope.challengeData[x]] = true;
          }
        }

        $scope.saved = false;
        $scope.guides = [];
        //$scope.terms = [];
        $scope.currCase = 2;
        //$scope.videos = [];

        $scope.transparentGuides = [];


        $scope.completedDeepDives = {};

        $scope.showEssen = true;

        function init() {

            $scope.waiting = true;

            $scope.user.userMonj.completedModuleIds.forEach(function (e) {
              $scope.completedDeepDives[e] = true;
            });

            userFactory.getEssentials($scope.user.userMonj.userId).then(function(data) {
                $scope.waiting = false;
                $scope.guides = data;
            });

            /*if($scope.terms) {
                $scope.terms.sort(function (a, b) {
                    return (a.entry > b.entry) ? 1 : ((b.entry > a.entry) ? -1 : 0);
                });
            }*/

            if(window.history.backToVid) {
              $scope.currCase = 1;
              $('#deep-dives-tab').toggleClass('active');
              $('#videos-tab').toggleClass('active');
            }

            // Check for heights of recipe labels after data loads
            $timeout(function() {
                filterFactory.updateLabelHeights($('.item-grid'));
            }, 100);
        };

        init();

        $( window ).resize(function() {
            filterFactory.updateLabelHeights($('.item-grid'));
        });

        $('#share').on("focus", function(e) {
          e.target.select();
          $(e.target).one('mouseup', function(e) {
            e.preventDefault();
          });
        });

        $scope.redirectToEssentials = function (essentialsID) {
            $scope.waiting = true;
            $location.path($state.href('essentials', {essentialsID: essentialsID}).replace('#', ''));
        };

        $scope.redirectToVideo = function (id) {
            $scope.waiting = true;
            $location.path($state.href('howTosVideo', {id: id}).replace('#', ''));
        };

        $scope.switchCase = function(cs) {
          if(cs == 3) {
            cs = 2;
            $scope.chalFilt = true;
          } else {
            $scope.chalFilt = false;
          }
          if(cs == 1) {
            window.history.backToVid = true;
          } else {
            window.history.backToVid = false;
          }
          $timeout(function() {
            $scope.currCase = cs;
          });

            // Check for heights of recipe labels after data loads
            $timeout(function() {
                filterFactory.updateLabelHeights($('.item-grid'));
            });
        };

        $scope.isLeft = function(value, index, array) {
          return index < array.length / 2;
        };

        $scope.isRight = function(value, index, array) {
          return index >= array.length / 2;
        };

        $scope.guideFilters = function(item, index, guideArray) {
          if(index == 0) {
            $scope.transparentGuides = [];
          }
          var sqry = '';
          if($scope.searchQuery) {
            sqry = $scope.searchQuery.toLowerCase();
          }

          $scope.challFilt = function() {
            if($scope.challengeData.length >= 1){
              if(!($scope.chalDataArray[item.moduleId])){
                return false;
              }
            }
            return true;
          };

          if($scope.chalFilt){
            if(!$scope.challFilt()){
              $scope.transparentGuides.push(item);
              return false;
            }
          }

          if(sqry != '') {
            if(item.name.toLowerCase().indexOf(sqry) > -1) {
              return true;
            } else {
              $scope.transparentGuides.push(item);
              return false;
            }
          }

          return true;
        };
        $scope.isMobile = function() {
          return $(window).width() <= 768;
        };

        $scope.$on('ngRepeatFinished', function(ngRepeatFinishedEvent) {
            if(!($scope.user.userMonj.touredModules)) {
                $scope.startTour();
            }
        });

        $scope.startTour = function() {
          var tourPlacement = ["right"];
          if($scope.isMobile()){
            tourPlacement = ["bottom"];
          }

            // Perform Additional Translations for tour
            var gotIt = '';
            var essentials = '';
            var essentialsDesc = '';
            $translate('tour.gotIt').then(function(translation) {
                gotIt = translation;
            });
            $translate('tour.essentials').then(function(translation) {
                essentials = translation;
            });
            $translate('tour.essentialsDesc').then(function(translation) {
                essentialsDesc = translation;
            });

          $timeout(function() {
            var tour = new Tour({
              name: 'tour',
              onShown: function(tourN){
                tourN._options.autoscroll = false;
              },
              onStart: function() {
                if($('.hamburger-menu-icon')) {
                  $('.hamburger-menu-icon').toggleClass('disable-pointer-events');
                }
              },
              onEnd: function() {
                if($('.hamburger-menu-icon')) {
                  $('.hamburger-menu-icon').toggleClass('disable-pointer-events');
                }
              },
              steps: [
              {
                element: "#img0",
                title: essentials,
                content: essentialsDesc,
                placement: tourPlacement[0],
                backdropContainer: "#backdrop-container",
                backdrop: true,
                onShow: function(tour) {
                  $('#img0').addClass('temp-offset');
                  $('#backdrop-container').addClass('visible-overflow');
                },
                onHide: function(tour) {
                  tour._options.autoscroll = true;
                  $('#img0').removeClass('temp-offset');
                  $('#backdrop-container').removeClass('visible-overflow');
                  userFactory.updateTouredPages("modules", $scope.user.userMonj.userId);
                },
                template: "<div class='popover tour'><div class='arrow index-mobile'></div><h3 class='popover-title'></h3><div class='popover-content'></div><div class='my-btn' data-role='end'>"+ gotIt +"</div></div>",
              }
            ]});

            //could help to clear local storage before initializing tour
            // You should selectively clean the localStorage - tour_current_step & tour_end keys by localStorage.removeItem(key); Clearing the entire localStorage is not really intended here. You can view the localStorage in developer tools (chrome) going to "Resources" tab >> "Local storage" option.
            // Clear session data
            //localStorage.clear();

            localStorage.removeItem("tour_current_step");
            localStorage.removeItem("tour_end");

            // Initialize the tour
            tour.init();

            // Start the tour
            tour.start();
          });
        };

    };

    angular.module('monjApp')
        .controller('HowToController', ['$scope', '$timeout', '$translate', '$location',
            'dataTransferFactory', 'filterFactory', 'userFactory', '$state', 'resourceLibraryInit', 'termsInit', 'userInit', HowToController]);

}());
