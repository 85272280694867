(function() {
    var ChallengeHeaderController = function ($scope, userFactory) {
        $scope.user = userFactory.getUserData();

        function init() {

            if($scope.user.userMonj.challenge) {
                // Format start and end dates
                $scope.challengeStartDate = moment($scope.user.userMonj.challenge.challengeStartDate);
                $scope.challengeEndDate = moment($scope.user.userMonj.challenge.challengeEndDate);

                // test the state of the current challenge and assign it to user.userMonj.challenge.state
                var now = moment(new Date()); //today's date
                var startTime = moment.duration($scope.challengeStartDate.diff(now)); // Calculate the diff between the dates
                var endTime = moment.duration($scope.challengeEndDate.diff(now)); // Calculate the diff between the dates

                $scope.challengeEndDate = moment($scope.challengeEndDate).format('l');
                if(startTime.asDays() >= 0) {
                    $scope.user.userMonj.challenge.state = 0;
                } else if(endTime.asDays() < 0) {
                    $scope.user.userMonj.challenge.state = 2;
                } else {
                    $scope.user.userMonj.challenge.state = 1;
                }

                $scope.challengeStartDate = moment($scope.challengeStartDate).format('l');

            }
        }

        init();

    };

    angular.module('monjApp')
        .controller('ChallengeHeaderController', ['$scope', 'userFactory', ChallengeHeaderController]);

}());
