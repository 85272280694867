(function() {

    var GuidedMealsIndexBlueprintController = function ($scope, $timeout, $translate, $location, dataTransferFactory, userFactory, recipesInit, $state, filterFactory, userInit, blueprintsInit) {
        $scope.user = userInit;
        $scope.recipes = recipesInit;
        $scope.blueprints = blueprintsInit;

        // Log this page view for analytics.
        analytics.page({
            title: 'GuidedMealsIndexController',
            url: $location.absUrl(),
            path: $location.path(),
            referrer: document.referrer
        });

        $scope.waiting = false;
        $scope.recipeFilters = [];
        $scope.preSelection = true;

        $scope.blueprintSwitch = false;

        // Check to see if the user was routed from a challenge, and if so, grab the ID data passed in
        //$scope.challengeData = dataTransferFactory.get('challengeData');
        // Remove challenge data once it's been retrieved. User should only see challenge data if routed from their team challenge page
        //dataTransferFactory.remove('challengeData');

        // Instead of passing in an object, check if the user is enrolled and the challenge started and then inject the list of challenge items.
        var chalObj = $scope.user.userMonj.challenge;
        if(chalObj) {
            if(chalObj.daysLeftToEnroll <= 0 && chalObj.daysUntilChallengeEnds > 0 && chalObj.team.teamId) {
                for(var x = 0; x < chalObj.team.teamRules.length; x++) {
                    if(chalObj.team.teamRules[x].contentType == "meal") {
                        $scope.challengeData = chalObj.team.teamRules[x].validContentIds;
                    }
                }
                for(var x = 0; x < chalObj.team.individualRules.length; x++) {
                    if(chalObj.team.individualRules[x].contentType == "meal") {
                        $scope.challengeData = chalObj.team.individualRules[x].validContentIds;
                    }
                }
            }
        }

        $scope.ddBool = false;

        $scope.locale = $scope.user.userMonj.locale;

        /***********************************
         * Filter Factory methods used by view
         */
        $scope.transparentItems = {};
        $scope.filteredTags = {};
        $scope.completedRecipes = filterFactory.updateCompleted($scope.user.userMonj.completedRecipeIds);

        $scope.filterClick = function(evt, clearAllSiblings) {
            filterFactory.filterClick(evt, clearAllSiblings);
            $scope.transparentItems = filterFactory.getTransparentItems();
            $scope.filteredTags = filterFactory.getFilteredTags();
        };

        $scope.filterClear = function(evt, clearTag) {
            filterFactory.filterClear(evt, clearTag);
            $scope.transparentItems = filterFactory.getTransparentItems();
        };

        $scope.itemsFilter = function(item, index, array) {
            $scope.transparentItems = filterFactory.getTransparentItems();
            return filterFactory.itemsFilter(item, index, array, $('.item-grid'));
        };

        $( window ).resize(function() {
            filterFactory.updateLabelHeights($('.item-grid'));
        });
        /**
         * End Filter Factory Methods
         ********************************************/

        function init() {

            $timeout(function() {
                // Check for heights of recipe labels after data loads
                filterFactory.updateLabelHeights($('.item-grid'));
            });

            userFactory.getRecipeFilters($scope.user.userMonj.userId).then(function(data) {
                $scope.recipeFilters = data;

                // Reset previously selected filters
                filterFactory.clearFilteredTags();
                var evt = [];

                if($scope.preSelection) {
                    $timeout(function() {
                        $scope.preSelection = false;
                        // Set default preference filters
                        if ($scope.user.userMonj.dairyFreePref) {
                            filterFactory.setFilteredTag('dairyfree', 'Dairy Free');
                            evt.currentTarget = "#dairyfree";
                            $scope.filterClick(evt);
                        }
                        if ($scope.user.userMonj.glutenFreePref) {
                            filterFactory.setFilteredTag('glutenfree', 'Gluten Free');
                            evt.currentTarget = "#glutenfree";
                            $scope.filterClick(evt);
                        }
                        if ($scope.user.userMonj.vegetarianPref) {
                            filterFactory.setFilteredTag('vegetarian', 'Vegetarian');
                            evt.currentTarget = "#vegetarian";
                            $scope.filterClick(evt);
                        }
                        if ($scope.challengeData) {
                            filterFactory.setFilteredTag('challenge', 'Challenge');
                            evt.currentTarget = "#challenge";
                            $scope.filterClick(evt);
                        }
                    });
                }
            });


            // Loop through the recipes object and update the tags to match the ID's used in the view
            for(var x = 0; x < $scope.recipes.length; x++) {
                if($scope.recipes[x].tags.indexOf('quick') < 0) {
                    $scope.recipes[x].tags.push('notquick');
                }

                // If challenge data exists, append the challenge tag to the appropriate items
                if($scope.challengeData) {
                    var chalLength = $scope.challengeData.length;
                    if(chalLength == 0) {
                        $scope.recipes[x].tags.push('challenge');
                    } else {
                        for (var i = 0; i < chalLength; i++) {
                            if ($scope.recipes[x].recipeId == $scope.challengeData[i]) {
                                $scope.recipes[x].tags.push('challenge');
                            }
                        }
                    }
                }
            }
        };

        init();

        $scope.updateMasonry = function() {
            var itemGrid = $('.item-grid');
            filterFactory.updateMasonryList(itemGrid);
        };

        $scope.expandBluePrint = function(elem) {
            var element = $(elem.currentTarget).parent();
            element.parent().find('.expanded').removeClass('expanded');
            element.addClass('expanded');
            $scope.updateMasonry();
        };

        $scope.redirect = function(recipeId) {
            $scope.waiting = true;
            $location.path($state.href('expandedRecipe', {recipeId: recipeId}).replace('#', ''));
        };

        $scope.isMobile = function() {
            return $(window).width() <= 768;
        };

        $scope.$on('ngRepeatFinished', function(ngRepeatFinishedEvent) {
            if(!($scope.user.userMonj.touredMeals)){
                $scope.startTour();
            }
        });

        $scope.startTour = function() {

            var tourPlacement = ["right", "right"];
            var step2 = '.filters';
            if($scope.isMobile()){
                tourPlacement = ["bottom", "top"];
            }

            // Perform Additional Translations for tour
            var gotIt = '';
            var guidedMeals = '';
            var guidedMealsDesc = '';
            var cookingMethods = '';
            var cookingMethodsDesc = '';
            $translate('tour.gotIt').then(function(translation) {
                gotIt = translation;
            });
            $translate('tour.guidedMeals').then(function(translation) {
                guidedMeals = translation;
            });
            $translate('tour.guidedMealsDesc').then(function(translation) {
                guidedMealsDesc = translation;
            });
            $translate('tour.cookingMethods').then(function(translation) {
                cookingMethods = translation;
            });
            $translate('tour.cookingMethodsDesc').then(function(translation) {
                cookingMethodsDesc = translation;
            });

            $timeout(function() {
                var tour = new Tour({
                    name: 'tour',
                    onShown: function(tourN){
                        tourN._options.autoscroll = false;
                    },
                    onStart: function() {
                        if($('.hamburger-menu-icon')) {
                            $('.hamburger-menu-icon').toggleClass('disable-pointer-events');
                        }
                    },
                    onEnd: function() {
                        if($('.hamburger-menu-icon')) {
                            $('.hamburger-menu-icon').toggleClass('disable-pointer-events');
                        }
                    },
                    steps: [
                        {
                            element: "#img0",
                            title: guidedMeals,
                            content: guidedMealsDesc,
                            placement: tourPlacement[0],
                            backdropContainer: "#backdrop-container",
                            backdrop: true,
                            onShow: function(tour) {
                                $('#img0').addClass('temp-offset');
                                $('#backdrop-container').addClass('visible-overflow');
                            },
                            onHide: function(tourN) {
                                tourN._options.autoscroll = true;
                                $('#img0').removeClass('temp-offset');
                                $('#backdrop-container').removeClass('visible-overflow');
                                if($scope.isMobile()){
                                    $('.gm-ppe-left-content-wrapper').toggleClass('show-dd');
                                    $timeout(function(){
                                        $('.gm-ppe-left-content-wrapper').toggleClass('expand-dd');
                                    }, 10);
                                }
                            },
                            template: "<div class='popover index-mobile tour'><div class='arrow index-mobile'></div><h3 class='popover-title'></h3><div class='popover-content'></div><div class='my-btn' data-role='next'>"+ gotIt +"</div></div>",
                        },
                        {
                            element: step2,
                            title: cookingMethods,
                            content: cookingMethodsDesc,
                            placement: tourPlacement[1],
                            backdropContainer: "#backdrop-container",
                            backdrop: true,
                            onShow: function(tour) {
                                if(!($scope.isMobile())) {
                                    $(step2).addClass('temp-padding');
                                }
                                $(step2).addClass('temp-offset');
                                $('#backdrop-container').addClass('visible-overflow');
                            },
                            onShown: function(tourN){
                                tourN._options.autoscroll = false;

                                var count = 50;
                                function scrollDown() {
                                    if(count < 450) {
                                        $('body')[0].scrollTop = $('body')[0].scrollTop + 10;
                                        $timeout(function(){
                                            count = count + 10;
                                            scrollDown();
                                        }, 10);
                                    }
                                }
                            },
                            onHide: function(tourN) {
                                tourN._options.autoscroll = true;
                                if($scope.isMobile()){
                                    $('.gm-ppe-left-content-wrapper').toggleClass('expand-dd');
                                    $timeout(function(){
                                        $('.gm-ppe-left-content-wrapper').toggleClass('show-dd');
                                    }, 390);
                                } else {
                                    $(step2).removeClass('temp-padding');
                                }
                                $(step2).removeClass('temp-offset');
                                $('#backdrop-container').removeClass('visible-overflow');
                                userFactory.updateTouredPages("meals", $scope.user.userMonj.userId);
                            },
                            template: "<div class='popover tour index-mobile'><div class='arrow index-mobile'></div><h3 class='popover-title'></h3><div class='popover-content'></div><div class='my-btn' data-role='end'>"+ gotIt +"</div></div>",
                        }
                    ]});

                localStorage.removeItem("tour_current_step");
                localStorage.removeItem("tour_end");

                // Initialize the tour
                tour.init();

                // Start the tour
                tour.start();
            });
        };

    };

    angular.module('monjApp')
        .controller('GuidedMealsIndexBlueprintController', ['$scope', '$timeout', '$translate', '$location', 'dataTransferFactory', 'userFactory', 'recipesInit', '$state', 'filterFactory', 'userInit', 'blueprintsInit', GuidedMealsIndexBlueprintController]);

}());
