//hello world
(function() {

    var TeamRowsController = function ($scope, $timeout, $location, $translate, userFactory, teamsInit, $state) {
        $scope.user = userFactory.getUserData();
        $scope.teams = teamsInit;
        $scope.searchWaiting = false;
        $scope.onJoinPage = true;
        $scope.currShown = 10;
        $scope.searchQuery = {};

        $scope.powerupTitle = '';
        $scope.lessonTitle = '';
        $scope.mealTitle = '';

        $translate('powerUp.title').then(function(translation) {
            $scope.powerupTitle = translation;
        });
        $translate('meal.title').then(function(translation) {
            $scope.mealTitle = translation;
        });
        $translate('lesson.title').then(function(translation) {
            $scope.lessonTitle = translation;
        });

        $scope.isPhone = $(window).width() < 768;
        // Check for size change
        $(window).resize(function(){
            $scope.isPhone = $(window).width() < 768;
            $scope.$apply();
        });

        function init() {
        }
        
        init();

        if($state.get('joinChallenge').url == $location.path()){
          $scope.onJoinPage = true;
        } else {
            $scope.onJoinPage = false;
        }

        if($scope.user.userMonj.challenge.state == 0) {
            $scope.twoRows = true;
        }

        $scope.getTimeSince = function(timestamp) {
            return moment(timestamp).fromNow();
        };

        $scope.getContentTypeName = function(contentType) {
            if(contentType) {
                switch (contentType.toLowerCase()) {
                    case 'powerup':
                        return $scope.powerupTitle;
                        break;
                    case 'lesson':
                        return $scope.lessonTitle;
                        break;
                    case 'meal':
                        return $scope.mealTitle;
                        break;
                }
            }
        };

        $scope.allTeamsShown = function() {
            if(($scope.teams.length % 10) > 0) {
                return false;
            }
            return true;
        };


        var timer;
        $scope.keyUpSearch = function() {
          var str;
          $scope.currShown = 10;
          $scope.searchWaiting = true;
          if(timer) {
            $timeout.cancel(timer);
          };
          if (!($scope.searchQuery.name) || ($scope.searchQuery.name == '')) {
            str = '';
          } else {
            str = $scope.searchQuery.name;
          }

          timer = $timeout(function() {
            userFactory.getChallengeTeamsOpen($scope.user.userMonj.challenge.challengeId, str, $scope.currShown).then(function(data) {
              $scope.teams = data;
              $scope.searchWaiting = false;
            });
          }, 800);
        };

        $scope.showMoreTeams = function() {
          $scope.currShown += 10;
          if($scope.searchQuery.name && $scope.searchQuery.name != '') {
            userFactory.getChallengeTeamsOpen($scope.user.userMonj.challenge.challengeId, $scope.searchQuery.name, $scope.currShown).then(function(data) {
              $scope.teams = data;
            });
          } else {
            userFactory.getChallengeTeamsOpen($scope.user.userMonj.challenge.challengeId, '', $scope.currShown).then(function(data) {
              $scope.teams = data;
            });
          }
        }
    };

    angular.module('monjApp')
        .controller('TeamRowsController', ['$scope', '$timeout', '$location', '$translate', 'userFactory', 'teamsInit', '$state', TeamRowsController]);

}());
