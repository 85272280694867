/*
UI-Router change 2015-08-18 JV
replace $routeParams with $stateParams
*/

(function () {
  var ExpandedRecipeController = function (
    $scope,
    $log,
    $stateParams,
    cookieFactory,
    autoScrollFactory,
    filterTextFactory,
    animateSavedButtonFactory,
    $location,
    $translate,
    $window,
    $sce,
    $timeout,
    userFactory,
    userInit,
    factoryInit,
    colorInit,
    dataTransferFactory,
    $state,
    filterFactory
  ) {
    $scope.user = userInit;
    $scope.recipe = factoryInit.fields;
    $scope.colors = colorInit;
    $scope.contentfuiId = factoryInit.sys.id;

    $scope.recipe.hasCheatSheet = factoryInit.fields.hasCheatSheet;
    $scope.saved = factoryInit.fields.isSaved;
    $scope.recipeMonj = { global: null, company: null };
    $scope.waiting = false;
    $scope.primary = false;
    $scope.secondary = false;
    $scope.tertiary = false;

    // Log this page view for analytics.
    analytics.page({
      title: "ExpandedRecipeController",
      url: $location.absUrl(),
      path: $location.path(),
      referrer: document.referrer,
    });
    $scope.filteredTags = filterFactory.getFilteredTags();
    function componentToHex(c) {
      var hex = c.toString(16);
      return hex.length == 1 ? "0" + hex : hex;
    }

    function getHex(value) {
      if (value.length > 6) {
        value = value.replace("rgba", "");
        value = value.replace("rgb", "");
        value = value.replace("(", "");
        value = value.replace(")", "");
        value = value.split(",");

        return (
          componentToHex(parseInt(value[0])) +
          componentToHex(parseInt(value[1])) +
          componentToHex(parseInt(value[2]))
        );
      }

      return value;
    }

    if ($scope.colors) {
      if ($scope.colors.primary) {
        $scope.primary = getHex($scope.colors.primary.replace("#", ""));
      }
      if ($scope.colors.secondary) {
        $scope.secondary = getHex($scope.colors.secondary.replace("#", ""));
      }
      if ($scope.colors.tertiary) {
        $scope.tertiary = getHex($scope.colors.tertiary.replace("#", ""));
      }
    }

    // Add subtitles to video
    filterTextFactory
      .addSubtitlesToVideo($scope.recipe.overviewVideo)
      .then(function (video) {
        $scope.recipe.overviewVideo = video;
      });

    if ($state.current.name == "expandedRecipeWidget") {
      $scope.widget = true;
      //$('.drilldown').css('margin', '0 auto');
    }

    $scope.showQuestionEntryField = false;
    $scope.showDeliveryMethod = false;
    $scope.ff_videoPage = 0;

    var scrollURL = $location.path();

    $scope.isDietaryOpen = true;
    $scope.resumingRecipe = false;
    $scope.currTab = 0;

    // Set flags for Flavor Framework Categories
    $scope.sweet = false;
    $scope.salty = false;
    $scope.sour = false;
    $scope.umami = false;
    $scope.spicy = false;
    $scope.bitter = false;
    $scope.roles = [];
    function checkIfRecipeAlreadyWithPreference(preference, recipe) {
      let tags = recipe.recipe.tagsV2;
      // console.log( "recipe.recipe",recipe.recipe,preference,tagsV2.indexOf(preference.tag));
      let returnValue = false;
      if (
        tags.indexOf(preference.tag) >= 0 ||
        (preference.tag == "meatfree" &&
          (/* tags.indexOf("vegan") >= 0 || */ tags.indexOf("vegetarian") >= 0))
      ) {
        returnValue = true;
      }
      return returnValue;
    }
    function initPreferenceSubstituteFramework() {
      $scope.preferenceSubstituteArr = [
        {
          displayName: "Reduced Salt",
          tag: "lowsalt",
          color: "color-1",
          substitutes: [],
        },
        {
          displayName: "Reduced Sugar",
          tag: "lowsugar",
          color: "color-2",
          substitutes: [],
        },
        {
          displayName: "Reduced Carbs",
          tag: "lowcarbs",
          color: "color-3",
          substitutes: [],
        },
        {
          displayName: "Reduced Calories",
          tag: "lowcalories",
          color: "color-4",
          substitutes: [],
        },
        {
          displayName: "Avoid Dairy",
          tag: "dairyfree",
          color: "color-5",
          substitutes: [],
        },
        {
          displayName: "Avoid Gluten",
          tag: "glutenfree",
          color: "color-6",
          substitutes: [],
        },
        {
          displayName: "Avoid Shellfish",
          tag: "shellfishfree",
          color: "color-7",
          substitutes: [],
        },
        {
          displayName: "Avoid Meat",
          tag: "meatfree",
          color: "color-8",
          substitutes: [],
        },
        {
          displayName: "Avoid Tree Nuts",
          tag: "treenutfree",
          color: "color-9",
          substitutes: [],
        },
      ];

      $scope.preferenceSubstituteArr = $scope.preferenceSubstituteArr.filter(
        (preference) => {
          var returnValue = false;
          for (const key in $scope.filteredTags) {
            if (Object.hasOwnProperty.call($scope.filteredTags, key)) {
              if (
                preference.tag == key /* &&
                !checkIfRecipeAlreadyWithPreference(preference, $scope.recipe) */
              ) {
                returnValue = true;
              }
            }
          }
          return returnValue;
        }
      );

      $scope.recipe.ingredients.map((ingredient) => {
        ingredient.fields.isFiltered = false;
        if (ingredient.fields && ingredient.fields.substitution) {
          ingredient.fields.substitution.forEach((substitution) => {
            if (substitution.fields.because) {
              substitution.fields.because.forEach((reason) => {
                var substituteObj = {
                  ingredientName: ingredient.fields.description,
                  subsitituteName: substitution.fields.description,
                  quantity: substitution.fields.quantity,
                  type: substitution.fields.type,
                };
                if (
                  $scope.preferenceSubstituteArr &&
                  $scope.preferenceSubstituteArr.length > 0
                ) {
                  $scope.preferenceSubstituteArr.map((preference) => {
                    if (reason.toLowerCase() == preference.tag) {
                      ingredient.fields.isFiltered = true;
                      preference.substitutes.push(substituteObj);
                    } else if (
                      preference.tag == "meatfree" &&
                      (/* reason.toLowerCase() == "vegan" || */
                        reason.toLowerCase() == "vegetarian")
                    ) {
                      let isSubExist = preference.substitutes.findIndex(
                        (sub) =>
                          substituteObj.ingredientName == sub.ingredientName &&
                          substituteObj.subsitituteName ==
                            sub.subsitituteName &&
                          substituteObj.type == sub.type
                      );
                      if (!(isSubExist >= 0)) {
                        ingredient.fields.isFiltered = true;
                        preference.substitutes.push(substituteObj);
                      }
                    }
                  });
                }
              });
            }
          });
        }
      });

      $scope.preferenceSubstituteArr= $scope.preferenceSubstituteArr.filter((preference)=>{
        if(preference && preference.substitutes && preference.substitutes.length>0){
          return true;
        }
        return false;
      });

      
      if ($scope.preferenceSubstituteArr.length > 0) {
        let pathWithAnd = "";
        if ($scope.preferenceSubstituteArr.length > 0) {
          for (let index = 0; index < $scope.preferenceSubstituteArr.length; index++) {
            const element = $scope.preferenceSubstituteArr[index];
            if (index == $scope.preferenceSubstituteArr.length - 1) {
              pathWithAnd += pathWithAnd ? " and " : "";
            } else {
              pathWithAnd += pathWithAnd ? ", " : "";
            }
            pathWithAnd += userFactory.getPreferenceName(element.tag);
          }
        }
        $scope.path = pathWithAnd;
        $scope.pathTitle = "SWAPS";
      } else {
        $scope.pathTitle = "PATH";
      }
    }
    function initFlavorFramework() {
      if ($scope.recipe.ingredients) {
        for (var x = 0; x < $scope.recipe.ingredients.length; x++) {
          if ($scope.recipe.ingredients[x].fields.roleBitter) {
            $scope.bitter = true;
            $translate("expandedRecipe.biiter").then(function (translation) {
              var object = {
                name: translation,
                rank: 6,
                color: "1EA182",
              };
              //if($.inArray(translation, $scope.roles) === -1) { $scope.roles.push(object); }
              if (!isInObject($scope.roles, translation)) {
                $scope.roles.push(object);
              }
            });
          }
          if ($scope.recipe.ingredients[x].fields.roleSalty) {
            $scope.salty = true;
            $translate("expandedRecipe.salty").then(function (translation) {
              var object = {
                name: translation,
                rank: 2,
                color: "FFC411",
              };
              //if($.inArray(translation, $scope.roles) === -1) { $scope.roles.push(object); }
              if (!isInObject($scope.roles, translation)) {
                $scope.roles.push(object);
              }
            });
          }
          if ($scope.recipe.ingredients[x].fields.roleSour) {
            $scope.sour = true;
            $translate("expandedRecipe.sour").then(function (translation) {
              var object = {
                name: translation,
                rank: 3,
                color: "A5D9CD",
              };
              //if($.inArray(translation, $scope.roles) === -1) { $scope.roles.push(object); }
              if (!isInObject($scope.roles, translation)) {
                $scope.roles.push(object);
              }
            });
          }
          if ($scope.recipe.ingredients[x].fields.roleSpicy) {
            $scope.spicy = true;
            $translate("expandedRecipe.spicy").then(function (translation) {
              var object = {
                name: translation,
                rank: 5,
                color: "FFE7A0",
              };
              //if($.inArray(translation, $scope.roles) === -1) { $scope.roles.push(object); }
              if (!isInObject($scope.roles, translation)) {
                $scope.roles.push(object);
              }
            });
          }
          if ($scope.recipe.ingredients[x].fields.roleSweet) {
            $scope.sweet = true;
            $translate("expandedRecipe.sweet").then(function (translation) {
              var object = {
                name: translation,
                rank: 1,
                color: "A6E7F1",
              };
              //if($.inArray(translation, $scope.roles) === -1) { $scope.roles.push(object); }
              if (!isInObject($scope.roles, translation)) {
                $scope.roles.push(object);
              }
            });
          }
          if ($scope.recipe.ingredients[x].fields.roleUmami) {
            $scope.umami = true;
            $translate("expandedRecipe.umami").then(function (translation) {
              var object = {
                name: translation,
                rank: 4,
                color: "21C2DB",
              };
              //if($.inArray(translation, $scope.roles) === -1) { $scope.roles.push(object); }
              if (!isInObject($scope.roles, translation)) {
                $scope.roles.push(object);
              }
            });
          }

          $scope.hasFlavor =
            $scope.sweet ||
            $scope.salty ||
            $scope.sour ||
            $scope.umami ||
            $scope.spicy ||
            $scope.bitter;
        }
        $timeout(function () {
          $scope.roleLength = $scope.roles.length;
          $scope.lastRoleName =
            $scope.roles.length > 0
              ? $scope.roles[$scope.roles.length - 1].name
              : "";
        });
      }
    }

    function isInObject(oldObject, value) {
      var tmp = oldObject.filter(function (role) {
        return role.name == value;
      });
      if (tmp.length > 0) {
        return true;
      }
      return false;
    }

    function collapseFrameworkIngredients(event) {
      var elem = $(".flavor-framework .er-item-container").not(
        event.currentTarget
      );
      elem.removeClass("large");
      elem.removeClass("strong");
      elem.addClass("opaque");
      elem.removeClass("");
      elem.find(".er-item-image").removeClass("large");
      elem.find(".info").removeClass("large");
      elem.find(".animate-ingr-equip-text").addClass("hide");
    }

    $scope.showDeliveryMethods = function () {
      $scope.showDeliveryMethod = !$scope.showDeliveryMethod;
    };

    $scope.expandFrameworkIngredient = function (event) {
      collapseFrameworkIngredients(event);
      var elem = $(event.currentTarget).parent();
      elem.toggleClass("large");
      elem.find(".er-item-image").toggleClass("large");
      elem.find(".info").toggleClass("large");
      elem.find(".animate-ingr-equip-text").toggleClass("hide");

      // Highlight other items with the same class
      var className = $(event.currentTarget).attr("class").split(" ")[1];
      if ($("." + className).hasClass("strong")) {
        $("." + className).removeClass("strong");
        $(".flavor-framework .er-item-container").removeClass("opaque");
      } else {
        $("." + className).addClass("strong");
      }
    };

    /*************************************************
     * filterTextFactory methods for use in view
     */

    $scope.filterText = function (text) {
      if (text) {
        return filterTextFactory.filterText(text);
      }
      return null;
    };

    $scope.flowplayerFilter = function (embedCode) {
      return $sce.trustAsHtml(filterTextFactory.flowplayerFilter(embedCode));
    };

    $scope.updateProtocols = function (original) {
      return $sce.trustAsHtml(filterTextFactory.updateProtocols(original));
    };

    /**
     * End filterTextFactory methods
     *************************************************/

    function init() {
      /*$scope.recipe = recipesFactory.getRecipe($stateParams.recipeId);
            if (!$scope.recipe) {
                if(!$scope.widget) {
                    $location.path($state.get('page404').url);
                    return;
                } else {
                    $location.path($state.get('widget404').url);
                    return;
                }
            }

            if($scope.user) {
                userFactory.getRecipe($scope.user.userMonj.userId, $stateParams.recipeId).then(function (result) {
                    $scope.recipe.hasCheatSheet = result.hasCheatSheet;
                    $scope.saved = result.isSaved;
                });
            }*/
      $scope.firstName = $scope.user.userMonj.firstName;
      $scope.pathStatement = "";
      // Loop through ingredients and setup flavor framework tab
      initFlavorFramework();
      initPreferenceSubstituteFramework();

      userFactory.postViewMeal(
        $scope.user.userMonj.userId,
        $stateParams.recipeId
      );

      resetExpantions();
      $scope.isButtonVisible = true;

      if ($scope.recipe.time) {
        var time = $scope.recipe.time.split(" ");
        var timeFinal = {};
        if (time.length > 1) {
          var last = time.pop();
          var first = "";

          while (time.length > 0) {
            first += time.shift() + " ";
          }

          $scope.recipe.timeSplit = {};
          $scope.recipe.timeSplit.first = first.trim();
          $scope.recipe.timeSplit.last = last;
        }
      }

      /*if ($scope.user.authData) {

                if($scope.user.userMonj.recipeFollowup) {
                    if ($scope.user.userMonj.recipeFollowup.recipeId == $scope.recipe.id &&
                        dataTransferFactory.check('prevPage') && dataTransferFactory.get('prevPage').url != '/home') {

                        if ($scope.recipe.recipeId) {
                            userFactory.postResumeMeal($scope.user.userMonj.userId, $scope.recipe.recipeId);
                        }
                        $scope.resumingRecipe = true;
                    }
                }

                if ($scope.user.userMonj && $scope.recipe) {
                    if($scope.user.userMonj.recipeFollowup) {
                        if ($scope.user.userMonj.recipeFollowup.recipeId == $scope.recipe.id) {
                            var recipeStored = false;

                            if(cookieFactory.isLocalStorage()){
                                recipeStored = localStorage.getItem($scope.recipe.recipeId)
                            } else {
                                recipeStored = cookieFactory.getCookie($scope.recipe.recipeId)
                            }

                            if (recipeStored) {
                                $scope.resumingRecipe = true;
                            }
                        }
                    }
                }
            }
            else {
                if (!$scope.recipe.public) {
                    $('#beginRecipeBtn').addClass('locked');
                }
            }*/

      $scope.user.eligibleForChallenge = false;
      if ($scope.user.authData) {
        if ($scope.user.userMonj.challenge) {
          if ($scope.user.userMonj.challenge.eligibleRecipeIds) {
            $scope.user.userMonj.challenge.eligibleRecipeIds.filter(function (
              data
            ) {
              if (data === $scope.recipe.recipeId) {
                $scope.user.eligibleForChallenge = true;
              }
            });
          }
        }
      }

      if ($scope.recipe.cookingMethod) {
        $scope.cookMeth = $scope.recipe.cookingMethod;
        $scope.cookMeth = $scope.cookMeth.replace(/\s+/g, "");
        $scope.cookMeth = "expandedRecipe." + $scope.cookMeth;
      }

      $scope.closeFrame = dataTransferFactory.get("applicationCloseFrame");
      $scope.stringify = dataTransferFactory.get("stringify");

      if (dataTransferFactory.get("primaryColorChange")) {
        $scope.primary = dataTransferFactory.get("primaryColorChange");
      }
      if (dataTransferFactory.get("secondaryColorChange")) {
        $scope.secondary = dataTransferFactory.get("secondaryColorChange");
      }
      if (dataTransferFactory.get("tertiaryColorChange")) {
        $scope.tertiary = dataTransferFactory.get("tertiaryColorChange");
      }

      // Update colors if they have been included and is a widget
      $timeout(function () {
        if ($scope.widget && $scope.primary) {
          $(".slider-container .slider-menu").css(
            "background-color",
            "#" + $scope.primary
          );
          $(".drilldown > header").css(
            "background-color",
            "#" + $scope.primary
          );
          $(".secondary-btn").css("background-color", "#" + $scope.primary);
          $(".er-container .recipe-detail .value").css(
            "color",
            "#" + $scope.primary
          );
          $(".secondary-link").css("color", "#" + $scope.primary);
        }
        if ($scope.widget && $scope.secondary) {
          $(".primary-btn").css("background-color", "#" + $scope.secondary);
          $(".border-button.primary").css("color", "#" + $scope.secondary);
          $(".border-button.primary").css(
            "border",
            "2px solid #" + $scope.secondary
          );
          $(".border-button.primary").hover(
            function () {
              $(this).css("background-color", "#" + $scope.secondary);
              $(this).css("color", "#FFFFFF");
            },
            function () {
              $(this).css("background-color", "#FFFFFF");
              $(this).css("color", "#" + $scope.secondary);
            }
          );
        }
        if ($scope.widget && $scope.tertiary) {
          $(".completed-animation").css(
            "background-color",
            "#" + $scope.tertiary
          );
          $(".completed-animation-circle p").css(
            "color",
            "#" + $scope.tertiary
          );
        }
      });

      updatePageTabColors();

      /*if($scope.stringify) {
                window.parent.postMessage(JSON.stringify({event: 'widgetReady', closeWindow: false, completed: false, stringy: true}), "*");
            }
            else {
                window.parent.postMessage({event: 'widgetReady', closeWindow: false, completed: false}, "*");
            }*/
    }

    init();

    function updatePageTabColors() {
      if (dataTransferFactory.get("primaryColorChange")) {
        $scope.primary = dataTransferFactory.get("primaryColorChange");
      }
      if (dataTransferFactory.get("secondaryColorChange")) {
        $scope.secondary = dataTransferFactory.get("secondaryColorChange");
      }
      if (dataTransferFactory.get("tertiaryColorChange")) {
        $scope.tertiary = dataTransferFactory.get("tertiaryColorChange");
      }

      // Update colors if they have been included and is a widget
      $timeout(function () {
        if ($scope.widget && $scope.primary) {
          $(".page-tabs li a").css("color", "#9ba1b3");
          $(".page-tabs li").css(
            "border-bottom",
            "4px solid rgba(0, 0, 0, 0.05)"
          );
          $(".page-tabs li a.active").css("color", "#" + $scope.primary);
          $(".page-tabs li.active").css(
            "border-bottom",
            "4px solid #" + $scope.primary
          );
        }
        if ($scope.widget && $scope.secondary) {
          $(".primary-btn").css("background-color", "#" + $scope.secondary);
        }
        if ($scope.widget && $scope.tertiary) {
          $(".completed-animation").css(
            "background-color",
            "#" + $scope.tertiary
          );
          $(".completed-animation-circle p").css(
            "color",
            "#" + $scope.tertiary
          );
        }
      });
    }

    function resetExpantions() {
      if ($scope.recipe.ingredients != null) {
        $scope.recipe.ingredients.map(function (elem) {
          elem["isExpanded"] = false;
        });
      }

      if ($scope.recipe.equipment != null) {
        $scope.recipe.equipment.map(function (elem) {
          elem["isExpanded"] = false;
        });
      }

      if ($scope.recipe.wineAndBeerPairings != null) {
        $scope.recipe.wineAndBeerPairings.map(function (elem) {
          elem["isExpanded"] = false;
        });
      }
    }

    $scope.getFFClass = function (name) {
      return name
        .toLowerCase()
        .replace(/[^a-zA-Z ]/g, "")
        .substring(0, 16)
        .split(" ")
        .join("");
    };

    $scope.expandIngredient = function (ingr) {
      if (ingr["isExpanded"] == false) {
        resetExpantions();
      }

      ingr["isExpanded"] = !ingr["isExpanded"];
    };

    $scope.expandEquipment = function (equip) {
      if (equip["isExpanded"] == false) {
        resetExpantions();
      }

      equip["isExpanded"] = !equip["isExpanded"];
    };

    $(window).on("scroll", scrolled);

    function scrolled() {
      if (scrollURL != $location.path()) {
        $(window).off("scroll", scrolled);
        return;
      }

      // B U T T O N
      var el = $("#beginRecipeBtn");
      if (el) {
        var offset = el.offset();
        var height = el.outerHeight();
        var scroll = $(window).scrollTop();

        if (offset) {
          if (offset.top + height / 2 > scroll) {
            if ($scope.isButtonVisible == false) {
              $scope.isButtonVisible = true;
              if (!$scope.$$phase) {
                $scope.$digest();
              }
            }
          } else {
            if ($scope.isButtonVisible == true) {
              $scope.isButtonVisible = false;
              if (!$scope.$$phase) {
                $scope.$digest();
              }
            }
          }
        }
      }
    }

    $scope.beginRecipe = function () {
      $scope.waiting = true;
      if (!$scope.widget) {
        /*if (!$scope.recipe.public && !$scope.user.authData) {
                    $location.path($state.href('login', {recipeId: $stateParams.recipeId}).replace('#', ''));
                }
                else {
                    if ($scope.user.userMonj.recipeFollowup) {
                        if ($scope.user.userMonj.recipeFollowup.recipeId == $scope.recipe.id) {
                            $scope.user.userMonj.followup = $scope.recipe.id;
                        }
                    }*/
        $location.path(
          $state
            .href("guidedRecipe", { recipeId: $stateParams.recipeId })
            .replace("#", "")
        );
        //}
      } else {
        $location.path(
          $state
            .href("guidedRecipeWidget", { recipeId: $stateParams.recipeId })
            .replace("#", "")
        );
      }
    };

    $scope.emailShoppingList = function () {
      if ($scope.user.authData) {
        userFactory.emailShoppingList(
          $scope.user.userMonj.userId,
          $scope.recipe.recipeId
        );
      }
    };

    $scope.saveShoppingList = function () {
      if ($scope.user.authData) {
        $scope.saved = false;
        $scope.saveRecipe();
        userFactory.saveShoppingList(
          $scope.user.userMonj.userId,
          $scope.recipe.recipeId
        );
      }
    };

    $scope.emailCheatSheet = function () {
      userFactory
        .sendCheatSheet($scope.user.userMonj.userId, $scope.recipe.recipeId)
        .then(function (data) {
          $scope.cheatSheetEmailed = true;
        });
    };

    $scope.completeMeal = function () {
      $location
        .path(
          $state
            .href("guidedRecipe", { recipeId: $stateParams.recipeId })
            .replace("#", "")
        )
        .search("c", "true");
    };

    $scope.goBack = function () {
      if ($scope.widget) {
        if ($scope.stringify) {
          window.parent.postMessage(
            JSON.stringify({
              /*event: 'widgetClosed', */ closeWindow: true,
              completed: false,
              stringy: true,
            }),
            "*"
          );
          window.ReactNativeWebView.postMessage(
            JSON.stringify({
              /*event: 'widgetClosed', */ closeWindow: true,
              completed: false,
              stringy: true,
            }),
            "*"
          );
        } else {
          window.parent.postMessage(
            { /*event: 'widgetClosed', */ closeWindow: true, completed: false },
            "*"
          );
          window.ReactNativeWebView.postMessage(
            { /*event: 'widgetClosed', */ closeWindow: true, completed: false },
            "*"
          );
        }
      } else {
        $scope.completeMeal();
      }
    };

    $scope.saveRecipe = function () {
      $(".my-menu-header .ae-header-container").addClass("show-header");
      if (!$scope.saved) {
        $scope.saved = true;
        animateSavedButtonFactory.animateOnSave();
        userFactory.saveMeal(
          $scope.user.userMonj.userId,
          $scope.recipe.recipeId
        );
        var convertedMealData = [
          {
            timestamp: new Date().toLocaleString(),
            contentId: $scope.recipe.recipeId,
            contentType: "Meal",
            contentTypeEnum: 0,
            contentName: $scope.recipe.title,
            body: null,
            imageUrl: $scope.recipe.thumbnailImage.fields.file.url,
            gameValue: $scope.recipe.gameValue,
          },
        ];
        dataTransferFactory.set("lastSavedMeal", convertedMealData);
      } else {
        $scope.saved = false;
        $(".action-container .action-icon.save.selected").css(
          "transform",
          "translate(0px, 0px)"
        );
        userFactory.unsaveMeal(
          $scope.user.userMonj.userId,
          $scope.recipe.recipeId
        );
        dataTransferFactory.remove("lastSavedMeal");
      }
    };

    $scope.instacartSafeway = function () {
      $window.open($scope.recipe.instacartSafewayList, "_blank");
    };

    $scope.instacartWholeFoods = function () {
      $window.open($scope.recipe.instacartWholeFoodsList, "_blank");
    };

    $scope.switchTab = function (tNum) {
      /*$('.tag-tab-wrapper').toggleClass('zero-op');
          $timeout(function() {*/
      $scope.currTab = tNum;
      /*$('.tag-tab-wrapper').toggleClass('zero-op');
          }, 400);*/

      updatePageTabColors();
    };

    $("#share").on("focus", function (e) {
      e.target.select();
      $(e.target).one("mouseup", function (e) {
        e.preventDefault();
      });
    });
  };

  angular
    .module("monjApp")
    .controller("ExpandedRecipeController", [
      "$scope",
      "$log",
      "$stateParams",
      "cookieFactory",
      "autoScrollFactory",
      "filterTextFactory",
      "animateSavedButtonFactory",
      "$location",
      "$translate",
      "$window",
      "$sce",
      "$timeout",
      "userFactory",
      "userInit",
      "factoryInit",
      "colorInit",
      "dataTransferFactory",
      "$state",
      "filterFactory",
      ExpandedRecipeController,
    ]);
})();
