(function() {
    var AuthExperienceController = function ($scope, $sce, $timeout, $location, $translate, dataTransferFactory, userFactory, userInit, $state, animateSavedButtonFactory) {

        $scope.user = userInit;

        // Log this page view for analytics.
        analytics.page({
            title: 'AuthExperienceController',
            url: $location.absUrl(),
            path: $location.path(),
            referrer: document.referrer
        });

        if($scope.user.userMonj) {
            $scope.levIndex = $scope.user.userMonj.levelIndex;
        }

        $scope.chefHatLevels = ['newbie-color','fearless-foodie-color','fearless-foodie-p-color','fearless-foodie-pp-color',
                                'confident-cook-color','confident-cook-p-color','confident-cook-pp-color','super-chef-color',
                                'super-chef-p-color','super-chef-pp-color','monj-master-filled'];

        $scope.missionInitCount = 0;
        $scope.challengeInitCount = 0;
        $scope.user.recipeData = true;
        $scope.customGoals = [];
        $scope.FLIPPED_CHALLENGE = false;

        $scope.leftToGo = ["auth.miss", "auth.guidedMeals", "auth.powerUps"];

        $scope.recList = [];
        $scope.recListIds = [];

        $scope.recListTitles = ["meals.title", "powerUps.title", "deepDives.title"];

        var d = new Date();
        var weekday = new Array(7);

        if($scope.user.userMonj.locale == 'en'){
          weekday[0] =  "Sunday";
          weekday[1] = "Monday";
          weekday[2] = "Tuesday";
          weekday[3] = "Wednesday";
          weekday[4] = "Thursday";
          weekday[5] = "Friday";
          weekday[6] = "Saturday";
        } else {
          weekday[0] =  "Domingo";
          weekday[1] = "Lunes";
          weekday[2] = "Martes";
          weekday[3] = "Mi\xE9rcoles";
          weekday[4] = "Jueves";
          weekday[5] = "Viernes";
          weekday[6] = "S\xE1bado";
        }

        $scope.day = weekday[d.getDay()];

        function init() {
            userFactory.getUserLevels($scope.user.userMonj.userId).then(function(result) {
                $scope.levels = result;
            });

            // Get list of recommendations
            userFactory.getRecommendations($scope.user.userMonj.userId, null, null, null).then(function(data) {
                if (data.recipe) {
                    $scope.recList.push(data.recipe);
                    $scope.recListIds.push(data.recipe.recipeId);
                }
                if (data.powerUp) {
                    $scope.recList.push(data.powerUp);
                    $scope.recListIds.push(data.powerUp.powerUpId);
                }
                if (data.lesson) {
                    $scope.recList.push(data.lesson);
                    $scope.recListIds.push(data.lesson.lessonId);
                }
            });

            // MISSIONS

            userFactory.getDailyMission($scope.user.userMonj.userId)
            //userFactory.DEVgetMission($scope.user.userMonj.userId, 30)
            //userFactory.DEVgetPoll($scope.user.userMonj.userId, 1)
                .then(function (result) {
                    if (result) {
                        $scope.mission = result;
                    }

                    if(result.content) {
                        switch(result.content.type) {
                            case 'quiz':
                                $translate('headerList.mission.quiz').then(function (translation) {
                                    $scope.mission.content.pollName = translation;
                                });
                                break;
                            case 'research':
                                $translate('headerList.mission.survey').then(function (translation) {
                                    $scope.mission.content.pollName = translation;
                                });
                                break;
                            case 'outcomes':
                                $translate('headerList.mission.checkingIn').then(function (translation) {
                                    $scope.mission.content.pollName = translation;
                                });
                                break;
                            default:
                                $scope.mission.content.pollName = '';
                                break;
                        }
                    }

                    // Record that this mission has been viewed
                    if (result.content && result.contentType == "mission") {
                        userFactory.postViewMission($scope.user.userMonj.userId, result.content.missionId);
                    }

                    // Record that this poll has been viewed
                    if (result.content && result.contentType == "poll") {
                        userFactory.postViewPoll($scope.user.userMonj.userId, $scope.mission.content.pollId);
                    }

                    // dynamically set height for back of mission card
                    $scope.missionInit = false;

                    calcMissionHeights();

                    $timeout(function() {
                      if(!($scope.user.userMonj.touredHome)){
                        $scope.startTour();
                      }
                    },300);

                    if($scope.mission.content) {
                        dataTransferFactory.set('contentTitle', $scope.mission.content.title);
                    }
                });

            // Try to initialize the heights immediately
            calcChallengeHeights();

            $scope.$watch(function(scope) { return scope.user.userMonj.badges; }, function() {
                $scope.updateCustomizedGoals();
            });
            $scope.updateCustomizedGoals();
            $scope.goalNames = ["wisdom", "cooking method", "power up"];
            
            if($scope.user.userMonj.challenge) {
                // Format start and end dates
                $scope.challengeStartDate = moment($scope.user.userMonj.challenge.challengeStartDate);
                $scope.challengeEndDate = moment($scope.user.userMonj.challenge.challengeEndDate);

                $scope.challengeStartDate = moment($scope.challengeStartDate).format('l');
                $scope.challengeEndDate = moment($scope.challengeEndDate).format('l');
            }

            $scope.joinedChallenge = dataTransferFactory.get('joinChallengeFailed');
            if($scope.joinedChallenge) {
                if($scope.joinedChallenge.indexOf(" ") > 0) {
                    $scope.challengeErrorText = $scope.joinedChallenge;
                    dataTransferFactory.remove('joinChallengeFailed');
                    openModal('join-challenge-error-container');
                } else {
                    $translate('challenge.' + $scope.joinedChallenge).then(function (translation) {
                        $scope.challengeErrorText = translation;
                        dataTransferFactory.remove('joinChallengeFailed');
                        openModal('join-challenge-error-container');
                    });
                }
            }

            /*$timeout(function() {
                if($scope.user.userMonj.challenge) {
                    if ($scope.user.userMonj.challenge.team.teamId) {
                        $('.ae-challenge-container').css('height', 'auto');
                        for(var x = 0; x <= $scope.user.userMonj.challenge.team.teamMembers; x++) {
                            console.log(x);
                            $scope.createProgressBar($scope.user.userMonj.challenge.team.teamMembers[x].contributionPercent / 100, '#teamMember-' + x, 8, 6, '#FFFFFF', $scope.progressParamsColors[x]);
                        }
                    }
                }
            });*/
        }

        /*$scope.createProgressBar = function(percentage, elemName, trailWidth, strokeWidth, trailColor, color) {
            color = color ? color : '#30C2D9';
            trailColor = trailColor ? trailColor : '#ffffff';
            trailWidth = trailWidth ? trailWidth : '8';
            strokeWidth = strokeWidth ? strokeWidth : '8';

            var from = Math.min(strokeWidth, 10);
            var to = Math.min(strokeWidth, 8);

            $scope.bar = new ProgressBar.Circle(elemName, {
                color: color,
                trailColor: trailColor,
                // This has to be the same size as the maximum width to
                // prevent clipping
                strokeWidth: strokeWidth,
                trailWidth: trailWidth,
                easing: 'easeInOut',
                duration: 1400,
                text: {
                    autoStyleContainer: false
                },
                from: { width: from },
                to: { width: to },
                // Set default step function for all animate calls
                step: function(state, circle) {
                    circle.path.setAttribute('stroke', circle._opts.color);
                    circle.path.setAttribute('stroke-width', state.width);

                    //var value = Math.round(circle.value());
                }
            });
            $scope.bar.animate(percentage);
        };*/

        function openModal(className, delay) {
            $timeout(function() {
                jQuery.event.trigger('pauseVideo');
                $('.' + className).addClass('active');
                $('.modal-background').removeClass('hide');
            }, Math.max(delay | 0, 0));
        };

        // Loop through badge data and retrieve object for customized goal based on groupId
        $scope.getCustomizedGoalData = function(groupId, topicId) {
            var variable = null;
            $scope.user.userMonj.badges.forEach(function(dataset){
                if(dataset.groupId == groupId) {
                    dataset.badges.forEach(function(result){
                        if(result.topicId == topicId) {
                            variable = result;
                        }
                    });
                }
            });
            return variable;
        };

        $scope.updateCustomizedGoals = function() {
            // Assign customized goals data
            $scope.customGoals[0] = $scope.getCustomizedGoalData("wisdom", $scope.user.userMonj.wisdomGoalTopicId);
            $scope.customGoals[1] = $scope.getCustomizedGoalData("methods", $scope.user.userMonj.methodGoalTopicId);
            $scope.customGoals[2] = $scope.getCustomizedGoalData("powerups", $scope.user.userMonj.powerUpGoalTopicId);
        };

        $scope.initIntroVideo = function () {
            $('.flowplayer').flowplayer();
        };

        // Waiting until the userMonj is set to initialize the data
        /*function seqInit () {
            if ($scope.user.userMonj) {
                init();
                // Update carousel position once the recipe's have been collected.
                setTimeout(function() {
                    //moveCarouselToIndex(null);
                }, 500);
            }
            else {
                if($scope.user.userMonjPromise) {
                    $scope.user.userMonjPromise.then(function (result) {
                        init();
                        // Update carousel position once the recipe's have been collected.
                        setTimeout(function () {
                            //moveCarouselToIndex(null);
                        }, 500);
                    });
                }
            }
        }

        seqInit();*/

        $scope.setWaiting = function(val) {
            $scope.waiting = val;
        };

        $scope.redirectTo = function (url) {
            $location.path($state.get(url).url);
        };

        $scope.logout = function () {
            userFactory.logout();
        };

        ////////////////////////
        // MISSION METHODS

        $scope.missionFlipToggle = function() {
            //calculateMissionHeights();
            if($scope.FLIPPED_MISSION) {
                $scope.FLIPPED_MISSION = false;
                $('#ae-mission-container').removeClass('flipped');
            } else {
                $scope.FLIPPED_MISSION = true;
                $('#ae-mission-container').addClass('flipped');
            }
            calcMissionHeights();
        };

        function calcMissionHeights() {
            var frontHeight = $('#ae-mission-container .front').outerHeight();
            var backHeight = $('#ae-mission-container .back').outerHeight();
            var container = $('#ae-mission-container .flipper');

            if($scope.FLIPPED_MISSION) {
                container.height(backHeight);
            } else {
                container.height(frontHeight);
            }

            // Keep trying to calculate the height until the elements are loaded. Limit the attempts to 100 (or 10 seconds)
            if(frontHeight <= 0 && backHeight <= 0 && $scope.missionInitCount <= 100) {
                $timeout(function() {
                    $scope.missionInitCount++;
                    calcMissionHeights();
                }, 100);
            }
        }

        $scope.saveDailyMission = function() {
            if($scope.mission.content) {
                if (!$scope.mission.content.isSaved) {
                    animateSavedButtonFactory.animateOnSave();
                    $scope.mission.content.isSaved = true;
                    userFactory.postSaveMission($scope.user.userMonj.userId, $scope.mission.content.missionId);
                    var convertedMissionData = [{
                        'timestamp': new Date().toLocaleString(),
                        'contentId': $scope.mission.content.missionId,
                        'contentType': 'Mission',
                        'contentTypeEnum': 1,
                        'contentName': $scope.mission.content.title,
                        'body': $scope.mission.content.body,
                        'imageUrl': $scope.mission.content.imageUrl,
                        'gameValue': $scope.mission.content.gameValue,
                        'isComplete': $scope.mission.isComplete
                    }];
                    dataTransferFactory.set('lastSavedMission', convertedMissionData);
                } else {
                    // Otherwise, unsave the mission since the user hit ACCEPT a second time.
                    $scope.unsaveMission($scope.mission.content.missionId);
                    $('.action-container .action-icon.save.selected').css('transform', 'translate(0px, 0px)');
                    dataTransferFactory.remove('lastSavedMission');
                }
            }
        };

		// Unsave mission when the user clicks the ACCEPT  button a second time.
		$scope.unsaveMission = function(savedMissionId) {
			if($scope.mission.content.isSaved) {
                $scope.mission.content.isSaved = null;

                if(savedMissionId) {
                    userFactory.postUnsaveMission($scope.user.userMonj.userId, savedMissionId);
                }
			}
		};

        $scope.completeDailyMission = function(send_api_request) {

            if (!$scope.mission.isComplete) {
                $scope.mission.isComplete = true;
                $scope.waiting = true;

                if (send_api_request && $scope.mission.content) {
                    userFactory.postCompleteMission($scope.user.userMonj.userId, $scope.mission.content.missionId).then(function() {
                        $scope.waiting = false;
                    });
                }
            }
        };

        $scope.selectedPollAnswer = null;

        $scope.selectPollAnswer = function(index) {
            $scope.selectedPollAnswer = index;
        };

        $scope.convertIndexToLetter = function(index) {
            return String.fromCharCode(65 + index);
        };

        $scope.submitPollAnswer = function(index) {
            if(!$scope.mission.isComplete) {
                $scope.mission.isComplete = true;
                $scope.waiting = true;
                userFactory.postAnswerPoll($scope.user.userMonj.userId, $scope.mission.content.pollId, $scope.selectedPollAnswer).then(function() {
                    $scope.waiting = false;
                });
            }

            $timeout(function() {
                calcMissionHeights();
            }, 1000);
        };

        // accepts an int or string (without 'px')
        function setMissionHeight(size) {
            if ( size === 'auto' ) {
                $('#ae-mission-container').css('height', 'auto');
            } else {
                var newHeight = size + 'px';
                $('#ae-mission-container').css('height', newHeight);
            }
        }

        // Dynamically resize missions container on window resize
        $(window).resize(function(size) {
            // use auto height to figure out what height the card 'should be'
            setMissionHeight('auto');

            // re-set the scope variables based on that
            calcMissionHeights();
            calcChallengeHeights();
            //calculateIntroVideoHeights();

            // set the heights to the new height
            if ($('.ae-mission-flipper').hasClass('flipped')) {
                setMissionHeight($scope.user.missionBackHeight);
            } else {
                setMissionHeight($scope.missionFrontHeight);
            }

            /*if(!$('.teamup-content').hasClass('small-on-mobile') || $(document).width() >= 768) {
                $('.teamup-content').css('height', 'inherit');
                if(!$('.teamup-content').hasClass('small-on-mobile') && $(document).width() >= 768) {
                    $('.teamup-content').addClass('small-on-mobile');
                }
            }
            if($('.teamup-content').hasClass('small-on-mobile') && $(document).width() <= 768) {
                $('.teamup-content').css('height', '62px');
            }*/
            if(size.target.innerWidth >= 768) {
                $('.teamup-content').css('height', 'auto');
            }

            $scope.$apply();
        });

        /*******************************************
         * CHALLENGE METHODS
         */

        $scope.challengeFlipToggle = function() {
            //calculateMissionHeights();
            if($scope.FLIPPED_CHALLENGE) {
                $scope.FLIPPED_CHALLENGE = false;
                $('#ae-challenge-container').removeClass('flipped');
            } else {
                $scope.FLIPPED_CHALLENGE = true;
                $('#ae-challenge-container').addClass('flipped');
            }
            calcChallengeHeights();
        };

        // Calculate Challenge Heights on flip
        function calcChallengeHeights() {
            var frontHeight = $('#ae-challenge-container .front').outerHeight();
            var backHeight = $('#ae-challenge-container .back').outerHeight();
            var container = $('#ae-challenge-container .flipper');

            if($scope.FLIPPED_CHALLENGE) {
                container.height(backHeight);
            } else {
                container.height(frontHeight);
            }

            // Keep trying to calculate the height until the elements are loaded. Limit the attempts to 100 (or 10 seconds)
            if(frontHeight <= 0 && backHeight <= 0 && $scope.challengeInitCount <= 100) {
                $timeout(function() {
                    $scope.challengeInitCount++;
                    calcChallengeHeights();
                }, 100);
            }
        };

        $scope.getHtml = function(html){
            return $sce.trustAsHtml(html);
        };

        $scope.recRedirect = function(index, id) {
          $scope.waiting = true;
          if(index == 0) {
            $location.path($state.href('expandedRecipe', {recipeId: id}).replace('#', ''));
          } else if(index == 1) {
            $location.path($state.href('powerUpView', {powerupId: id}).replace('#', ''));
          } else if(index == 2) {
            $location.path($state.href('lesson', {id: id}).replace('#', ''));
          }
        };

        $scope.ppSection = ["wisdom","methods","powerups"];

        $scope.smoothScrollTo = function(index) {
            $location.path($state.get('progressPage').url).search({ 'scrollTo': $scope.ppSection[index] });
        };

        $scope.isMobile = function() {
          return $(window).width() <= 1024;
        };

        $scope.startTour = function() {
          var stepOne = "";

          var stepOneContent = "";

          if($scope.user.userMonj.wisdomGoalTopicId) {

            var str = "";

              switch($scope.user.userMonj.wisdomGoalTopicId) {
                  case 10:
                      $translate('tour.wellnessWarrior').then(function(translation) {
                          $translate('tour.stepOneVar', { x: translation }).then(function(translation2) {
                              stepOneContent = translation2;
                          });
                      });
                      break;

                  case 11:
                      $translate('tour.socialButterfly').then(function(translation) {
                          $translate('tour.stepOneVar', { x: translation }).then(function(translation2) {
                              stepOneContent = translation2;
                          });
                      });
                      break;

                  case 12:
                      $translate('tour.culinaryExplorer').then(function(translation) {
                          $translate('tour.stepOneVar', { x: translation }).then(function(translation2) {
                              stepOneContent = translation2;
                          });
                      });
                      break;

                  case 13:
                      $translate('tour.prepPro').then(function(translation) {
                          $translate('tour.stepOneVar', { x: translation }).then(function(translation2) {
                              stepOneContent = translation2;
                          });
                      });
                      break;

                  case 14:
                      $translate('tour.savvySage').then(function(translation) {
                          $translate('tour.stepOneVar', { x: translation }).then(function(translation2) {
                              stepOneContent = translation2;
                          });
                      });
                      break;
              }

          } else {
              $translate('tour.stepOne').then(function(translation) {
                  stepOneContent = translation;
              });
          }

          if($scope.mission.contentType == "mission") {
            stepOne = "#mission";
          } else {
            stepOne = "#quiz";
          }
          var tourPlacement = ["right","left","right"];
          if($scope.isMobile()){
            tourPlacement = ["bottom","top","top"];
          }
            
            // Perform Additional Translations for tour
            var gotIt = '';
            var getStarted = '';
            var dailyMission = '';
            var getRecommendations = '';
            var getRecommendationsDesc = '';
            var trackGoals = '';
            var trackGoalsDesc = '';
            $translate('tour.gotIt').then(function(translation) {
                gotIt = translation;
            });
            $translate('tour.getStarted').then(function(translation) {
                getStarted = translation;
            });
            $translate('tour.dailyMission').then(function(translation) {
                dailyMission = translation;
            });
            $translate('tour.getRecommendations').then(function(translation) {
                getRecommendations = translation;
            });
            $translate('tour.getRecommendationsDesc').then(function(translation) {
                getRecommendationsDesc = translation;
            });
            $translate('tour.trackGoals').then(function(translation) {
                trackGoals = translation;
            });
            $translate('tour.trackGoalsDesc').then(function(translation) {
                trackGoalsDesc = translation;
            });

          $timeout(function() {
            var tour = new Tour({
              name: 'tour',
              onShown: function(tourN){
                tourN._options.autoscroll = false;
              },
              onHide: function(tourN){
                tourN._options.autoscroll = true;
              },
              onStart: function() {
                if($('.hamburger-menu-icon')) {
                  $('.hamburger-menu-icon').toggleClass('disable-pointer-events');
                }
              },
              onEnd: function() {
                if($('.hamburger-menu-icon')) {
                  $('.hamburger-menu-icon').toggleClass('disable-pointer-events');
                }
              },
              steps: [
              {
                element: stepOne,
                title: dailyMission,
                content: stepOneContent,
                placement: tourPlacement[0],
                backdropContainer: '#home-auth',
                backdrop: true,
                template: "<div class='popover tour'><div class='arrow'></div><h3 class='popover-title'></h3><div class='popover-content'></div><div class='my-btn' data-role='next'>"+ gotIt +"</div></div>",
              },
              {
                element: "#recommendations-container",
                title: getRecommendations,
                content: getRecommendationsDesc,
                placement: tourPlacement[2],
                backdropContainer: '#home-auth',
                backdrop: true,
                template: "<div class='popover tour'><div class='arrow'></div><h3 class='popover-title'></h3><div class='popover-content'></div><div class='my-btn' data-role='next'>"+ gotIt +"</div></div>",
              },
              {
                element: "#goal-progress",
                title: trackGoals,
                content: trackGoalsDesc,
                placement: tourPlacement[1],
                backdrop: true,
                backdropContainer: '#home-auth',
                onHide: function() {
                  userFactory.updateTouredPages("home", $scope.user.userMonj.userId);
                },
                template: "<div class='popover tour'><div class='arrow'></div><h3 class='popover-title'></h3><div class='popover-content'></div><div class='my-btn' data-role='end'>"+ getStarted +"</div></div>",
              }
            ]});

            //could help to clear local storage before initializing tour
            // You should selectively clean the localStorage - tour_current_step & tour_end keys by localStorage.removeItem(key); Clearing the entire localStorage is not really intended here. You can view the localStorage in developer tools (chrome) going to "Resources" tab >> "Local storage" option.
            // Clear session data
            //localStorage.clear();

            localStorage.removeItem("tour_current_step");
            localStorage.removeItem("tour_end");

            // Initialize the tour
            tour.init();

            // Start the tour
            tour.start();
          });
        };

        init();

    };

    angular.module('monjApp')
        .controller('AuthExperienceController', ['$scope', '$sce', '$timeout', '$location', '$translate', 'dataTransferFactory', 'userFactory', 'userInit', '$state', 'animateSavedButtonFactory', AuthExperienceController]);

}());
