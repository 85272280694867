(function () {
    // Init Raygun
    Raygun.init('CObRo2OpgPZe1VHHvOB/Hg==').attach();

    // AngularJS
    var app;
    var uiStateTriggered = false;
    var cacheBustVersion = "2.9.0";

    if(isLocalStorage()){
        var defaultLang = localStorage.getItem("defaultLanguage");
    } else {
        var defaultLang = getCookie('defaultLanguage');
    }

    if(!defaultLang) {
        defaultLang = 'en';
    }

    function isLocalStorage(){
        try {
            localStorage.setItem('localStorageTest', 'test');
            localStorage.removeItem('localStorageTest');
            return true;
        } catch(e) {
            return false;
        }
    }

    // AngularJS init
    app = angular.module('monjApp', ['ui.router', 'ngRoute', 'ngAnimate', 'pascalprecht.translate', 'wu.masonry', 'ngTouch']);

    var BrowserDetect = {
        init: function () {
            this.browser = this.searchString(this.dataBrowser) || "Other";
            this.version = this.searchVersion(navigator.userAgent) || this.searchVersion(navigator.appVersion) || "Unknown";
        },
        searchString: function (data) {
            for (var i = 0; i < data.length; i++) {
                var dataString = data[i].string;
                this.versionSearchString = data[i].subString;

                if (dataString.indexOf(data[i].subString) !== -1) {
                    return data[i].identity;
                }
            }
        },
        searchVersion: function (dataString) {
            var index = dataString.indexOf(this.versionSearchString);
            if (index === -1) {
                return;
            }

            var rv = dataString.indexOf("rv:");
            if (this.versionSearchString === "Trident" && rv !== -1) {
                return parseFloat(dataString.substring(rv + 3));
            } else {
                return parseFloat(dataString.substring(index + this.versionSearchString.length + 1));
            }
        },

        dataBrowser: [
            {string: navigator.userAgent, subString: "Edge", identity: "MS Edge"},
            {string: navigator.userAgent, subString: "MSIE", identity: "Explorer"},
            {string: navigator.userAgent, subString: "Trident", identity: "Explorer"},
            {string: navigator.userAgent, subString: "Firefox", identity: "Firefox"},
            {string: navigator.userAgent, subString: "Opera", identity: "Opera"},
            {string: navigator.userAgent, subString: "OPR", identity: "Opera"},
            {string: navigator.userAgent, subString: "Chrome", identity: "Chrome"},
            {string: navigator.userAgent, subString: "Safari", identity: "Safari"}
        ]
    };
    BrowserDetect.init();

    var segment = false;

    app.run(['$rootScope', '$state', '$location', '$timeout', 'appSettings', 'userFactory', 'dataTransferFactory', '$translate', '$q', function($rootScope, $state, $location, $timeout, appSettings, userFactory, dataTransferFactory, $translate, $q) {
        $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {

            // Set default image sizes based on window width - Base this on screen width, not viewport width (otherwise images may turn out fuzzy when someone switches to wider view on desktop)
            // The current maximum page width is 1280px which should be our horizontal limit.
            var width = screen.width;
            // Default to Desktop Sizes
            $rootScope.oneToOne = "w=250&h=250";
            $rootScope.fourToThree = "w=1280&h=960";
            $rootScope.sixteenToNine = "w=1280&h=720";

            // Tablet Sizes
            if(width < 1024) {
                //$rootScope.oneToOne = "w=500&h=500";
                $rootScope.fourToThree = "w=1024&h=768";
                $rootScope.sixteenToNine = "w=1024&h=576";
            }

            // Mobile Sizes
            if(width < 768) {
                //$rootScope.oneToOne = "w=500&h=500";
                $rootScope.fourToThree = "w=768&h=576";
                $rootScope.sixteenToNine = "w=768&h=432";
            }

            // If widget is loaded, don't show monj logo in load animation
            if(toState.name !== 'widget404' && toState.name !== 'dailyMissionWidget' && toState.name !== 'essentialsWidget'
                && toState.name !== 'powerUpViewWidget' && toState.name !== 'expandedRecipeWidget' && toState.name !== 'guidedRecipeWidget'
                && toState.name !== 'expandedLessonWidget' && toState.name !== 'guidedLessonWidget') {
                $('#loading').addClass('no-widget');
            }

            if($('.popover')) {
              $('.popover').remove();
            }

            //$rootScope.lang = defaultLang;

            // Get and store valid VTT files
            /*userFactory.getVTTs().then(function(result) {
                $rootScope.validVTT = result;
            });*/

            if(!segment) {
                segment = true;
                !function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","page","once","off","on"];analytics.factory=function(t){return function(){var e=Array.prototype.slice.call(arguments);e.unshift(t);analytics.push(e);return analytics}};for(var t=0;t<analytics.methods.length;t++){var e=analytics.methods[t];analytics[e]=analytics.factory(e)}analytics.load=function(t){var e=document.createElement("script");e.type="text/javascript";e.async=!0;e.src=("https:"===document.location.protocol?"https://":"http://")+"cdn.segment.com/analytics.js/v1/"+t+"/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(e,n)};analytics.SNIPPET_VERSION="3.1.0";
                    analytics.load(appSettings.segmentKey);
                }}();
				
				// register app initializaion as a page event with Segment
				var deferred = $q.defer();
				userFactory.getUser()
				.then(function(data) {
					analytics.page('--initialize--', {companyId: data.userMonj.companyId});
					deferred.resolve(data);
				});
			
            }

            // Hack for VTT files in Mac/Safari
            if(BrowserDetect.browser == 'Safari') {
                $rootScope.isSafari = true;
            }

            // Hack for VTT files in Mac/Safari
            if(BrowserDetect.browser == 'Safari') {
                $rootScope.isSafari = true;
            }

            if (BrowserDetect.browser == 'Explorer' && BrowserDetect.version <= 9) {
                if (toState.name != 'ieError') {
                    $location.path($state.get('ieError').url);
                    return;
                }
            } else {
                if (toState.name == 'ieError') {
                    $location.path($state.get('publicExperience').url);
                    return;
                }
            }

            var userData = userFactory.getUserData();
            var userAuth = userData.authData;
            // Set language based on user profile here to override language if it hasn't updated in the system yet
            /*$rootScope.lang = userData.locale;
            $translate.use(userData.locale);*/


            // Rereoute the user after they've logged in.
            var routeTo = dataTransferFactory.get('rerouteUser');
            if(routeTo && userAuth) {
                dataTransferFactory.remove('rerouteUser');
                $location.path(dataTransferFactory.get('previousRoute'));
            } else {
                if($location.path() != $state.get('publicExperience').url) {
                    dataTransferFactory.set('previousRoute', $location.path());
                }
            }

            // If the user is viewing from a standalone bookmark (home screen icon) on iOS...
            var token = $location.search().access_token;

            if(!token) {
                if(toState.name == "publicExperience" && userAuth) {
                    $location.path($state.get('authExperience').url);
                    // location.reload();
                } else if(toState.name != "publicExperience" && toState.name != "ieError"
                    && toState.name != "signUpSponsored" && toState.name != "vipRegistration"
                    && toState.name != "page404" && toState.name != "legal" && !userAuth
                ) {
                    dataTransferFactory.set('whySignUp', true);
                    $location.path($state.get('publicExperience').url);
                }
            }

            //userFactory.validateUserAuth();

            // Remember the users route if the user is in standalone mode
            if(isInStandaloneMode()) {
                var toURL = $state.href(toState.name, toParams).replace('#', '');
                var prevStateName = false;

                if(isLocalStorage()){
                    prevStateName = localStorage.getItem("standAloneRoute");
                } else {
                    prevStateName = getCookie('standAloneRoute', toState.name);
                }

                // Check for our standalone-mode flag. If found, simply store the users route. If not, redirect them to their last visited page
                if(!dataTransferFactory.get('standAloneRoute') && prevStateName) {
                    $location.path(prevStateName);
                }

                // Set a flag so that we know the page has just been opened for the first time in standalone mode
                dataTransferFactory.set('standAloneRoute', true);
                if(toState.name != 'publicExperience' && toState.name != 'authExperience') {
                    if (isLocalStorage()) {
                        localStorage.setItem("standAloneRoute", toURL);
                    } else {
                        setCookie('standAloneRoute', toURL);
                    }
                }
            }

            // STORE THE PREVIOUS URL
            var prevURL = $state.href(fromState.name, fromParams);
            var prevScroll = $(window).scrollTop();

            var parentPage = dataTransferFactory.get('prevPage');

            dataTransferFactory.set('prevPage', prevURL ? { url: prevURL.substr(1), scroll: (prevScroll ? prevScroll : 0)} : null);
            dataTransferFactory.set('parentPage', parentPage ? parentPage : null);

            // CLEAN-UP
            /*if (toState.name == "publicExperience") {
                dataTransferFactory.remove('recipeId');
            }*/

            // RESETING THE SCROLLBAR
            /*$('html').css('overflow', 'auto');
            $('body').css('margin-left', '0px');
            $('.ae-header-container').css('width', '100%');
            $('#hs-beacon iframe:first-child').css('right', '18px');
            $('#hs-beacon iframe:last-child').css('right', '15px');*/

            var toURL = $state.href(toState.name, toParams);
            if (parentPage && toURL && toURL.substr(1) == parentPage.url)
                $('html, body').scrollTop(parentPage.scroll);
            else
                $('html, body').scrollTop(0);

            // SET AND UNSET THE WIDTH OVERRIDE
            if (!userAuth || toState.name == "publicExperience" || toState.name == "guidedRecipe" || ($location.path() == $state.get('signUpConfirmed').url) || toState.name == "guidedLesson" || toState.name == "guidedLessonWidget") {
                $("html").addClass("pe-max-width-override");
                $("body").addClass("pe-max-width-override");
                $("#app-wrapper").addClass("pe-max-width-override");
            } else {
                $("html").removeClass("pe-max-width-override");
                $("body").removeClass("pe-max-width-override");
                $("#app-wrapper").removeClass("pe-max-width-override");
            }

            if(toState.name == "authExperience") {
                $("body").addClass("gray-background");
            } else {
                $("body").removeClass("gray-background");
            }

            // Store close fields for external applications hosting our app within their systems
            var primary = $location.search().primary;
            var secondary = $location.search().secondary;
            var tertiary = $location.search().tertiary;
            if(primary) {
                dataTransferFactory.set('primaryColorChange', primary);
            }
            if(secondary) {
                dataTransferFactory.set('secondaryColorChange', secondary);
            }
            if(tertiary) {
                dataTransferFactory.set('tertiaryColorChange', tertiary);
            }
            var closeFrame = $location.search().enableClose;
            if(closeFrame) {
                dataTransferFactory.set('applicationCloseFrame', closeFrame);
            }
            var stringify = $location.search().stringify;
            if(stringify) {
                dataTransferFactory.set('stringify', stringify);
            }
            var partnerId = $location.search().partnerId;
            if(partnerId) {
                dataTransferFactory.set('applicationPartnerId', partnerId);
            }
        });
    }]);

    // Gets a cookie based on its name - thank you stack-overflow ;)
    function getCookie(name) {
        var value = "; " + document.cookie;
        var parts = value.split("; " + name + "=");
        if (parts.length == 2) return parts.pop().split(";").shift();
    }

    function setCookie(cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays*24*60*60*1000));
        var expires = "expires="+ d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    // Check if user is viewing in stand alone mode
    function isInStandaloneMode() {
        var chrome = window.matchMedia('(display-mode: standalone)').matches;
        var iOS = window.navigator.standalone;

        if(chrome || iOS) {
            return true;
        }

        return false;
    }

    // These must all be updated with new route names
    function getPageName(stateName) {
        switch(stateName) {
            case 'about':
                return "About";
            case 'authExperience':
                return "Home";
            case 'contact':
                return "Contact";
            case 'legal':
                return "Legal";
            case 'login':
                return "Sign In";
            case 'publicExperience':
                return "Public";
            case 'resourceLibrary':
                return "Monj Academy";
        }
    }

    // Raygun
    // ** START ***
    app.config(['$provide', function ($provide) {
        $provide.decorator("$exceptionHandler", ['$delegate', function($delegate) {
            return function (exception, cause) {
                Raygun.send(exception);
                $delegate(exception, cause);
            }
        }])
    }]);
    // *** END ***

    /*******************************************
     * Cache Buster
     */

    app.config(["$provide", function($provide) {
        return $provide.decorator("$http", ["$delegate", function($delegate) {
            var get = $delegate.get;
            $delegate.get = function(url, config) {
                // Check is to avoid breaking AngularUI ui-bootstrap-tpls.js: "template/accordion/accordion-group.html"
                if (!~url.indexOf('template/')) {
                    // Append ?v=[cacheBustVersion] to url
                    url += (url.indexOf("?") === -1 ? "?" : "&");
                    url += "v=" + cacheBustVersion;
                }
                return get(url, config);
            };
            return $delegate;
        }]);
    }]);

    /***
     * End Cache Buster
     *******************************************/

    app.config(['$stateProvider', '$urlRouterProvider', '$locationProvider', '$translateProvider', function ($stateProvider, $urlRouterProvider, $locationProvider, $translateProvider) {

        $translateProvider
            .useStaticFilesLoader({
                prefix: 'translations/',
                suffix: '.json'
            })
            .fallbackLanguage('en')
            .preferredLanguage(defaultLang)
            .useSanitizeValueStrategy(null);

        $urlRouterProvider
            .rule(function ($injector, $location) {
                var path = $location.path();
                var normalized = path;
                /*if(path.indexOf('recipe') == -1 && path.indexOf('change-password') == -1) {
                    normalized = path.toLowerCase();
                }*/

                if (path !== normalized) {
                    return normalized;
                }
            })
            .otherwise(function ($injector, $location) {
                switch ($location.$$url) {
                    case "":
                        return '/';
                    default:
                        return '/page-not-found';
                }
            });

        $stateProvider

            .state('publicExperience', {
                url: '/',
                resolve: {
                    factoryInit: ['$q', '$timeout', 'contentfulFactory', 'userFactory', function($q, $timeout, contentfulFactory, userFactory) {
                        var deferred = $q.defer();
                        var pageContent = '4ZyzGUask80Q82aWQKGq4k';

                        userFactory.getDomain(1).then(function(result) {
                            contentfulFactory.setContentfulClient(result.cmsSpaceId, result.cmsAccessToken, result.cmsUrl);
                            contentfulFactory.getContentfulData(pageContent).then(function(data) {
                                deferred.resolve(data);
                            });
                        });

                        return deferred.promise;
                    }]
                },
                views: {
                    '': {
                        templateUrl: 'partials/publicExperience.html',
                        controller: 'PublicExperienceController'
                    },
                    'signUpCheck@publicExperience': {
                        controller: 'SignUpController',
                        templateUrl: 'partials/popups/signUpCheck.html'
                    },
                    'login@publicExperience': {
                        controller: 'UserController',
                        templateUrl: 'partials/popups/login.html'
                    },
                    'passwordReset@publicExperience': {
                        controller: 'UserController',
                        templateUrl: 'partials/popups/passwordReset.html'
                    },
                    'whySignUp@publicExperience': {
                        controller: 'SignUpController',
                        templateUrl: 'partials/popups/whySignUp.html'
                    },
                    'vipRegistration@publicExperience': {
                        controller: 'UserController',
                        templateUrl: 'partials/popups/vipRegistration.html'
                    },
                    'signUpSponsored@publicExperience': {
                        controller: 'UserController',
                        templateUrl: 'partials/popups/signUpSponsored.html'
                    },
                    'convertUser@publicExperience': {
                        controller: 'UserController',
                        templateUrl: 'partials/popups/convertUser.html'
                    },
                    'changePasswordWithLink@publicExperience': {
                        templateUrl: 'partials/popups/changePassword.html',
                        controller: 'MiscPagesController'
                    }
                }
            })

            .state('authExperience', {
                url: '/home',
                resolve: {
                    userInit: ['userFactory', '$q', '$rootScope', function (userFactory, $q, $rootScope) {
                        var deferred = $q.defer();
                        userFactory.getUser().then(function(data) {
                            // Append Image Size Data to User Object
                            data.userMonj.oneToOne = $rootScope.oneToOne;
                            data.userMonj.fourToThree = $rootScope.fourToThree;
                            data.userMonj.sixteenToNine = $rootScope.sixteenToNine;
                            deferred.resolve(data);
                        });
                        return deferred.promise;
                    }]
                },
                views: {
                    '': {
                        templateUrl: 'partials/authExperience.html',
                        controller: 'AuthExperienceController'
                    },

                    'header@authExperience': {
                        templateUrl: 'partials/headerPartial.html',
                        controller: 'HeaderController'
                    },
                    'mission@authExperience': {
                        templateUrl: 'partials/dailyMissionPartial.html',
                    },
                    'quiz@authExperience': {
                        templateUrl: 'partials/quizPartial.html',
                    },
                    'intro-video@authExperience': {
                        templateUrl: 'partials/authExperienceIntroVideoPartial.html',
                    },

                    'challenge@authExperience': {
                        templateUrl: 'partials/challenge/headerPartial.html',
                    },
                    'footer@authExperience': {
                        templateUrl: 'partials/footerPartial.html'
                    },
                    'completed@authExperience': {
                        templateUrl: 'partials/completedAnimationPartial.html',
                        controller: 'UgcPostController'
                    },
                    'post@authExperience': {
                        templateUrl: 'partials/ugcPostPartial.html',
                        controller: 'UgcPostController'
                    }
                }
            })

            .state('challenge', {
                url: '/challenge',
                resolve: {
                    userInit: ['userFactory', '$q', '$rootScope', function (userFactory, $q, $rootScope) {
                        var deferred = $q.defer();
                        userFactory.getUser().then(function(data) {
                            // Perform a Regex replace on the rule descriptions to convert brackets [] into <a></a> tags
                            for (x = 0; x < data.userMonj.challenge.team.teamRules.length; x++) {
                                data.userMonj.challenge.team.teamRules[x].description = data.userMonj.challenge.team.teamRules[x].description.replace(/\[/g, '<a>');
                                data.userMonj.challenge.team.teamRules[x].description = data.userMonj.challenge.team.teamRules[x].description.replace(/\]/g, '</a>');
                            }
                            for (x = 0; x < data.userMonj.challenge.team.individualRules.length; x++) {
                                data.userMonj.challenge.team.individualRules[x].description = data.userMonj.challenge.team.individualRules[x].description.replace(/\[/g, '<a>');
                                data.userMonj.challenge.team.individualRules[x].description = data.userMonj.challenge.team.individualRules[x].description.replace(/\]/g, '</a>');
                            }
                            // Append Image Size Data to User Object
                            data.userMonj.oneToOne = $rootScope.oneToOne;
                            data.userMonj.fourToThree = $rootScope.fourToThree;
                            data.userMonj.sixteenToNine = $rootScope.sixteenToNine;
                            deferred.resolve(data);
                        });
                        return deferred.promise;
                    }],
                    teamsInit: ['userFactory', '$q', function (userFactory, $q) {
                        var deferred = $q.defer();

                        userFactory.getUser().then(function(result) {
                            if(result.userMonj.challenge){
                                userFactory.getChallengeTeamsOpen(result.userMonj.challenge.challengeId).then(function(data) {
                                    deferred.resolve(data);
                                });
                            } else {
                              deferred.resolve([]);
                            }
                        });

                        return deferred.promise;
                    }],
                },
                views: {
                    '': {
                        templateUrl: 'partials/challenge/challenge.html',
                        controller: 'ChallengeController',
                    },
                    'header@challenge': {
                        templateUrl: 'partials/headerPartial.html',
                        controller: 'HeaderController'
                    },
                    'challengeHeader@challenge': {
                        templateUrl: 'partials/challenge/challengeHeader.html',
                        controller: 'ChallengeHeaderController'
                    },
                    'footer@challenge': {
                        templateUrl: 'partials/footerPartial.html'
                    }
                }
            })

            .state('joinChallenge', {
                url: '/challenge/join',
                resolve: {
                    userInit: ['userFactory', '$q', '$rootScope', function (userFactory, $q, $rootScope) {
                        var deferred = $q.defer();
                        userFactory.getUser().then(function(data) {
                            // Append Image Size Data to User Object
                            data.userMonj.oneToOne = $rootScope.oneToOne;
                            data.userMonj.fourToThree = $rootScope.fourToThree;
                            data.userMonj.sixteenToNine = $rootScope.sixteenToNine;
                            deferred.resolve(data);
                        });
                        return deferred.promise;
                    }],
                    teamsInit: ['userFactory', '$q', function (userFactory, $q) {
                        var deferred = $q.defer();

                        userFactory.getUser().then(function(result) {
                            if(result.userMonj.challenge){
                                userFactory.getChallengeTeamsOpen(result.userMonj.challenge.challengeId).then(function(data) {
                                    deferred.resolve(data);
                                });
                            }
                        });

                        return deferred.promise;
                    }]
                },
                views: {
                    '': {
                        templateUrl: 'partials/challenge/challengeJoinTeam.html',
                        controller: 'ChallengeJoinController',
                    },
                    'teams@joinChallenge': {
                      templateUrl: 'partials/challenge/teamRowsView.html',
                      controller: 'TeamRowsController'
                    },
                    'header@joinChallenge': {
                        templateUrl: 'partials/headerPartial.html',
                        controller: 'HeaderController'
                    },
                    'challengeHeader@joinChallenge': {
                        templateUrl: 'partials/challenge/challengeHeader.html',
                        controller: 'ChallengeHeaderController'
                    },
                    'footer@joinChallenge': {
                        templateUrl: 'partials/footerPartial.html'
                    }
                }
            })

            .state('allTeamsChallenge', {
                url: '/challenge/teams',
                resolve: {
                    userInit: ['userFactory', '$q', '$rootScope', function (userFactory, $q, $rootScope) {
                        var deferred = $q.defer();
                        userFactory.getUser().then(function(data) {
                            // Append Image Size Data to User Object
                            data.userMonj.oneToOne = $rootScope.oneToOne;
                            data.userMonj.fourToThree = $rootScope.fourToThree;
                            data.userMonj.sixteenToNine = $rootScope.sixteenToNine;
                            deferred.resolve(data);
                        });
                        return deferred.promise;
                    }],
                    teamsInit: ['userFactory', '$q', function (userFactory, $q) {
                        var deferred = $q.defer();

                        userFactory.getUser().then(function(result) {
                            if(result.userMonj.challenge){
                                userFactory.getChallengeTeams(result.userMonj.challenge.challengeId).then(function(data) {
                                    deferred.resolve(data);
                                });
                            }
                        });

                        return deferred.promise;
                    }]
                },
                views: {
                    '': {
                        templateUrl: 'partials/challenge/challengeAllTeams.html',
                        controller: 'ChallengeAllTeamsController',
                    },
                    'header@allTeamsChallenge': {
                        templateUrl: 'partials/headerPartial.html',
                        controller: 'HeaderController'
                    },
                    'progress-circle@allTeamsChallenge': {
                        templateUrl: 'partials/progressCirclePartial.html'
                    },
                    'challengeHeader@allTeamsChallenge': {
                        templateUrl: 'partials/challenge/challengeHeader.html',
                        controller: 'ChallengeHeaderController'
                    },
                    'footer@allTeamsChallenge': {
                        templateUrl: 'partials/footerPartial.html'
                    }
                }
            })

            .state('teamChallenge', {
                url: '/challenge/team',
                resolve: {
                    userInit: ['userFactory', '$q', '$rootScope', function (userFactory, $q, $rootScope) {
                        var deferred = $q.defer();
                        userFactory.getUser().then(function(data) {
                            // Append Image Size Data to User Object
                            data.userMonj.oneToOne = $rootScope.oneToOne;
                            data.userMonj.fourToThree = $rootScope.fourToThree;
                            data.userMonj.sixteenToNine = $rootScope.sixteenToNine;
                            deferred.resolve(data);
                        });
                        return deferred.promise;
                    }],
                    teamInit: ['userFactory', '$q', '$location', '$state', function (userFactory, $q, $location, $state) {
                        var deferred = $q.defer();

                        userFactory.getUser().then(function(user) {
                            if(user.userMonj.challenge) {
                                if (user.userMonj.challenge.team) {
                                    userFactory.getTeamProfileDetailed(user.userMonj.challenge.team.teamId, user.userMonj.userId).then(function (teamProfile) {
                                        // Perform a Regex replace on the rule descriptions to convert brackets [] into <a></a> tags
                                        for (x = 0; x < teamProfile.teamRules.length; x++) {
                                            teamProfile.teamRules[x].description = teamProfile.teamRules[x].description.replace(/\[/g, '<a>');
                                            teamProfile.teamRules[x].description = teamProfile.teamRules[x].description.replace(/\]/g, '</a>');
                                        }
                                        for (x = 0; x < teamProfile.individualRules.length; x++) {
                                            teamProfile.individualRules[x].description = teamProfile.individualRules[x].description.replace(/\[/g, '<a>');
                                            teamProfile.individualRules[x].description = teamProfile.individualRules[x].description.replace(/\]/g, '</a>');
                                        }


                                        // Since we are only given a contentType and contentId, we must cycle through each content type and map
                                        // their data to our teamProfile.completedTasks object. Current big-O after data loads = 0(n*n^3)
                                        // Another option is to call a function on each ng-repeat object. The big-O would be larger than n*n^3 though.
                                        // Yet another option is to make a specific API call for the individual recipe/module/powerup, but that would
                                        // add a significant amount of API calls which would drastically slow the page load time down.
                                        // It may be best to do these calculations on server side and directly pass the data in the completedTasks object.
                                        // Also, it's important to note that these calls must happen before the page loads for the background images to work.
                                        if (teamProfile.completedTasks) {

                                            var unique = {};
                                            unique.meal = [];
                                            unique.powerup = [];
                                            unique.lesson = [];

                                            userFactory.getRecipes(user.userMonj.userId).then(function (recipes) {
                                                var recipes = recipes;
                                                userFactory.getPowerUps(user.userMonj.userId).then(function (powerUps) {
                                                    var powerups = powerUps;
                                                    userFactory.getlessons(user.userMonj.userId).then(function (lessons) {
                                                        var modules = lessons;
                                                        teamProfile.completedTasks.forEach(function (obj, index, arr) {
                                                            switch (obj.contentType.toLowerCase()) {
                                                                case 'meal':
                                                                    // Append data to completedTask object
                                                                    teamProfile.completedTasks[index].content = recipes.filter(function (result) {
                                                                        if(teamProfile.completedTasks[index].contentId == result.recipeId) {
                                                                            if(unique.meal[result.recipeId]) {
                                                                                unique.meal[result.recipeId]++;
                                                                            } else {
                                                                                unique.meal[result.recipeId] = 1;
                                                                                return teamProfile.completedTasks[index].contentId == result.recipeId;
                                                                            }
                                                                        }
                                                                    })[0];
                                                                    break;
                                                                case 'powerup':
                                                                    // Append data to completedTask object
                                                                    teamProfile.completedTasks[index].content = powerups.filter(function (result) {
                                                                        if(teamProfile.completedTasks[index].contentId == result.powerUpId) {
                                                                            if(unique.powerup[result.powerUpId]) {
                                                                                unique.powerup[result.powerUpId]++;
                                                                            } else {
                                                                                unique.powerup[result.powerUpId] = 1;
                                                                                return teamProfile.completedTasks[index].contentId == result.powerUpId;
                                                                            }
                                                                        }
                                                                    })[0];
                                                                    break;
                                                                case 'lesson':
                                                                    // Append data to completedTask object
                                                                    teamProfile.completedTasks[index].content = modules.filter(function (result) {
                                                                        if(teamProfile.completedTasks[index].contentId == result.lessonId) {
                                                                            if(unique.lesson[result.lessonId]) {
                                                                                unique.lesson[result.lessonId]++;
                                                                            } else {
                                                                                unique.lesson[result.lessonId] = 1;
                                                                                return teamProfile.completedTasks[index].contentId == result.lessonId;
                                                                            }
                                                                        }
                                                                    })[0];
                                                                    break;
                                                            }
                                                        });

                                                        // Apply count to each item
                                                        teamProfile.completedTasks.forEach(function (obj, index, arr) {
                                                            if(teamProfile.completedTasks[index].content) {
                                                                teamProfile.completedTasks[index].count = unique[obj.contentType.toLowerCase()][teamProfile.completedTasks[index].contentId];
                                                            }
                                                        });

                                                        deferred.resolve(teamProfile);
                                                    });
                                                });
                                            });
                                        }
                                    });
                                }
                            } else {
                                $location.path($state.get('authExperience').url);
                            }
                        });

                        return deferred.promise;
                    }],
                    teamsInit: ['userFactory', '$q', function (userFactory, $q) {
                        var deferred = $q.defer();

                        userFactory.getUser().then(function(result) {
                            if(result.userMonj.challenge){
                                userFactory.getChallengeTeams(result.userMonj.challenge.challengeId).then(function(data) {
                                    deferred.resolve(data);
                                });
                            }
                        });

                        return deferred.promise;
                    }]
                },
                views: {
                    '': {
                        templateUrl: 'partials/challenge/teamChallenge.html',
                        controller: 'ChallengeTeamController',
                    },
                    'header@teamChallenge': {
                        templateUrl: 'partials/headerPartial.html',
                        controller: 'HeaderController'
                    },
                    'teams@teamChallenge': {
                        templateUrl: 'partials/challenge/teamRowsView.html',
                        controller: 'TeamRowsController'
                    },
                    'challengeHeader@teamChallenge': {
                        templateUrl: 'partials/challenge/challengeHeader.html',
                        controller: 'ChallengeHeaderController'
                    },
                    'progress-circle@teamChallenge': {
                        templateUrl: 'partials/progressCirclePartial.html'
                    },
                    'footer@teamChallenge': {
                        templateUrl: 'partials/footerPartial.html'
                    },
                    'post@teamChallenge': {
                        templateUrl: 'partials/ugcPostPartial.html',
                        controller: 'UgcPostController'
                    }
                }
            })

            .state('challengeHeader', {
                views: {
                    '': {
                        templateUrl: 'partials/challenge/challengeHeader.html',
                        controller: 'ChallengeHeaderController',
                    }
                }
            })

            .state('login', {
                url: '/login',
                controller: 'UserController',
                templateUrl: 'partials/popups/login.html'
            })

            .state('passwordReset', {
                url: '/password-reset',
                controller: 'UserController',
                templateUrl: 'partials/popups/passwordReset.html'
            })

            .state('signUpCheck', {
                url: '/signup-check',
                controller: 'SignUpController',
                templateUrl: 'partials/popups/signUpCheck.html'
            })

            .state('vipRegistration', {
                url: '/vip/:code',
                controller: 'RegistrationRedirectController',
                resolve: {
                    init: ['$stateParams', 'userFactory', 'dataTransferFactory', function ($stateParams, userFactory, dataTransferFactory) {
                        var promise = userFactory.checkInvite($stateParams.code).then(function(data) {
                            data.inviteCode = $stateParams.code;
                            dataTransferFactory.set('vipRegistration',data);
                        });
                        return promise;
                    }]
                }
            })

            .state('inviteToTeam', {
                url: '/challenge/:teamId/join',
                resolve: {
                    init: ['$location', 'userFactory', '$stateParams', 'dataTransferFactory', '$state', function ($location, userFactory, $stateParams, dataTransferFactory, $state) {

                        userFactory.getUser().then(function(user) {
                            userFactory.joinATeam($stateParams.teamId, user.userMonj.userId).then(function (result) {
                                if(result) {
                                    if (result.success) {
                                        dataTransferFactory.set('joinChallengeSuccess', true);
                                        $location.path($state.get('teamChallenge').url);
                                    } else {
                                        dataTransferFactory.set('joinChallengeFailed', result.errorCode);
                                        $location.path($state.get('authExperience').url);
                                    }
                                }
                            }).catch(function(err) {
                                dataTransferFactory.set('joinChallengeFailed', err.message);
                                $location.path($state.get('authExperience').url);
                            });
                        });
                    }]
                }
            })

            .state('convertUser', {
                url: '/convert-user',
                controller: 'UserController',
                templateUrl: 'partials/popups/convertUser.html'
            })

            .state('signUpSponsored', {
                url: '/activate/:activationCode',
                controller: 'RegistrationRedirectController',
                resolve: {
                    init: ['$stateParams', '$location', 'userFactory', 'dataTransferFactory', function ($stateParams, $location, userFactory, dataTransferFactory) {
                        var activationCode = $stateParams.activationCode;
                        var promise = userFactory.checkActivation(activationCode).then(function(data) {
                            if(data.found) {
                                /*if($location.search().access_token) {
                                    dataTransferFactory.set('isSSO', true);
                                }*/
                                dataTransferFactory.set('isSSO', true);
                                data.activationCode = activationCode;
                                dataTransferFactory.set('generalUserRegistration',data);
                            } else {
                                dataTransferFactory.set('convert', false);
                            }
                        });
                        return promise;
                    }]
                }
            })

            .state('signUpPagination', {
                url: '/signup-confirmed',
                views: {
                    '': {
                        templateUrl: 'partials/signUpConfirmed.html',
                        controller: 'SignUpConfirmedController'
                    },
                    'signUpDots@signUpPagination': {
                        templateUrl: 'partials/signUpDots.html'
                    }
                },
                resolve: {
                    userInit: ['userFactory', '$q', '$rootScope', function (userFactory, $q, $rootScope) {
                        var deferred = $q.defer();
                        userFactory.getUser().then(function(data) {
                            // Append Image Size Data to User Object
                            data.userMonj.oneToOne = $rootScope.oneToOne;
                            data.userMonj.fourToThree = $rootScope.fourToThree;
                            data.userMonj.sixteenToNine = $rootScope.sixteenToNine;
                            deferred.resolve(data);
                        });
                        return deferred.promise;
                    }],
                    purposeInit: ['userFactory', '$q', function (userFactory, $q) {
                        var deferred = $q.defer();
                        userFactory.getUser().then(function(data) {
                            userFactory.getPurposes(data.userMonj.userId).then(function(result) {
                                for (var i = result.length - 1; i > 0; i--) {
                                    var j = Math.floor(Math.random() * (i + 1));
                                    var temp = result[i];
                                    result[i] = result[j];
                                    result[j] = temp;
                                }
                                deferred.resolve(result);
                            });
                        });
                        return deferred.promise;
                    }],

                }
            })

            .state('changePassword', {
                url: '/change-password',
                templateUrl: 'partials/popups/changePassword.html',
                controller: 'MiscPagesController'
            })

            .state('changePasswordWithLink', {
                url: '/change-password/:email/:pass',
                controller: 'RegistrationRedirectController',
                resolve: {
                    init: ['$stateParams', 'dataTransferFactory', function ($stateParams, dataTransferFactory) {
                        var email = $stateParams.email;
                        var pass = $stateParams.pass;
                        var data = {
                            email: email,
                            pass: pass
                        };
                        dataTransferFactory.set('resetPassword',data);
                    }]
                }
            })

            .state('whySignUp', {
                url: '/why-signup',
                controller: 'SignUpController',
                templateUrl: 'partials/popups/whySignUp.html'
            })

            .state('expandedRecipe', {
                url: '/recipes/:recipeId',
                resolve: {
                    userInit: ['userFactory', '$q', '$rootScope', function (userFactory, $q, $rootScope) {
                        var deferred = $q.defer();
                        userFactory.getUser().then(function(data) {
                            // Append Image Size Data to User Object
                            data.userMonj.oneToOne = $rootScope.oneToOne;
                            data.userMonj.fourToThree = $rootScope.fourToThree;
                            data.userMonj.sixteenToNine = $rootScope.sixteenToNine;
                            deferred.resolve(data);
                        });
                        return deferred.promise;
                    }],
                    factoryInit: ['$q', '$timeout', 'contentfulFactory', 'userFactory', '$stateParams', '$location', '$state', function($q, $timeout, contentfulFactory, userFactory, $stateParams, $location, $state) {
                        var deferred = $q.defer();

                        userFactory.getUser().then(function (user) {
                            userFactory.getRecipe(user.userMonj.userId, $stateParams.recipeId).then(function (recipe) {
                                contentfulFactory.setContentfulClient(recipe.cmsSpaceId, recipe.cmsAccessToken, recipe.cmsUrl);
                                contentfulFactory.getContentfulData(recipe.cmsContentType, recipe.cmsId).then(function (data) {
                                    data = data[0];
                                    // Append required monjAPI data to cms data
                                    data.fields.hasCheatSheet = recipe.hasCheatSheet;
                                    data.fields.isSaved = recipe.isSaved;
                                    data.fields.recipeId = recipe.recipeId;
                                    data.fields.gameValue = recipe.gameValue;
                                    data.fields.recipe = recipe;
                                    deferred.resolve(data);
                                });
                            });
                        });

                        return deferred.promise;
                    }],
                    colorInit: [function() {
                        return false;
                    }]
                },
                views: {
                    '': {
                        templateUrl: 'partials/recipes/expandedRecipe.html',
                        controller: 'ExpandedRecipeController'
                    },

                    'header@expandedRecipe': {
                        templateUrl: 'partials/headerPartial.html',
                        controller: 'HeaderController'
                    },

                    'ingredient@expandedRecipe': {
                        templateUrl: 'partials/recipes/expandedRecipeIngredientPartial.html'
                    },

                    'flavorFramework@expandedRecipe': {
                        templateUrl: 'partials/recipes/expandedRecipeFlavorFrameworkPartial.html'
                    },

                    /*'question@expandedRecipe': {
                        templateUrl: 'partials/expandedRecipeQuestionPartial.html'
                    },*/
                    'footer@expandedRecipe': {
                        templateUrl: 'partials/footerPartial.html'
                    },
                    'sharePopup@expandedRecipe': {
                        templateUrl: 'partials/popups/sharePopup.html'
                    }
                }
            })

            .state('guidedRecipe', {
                url: '/guided-recipe/:recipeId',
                resolve: {
                    userInit: ['userFactory', '$q', '$rootScope', function (userFactory, $q, $rootScope) {
                        var deferred = $q.defer();
                        userFactory.getUser().then(function(data) {
                            // Append Image Size Data to User Object
                            data.userMonj.oneToOne = $rootScope.oneToOne;
                            data.userMonj.fourToThree = $rootScope.fourToThree;
                            data.userMonj.sixteenToNine = $rootScope.sixteenToNine;
                            deferred.resolve(data);
                        });
                        return deferred.promise;
                    }],
                    factoryInit: ['$q', '$timeout', 'contentfulFactory', 'userFactory', '$stateParams', function($q, $timeout, contentfulFactory, userFactory, $stateParams) {
                        var deferred = $q.defer();

                        userFactory.getUser().then(function(user) {
                            userFactory.getRecipe(user.userMonj.userId, $stateParams.recipeId, true).then(function(recipe) {
                                contentfulFactory.setContentfulClient(recipe.cmsSpaceId, recipe.cmsAccessToken, recipe.cmsUrl);
                                contentfulFactory.getContentfulData(recipe.cmsContentType, recipe.cmsId).then(function(data) {
                                    data = data[0];
                                    // Append required monjAPI data to cms data
                                    data.fields.recipeId = recipe.recipeId;
                                    deferred.resolve(data);
                                });
                            });
                        });
                        return deferred.promise;
                    }],
                    dictionaryInit: ['$q', '$timeout', 'contentfulFactory', 'userFactory', '$stateParams', function($q, $timeout, contentfulFactory, userFactory, $stateParams) {
                        var deferred = $q.defer();

                        userFactory.getUser().then(function(user) {
                            userFactory.getRecipe(user.userMonj.userId, $stateParams.recipeId, true).then(function(recipe) {
                                contentfulFactory.setContentfulClient(recipe.cmsSpaceId, recipe.cmsAccessToken, recipe.cmsUrl);
                                contentfulFactory.getContentfulData('rFLtIpS4gKKqIWeI40aC2').then(function(data) {

                                    var terms = [];
                                    for(var x = 0; x < data.length; x++) {
                                        terms[x] = {
                                            'entry': data[x].fields.entry,
                                            'value': encodeURIComponent(data[x].fields.entry),
                                            'definition': data[x].fields.definition,
                                            'term': data[x].fields.entry
                                        };
                                    }

                                    deferred.resolve(terms);
                                });
                            });
                        });

                        return deferred.promise;
                    }],
                    colorInit: [function() {
                        return false;
                    }]
                },
                views: {
                    '': {
                        templateUrl: 'partials/recipes/guidedRecipe.html',
                        controller: 'GuidedRecipeController'
                    },

                    'gr-detail@guidedRecipe': {
                        templateUrl: "partials/recipes/guidedRecipePartial.html",
                    },

                    'gr-menu@guidedRecipe': {
                        templateUrl: 'partials/recipes/guidedRecipeMenuPartial.html',
                    },

                    'ingr-equip@guidedRecipe': {
                        templateUrl: 'partials/recipes/expandedRecipeIngredientPartial.html'
                    },

                    'gr-comment@guidedRecipe': {
                        templateUrl: 'partials/recipes/guidedRecipeCommentPartial.html',
                        controller: 'RibbonController'
                    },

                    'ribbon@guidedRecipe': {
                        templateUrl: 'partials/recipes/guidedRecipeRibbon.html',
                        controller: 'RibbonController'
                    },
                    'completed@guidedRecipe': {
                        templateUrl: 'partials/completedAnimationPartial.html',
                        controller: 'UgcPostController'
                    },
                    'post@guidedRecipe': {
                        templateUrl: 'partials/ugcPostPartial.html',
                        controller: 'UgcPostController'
                    }
                }
            })

            .state('lessons', {
                url: '/lessons',
                resolve: {
                    factoryInit: ['userFactory', '$q', function (userFactory, $q) {
                        var deferred = $q.defer();
                        userFactory.getUser().then(function(user) {
                            userFactory.getlessons(user.userMonj.userId).then(function(lessons) {
                                deferred.resolve(lessons);
                            });
                        });

                        return deferred.promise;
                    }],
                    userInit: ['userFactory', '$q', '$rootScope', function (userFactory, $q, $rootScope) {
                        var deferred = $q.defer();
                        userFactory.getUser().then(function(data) {
                            // Append Image Size Data to User Object
                            data.userMonj.oneToOne = $rootScope.oneToOne;
                            data.userMonj.fourToThree = $rootScope.fourToThree;
                            data.userMonj.sixteenToNine = $rootScope.sixteenToNine;
                            deferred.resolve(data);
                        });
                        return deferred.promise;
                    }]
                },
                views: {
                    '': {
                        templateUrl: 'partials/lessons/lessons.html',
                        controller: 'LessonsController'
                    },
                    'header@lessons': {
                        templateUrl: 'partials/headerPartial.html',
                        controller: 'HeaderController'
                    },
                    'footer@lessons': {
                        templateUrl: 'partials/footerPartial.html'
                    }
                }
            })

            .state('lesson', {
                url: '/lessons/:id',
                resolve: {
                    factoryInit: ['$q', '$timeout', 'contentfulFactory', 'userFactory', '$stateParams', function($q, $timeout, contentfulFactory, userFactory, $stateParams) {
                        var deferred = $q.defer();

                        userFactory.getUser().then(function(user) {
                            userFactory.getLesson(user.userMonj.userId, $stateParams.id).then(function(lesson) {
                                contentfulFactory.setContentfulClient(lesson.cmsSpaceId, lesson.cmsAccessToken, lesson.cmsUrl);
                                contentfulFactory.getContentfulData(lesson.cmsContentType, lesson.cmsId).then(function(data) {
                                    data = data[0];

                                    // Append required monjAPI data to cms data
                                    data.fields.lessonId = lesson.lessonId;
                                    data.fields.name = lesson.name;
                                    data.fields.thumbUrl = lesson.thumbUrl;
                                    data.fields.gameValue = lesson.gameValue;
                                    data.fields.isSaved = lesson.isSaved;
                                    data.fields.pageCount = lesson.pageCount;

                                    deferred.resolve(data);
                                });
                            });
                        });

                        return deferred.promise;
                    }],
                    userInit: ['userFactory', '$q', '$rootScope', function (userFactory, $q, $rootScope) {
                        var deferred = $q.defer();
                        userFactory.getUser().then(function(data) {
                            // Append Image Size Data to User Object
                            data.userMonj.oneToOne = $rootScope.oneToOne;
                            data.userMonj.fourToThree = $rootScope.fourToThree;
                            data.userMonj.sixteenToNine = $rootScope.sixteenToNine;
                            deferred.resolve(data);
                        });
                        return deferred.promise;
                    }],
                    colorInit: [function() {
                        return false;
                    }]
                },
                views: {
                    '': {
                        templateUrl: 'partials/lessons/expandedLesson.html',
                        controller: 'ExpandedLessonController'
                    },
                    'header@lesson': {
                        templateUrl: 'partials/headerPartial.html',
                        controller: 'HeaderController'
                    },
                    'footer@lesson': {
                        templateUrl: 'partials/footerPartial.html'
                    },
                    'sharePopup@lesson': {
                        templateUrl: 'partials/popups/sharePopup.html'
                    },
                    'completed@lesson': {
                        templateUrl: 'partials/completedAnimationPartial.html',
                        controller: 'UgcPostController'
                    },
                    'post@lesson': {
                        templateUrl: 'partials/ugcPostPartial.html',
                        controller: 'UgcPostController'
                    }
                }
            })

            .state('guidedLesson', {
                url: '/guided-lesson/:id',
                resolve: {
                    factoryInit: ['$q', '$timeout', 'contentfulFactory', 'userFactory', '$stateParams', function($q, $timeout, contentfulFactory, userFactory, $stateParams) {
                        var deferred = $q.defer();

                        userFactory.getUser().then(function(user) {
                            userFactory.getLesson(user.userMonj.userId, $stateParams.id, true).then(function(lesson) {
                                contentfulFactory.setContentfulClient(lesson.cmsSpaceId, lesson.cmsAccessToken, lesson.cmsUrl);
                                contentfulFactory.getContentfulData(lesson.cmsContentType, lesson.cmsId).then(function(data) {
                                    data = data[0];

                                    // Append required monjAPI data to cms data
                                    data.fields.previousAnswers = lesson.previousAnswers;
                                    data.fields.isSaved = lesson.isSaved;

                                    deferred.resolve(data);
                                });
                            });
                        });

                        return deferred.promise;
                    }],
                    userInit: ['userFactory', '$q', '$rootScope', function (userFactory, $q, $rootScope) {
                        var deferred = $q.defer();
                        userFactory.getUser().then(function(data) {
                            // Append Image Size Data to User Object
                            data.userMonj.oneToOne = $rootScope.oneToOne;
                            data.userMonj.fourToThree = $rootScope.fourToThree;
                            data.userMonj.sixteenToNine = $rootScope.sixteenToNine;
                            deferred.resolve(data);
                        });
                        return deferred.promise;
                    }],
                    colorInit: [function() {
                        return false;
                    }]
                },
                views: {
                    '': {
                        templateUrl: 'partials/lessons/lesson.html',
                        controller: 'LessonController'
                    },
                    'menu@guidedLesson': {
                        templateUrl: 'partials/lessons/lessonsMenu.html',
                    },
                    'completed@guidedLesson': {
                        templateUrl: 'partials/completedAnimationPartial.html',
                        controller: 'UgcPostController'
                    },
                    'post@guidedLesson': {
                        templateUrl: 'partials/ugcPostPartial.html',
                        controller: 'UgcPostController'
                    }
                }
            })

            .state('legal', {
                url: '/legal',
                controller: 'MiscPagesController',
                templateUrl: 'partials/legal.html',
                resolve: {
                    factoryInit: ['$q', '$timeout', 'contentfulFactory', 'userFactory', '$stateParams', function($q, $timeout, contentfulFactory, userFactory, $stateParams) {
                        var deferred = $q.defer();

                        userFactory.getDomain(1).then(function(result) {
                            //result = result[0];
                            contentfulFactory.setContentfulClient(result.cmsSpaceId, result.cmsAccessToken, result.cmsUrl);
                            contentfulFactory.getContentfulData('4ZyzGUask80Q82aWQKGq4k').then(function(data) {
                                deferred.resolve(data);
                            });
                        });

                        return deferred.promise;
                    }]
                }
            })

            .state('myProfile', {
                url: '/profile',
                views: {
                    '': {
                        controller: 'MiscPagesController',
                        templateUrl: 'partials/myProfile.html'
                    },
                    'header@myProfile': {
                        templateUrl: 'partials/headerPartial.html',
                        controller: 'HeaderController'
                    },
                    'changeName@myProfile': {
                        templateUrl: 'partials/popups/changeName.html',
                        //controller: 'MiscPagesController'
                    },
                    'changePassword@myProfile': {
                        templateUrl: 'partials/popups/changePassword.html',
                        //controller: 'MiscPagesController'
                    },
                    'changeEmail@myProfile': {
                        //controller: 'MiscPagesController',
                        templateUrl: 'partials/popups/changeEmail.html'
                    },
                    'changeLanguage@myProfile': {
                        templateUrl: 'partials/popups/changeLanguage.html',
                        //controller: 'MiscPagesController'
                    },
                    'footer@myProfile': {
                        templateUrl: 'partials/footerPartial.html'
                    }
                },
                resolve: {
                    userInit: ['userFactory', '$q', '$rootScope', function (userFactory, $q, $rootScope) {
                        var deferred = $q.defer();
                        userFactory.getUser().then(function(data) {
                            // Append Image Size Data to User Object
                            data.userMonj.oneToOne = $rootScope.oneToOne;
                            data.userMonj.fourToThree = $rootScope.fourToThree;
                            data.userMonj.sixteenToNine = $rootScope.sixteenToNine;
                            deferred.resolve(data);
                        });
                        return deferred.promise;
                    }]
                }
            })

            .state('changeName', {
                url: '/change-name',
                controller: 'MiscPagesController',
                templateUrl: 'partials/popups/changeName.html'
            })

            .state('changeEmail', {
                url: '/change-email',
                controller: 'MiscPagesController',
                templateUrl: 'partials/popups/changeEmail.html'
            })

            .state('ieError', {
                url: '/ie-error',
                templateUrl: 'partials/ieError.html'
            })

            .state('page404', {
                url: '/page-not-found',
                templateUrl: 'partials/404.html',
                controller: 'MiscPagesController'
            })

            .state('powerUps', {
                url: '/power-ups',
                views: {
                    '': {
                        controller: 'PowerUpController',
                        templateUrl: 'partials/powerUps.html'
                    },
                    'header@powerUps': {
                        templateUrl: 'partials/headerPartial.html',
                        controller: 'HeaderController'
                    },
                    'footer@powerUps': {
                        templateUrl: 'partials/footerPartial.html'
                    }
                },
                resolve: {
                    userInit: ['userFactory', '$q', '$rootScope', function (userFactory, $q, $rootScope) {
                        var deferred = $q.defer();
                        userFactory.getUser().then(function(data) {
                            // Append Image Size Data to User Object
                            data.userMonj.oneToOne = $rootScope.oneToOne;
                            data.userMonj.fourToThree = $rootScope.fourToThree;
                            data.userMonj.sixteenToNine = $rootScope.sixteenToNine;
                            deferred.resolve(data);
                        });
                        return deferred.promise;
                    }],
                    powerupInit: ['userFactory', '$q', function (userFactory, $q) {
                        // Get the recipe data before the page loads.
                        var deferred = $q.defer();

                        userFactory.getUser().then(function(result) {
                            userFactory.getPowerUps(result.userMonj.userId).then(function(data) {
                                deferred.resolve(data);
                            });
                        });

                        return deferred.promise;
                    }]
                }
            })

            .state('powerUpView', {
                url: '/power-ups/:powerupId',
                views: {
                    '': {
                        controller: 'PowerUpViewController',
                        templateUrl: 'partials/powerUpView.html'
                    },
                    'header@powerUpView': {
                        templateUrl: 'partials/headerPartial.html',
                        controller: 'HeaderController'
                    },
                    'footer@powerUpView': {
                        templateUrl: 'partials/footerPartial.html'
                    },
                    'sharePopup@powerUpView': {
                        templateUrl: 'partials/popups/sharePopup.html'
                    },
                    'completed@powerUpView': {
                        templateUrl: 'partials/completedAnimationPartial.html',
                        controller: 'UgcPostController'
                    },
                    'post@powerUpView': {
                        templateUrl: 'partials/ugcPostPartial.html',
                        controller: 'UgcPostController'
                    }
                },
                resolve: {
                    userInit: ['userFactory', '$q', '$rootScope', function (userFactory, $q, $rootScope) {
                        var deferred = $q.defer();
                        userFactory.getUser().then(function(data) {
                            // Append Image Size Data to User Object
                            data.userMonj.oneToOne = $rootScope.oneToOne;
                            data.userMonj.fourToThree = $rootScope.fourToThree;
                            data.userMonj.sixteenToNine = $rootScope.sixteenToNine;
                            deferred.resolve(data);
                        });
                        return deferred.promise;
                    }],
                    powerupInit: ['$q', '$timeout', 'contentfulFactory', 'userFactory', '$stateParams', function($q, $timeout, contentfulFactory, userFactory, $stateParams) {
                        var deferred = $q.defer();

                        userFactory.getUser().then(function(user) {
                            userFactory.getPowerUp(user.userMonj.userId, $stateParams.powerupId, true).then(function(powerup) {
                                contentfulFactory.setContentfulClient(powerup.cmsSpaceId, powerup.cmsAccessToken, powerup.cmsUrl);
                                contentfulFactory.getContentfulData(powerup.cmsContentType, powerup.cmsId).then(function(data) {
                                    data = data[0];

                                    data.fields.isSaved = powerup.isSaved;
                                    data.fields.monjId = powerup.powerUpId;

                                    deferred.resolve(data);
                                });
                            });
                        });

                        return deferred.promise;
                    }],
                    colorInit: [function() {
                        return false;
                    }]
                }
            })

            .state('howTos', {
                url: '/essentials',
                views: {
                    '': {
                        controller: 'HowToController',
                        templateUrl: 'partials/howTos.html'
                    },
                    'header@howTos': {
                        templateUrl: 'partials/headerPartial.html',
                        controller: 'HeaderController'
                    },
                    'footer@howTos': {
                        templateUrl: 'partials/footerPartial.html'
                    }
                },
                resolve: {
                    userInit: ['userFactory', '$q', '$rootScope', function (userFactory, $q, $rootScope) {
                        var deferred = $q.defer();
                        userFactory.getUser().then(function(data) {
                            // Append Image Size Data to User Object
                            data.userMonj.oneToOne = $rootScope.oneToOne;
                            data.userMonj.fourToThree = $rootScope.fourToThree;
                            data.userMonj.sixteenToNine = $rootScope.sixteenToNine;
                            deferred.resolve(data);
                        });
                        return deferred.promise;
                    }],
                    termsInit: ['$q', '$timeout', 'contentfulFactory', 'userFactory', '$stateParams', function($q, $timeout, contentfulFactory, userFactory, $stateParams) {
                        var deferred = $q.defer();

                        userFactory.getDomain(1).then(function(result) {
                            //result = result[0];
                            contentfulFactory.setContentfulClient(result.cmsSpaceId, result.cmsAccessToken, result.cmsUrl);
                            contentfulFactory.getContentfulData('rFLtIpS4gKKqIWeI40aC2').then(function(data) {
                                //deferred.resolve(contentfulFactory.getCmsItem('4ZyzGUask80Q82aWQKGq4k', '4Q2MyYeKX6MUq20cG6IkoS')[0].fields);
                                deferred.resolve(data);
                            });
                        });

                        return deferred.promise;
                    }],
                    resourceLibraryInit: ['$q', '$timeout', 'contentfulFactory', 'userFactory', '$stateParams', function($q, $timeout, contentfulFactory, userFactory, $stateParams) {
                        var deferred = $q.defer();

                        userFactory.getDomain(1).then(function(result) {
                            //result = result[0];
                            contentfulFactory.setContentfulClient(result.cmsSpaceId, result.cmsAccessToken, result.cmsUrl);
                            contentfulFactory.getContentfulData('4P41akJef6GUKy8uqk6M6u').then(function(data) {
                                deferred.resolve(data.filter(function (e) { return e.fields.entryType == "Video"; }));
                            });
                        });

                        return deferred.promise;
                    }]
                }
            })

            .state('howTosVideo', {
                url: '/essentials/video/:id',
                views: {
                    '': {
                        controller: 'videosController',
                        templateUrl: 'partials/howTosVideo.html'
                    },
                    'header@howTosVideo': {
                        templateUrl: 'partials/headerPartial.html',
                        controller: 'HeaderController'
                    },
                    'footer@howTosVideo': {
                        templateUrl: 'partials/footerPartial.html'
                    },
                    'sharePopup@howTosVideo': {
                        templateUrl: 'partials/popups/sharePopup.html'
                    }
                },
                resolve: {
                    videoInit: ['$q', '$timeout', 'contentfulFactory', 'userFactory', '$stateParams', function($q, $timeout, contentfulFactory, userFactory, $stateParams) {

                        var deferred = $q.defer();

                        userFactory.getDomain(1).then(function(result) {
                            contentfulFactory.setContentfulClient(result.cmsSpaceId, result.cmsAccessToken, result.cmsUrl);
                            contentfulFactory.getContentfulData('4P41akJef6GUKy8uqk6M6u', $stateParams.id).then(function(data) {
                                deferred.resolve(data[0]);
                            });
                        });

                        return deferred.promise;
                    }],
                    userInit: ['userFactory', '$q', '$rootScope', function (userFactory, $q, $rootScope) {
                        var deferred = $q.defer();
                        userFactory.getUser().then(function(data) {
                            // Append Image Size Data to User Object
                            data.userMonj.oneToOne = $rootScope.oneToOne;
                            data.userMonj.fourToThree = $rootScope.fourToThree;
                            data.userMonj.sixteenToNine = $rootScope.sixteenToNine;
                            deferred.resolve(data);
                        });
                        return deferred.promise;
                    }]
                }
            })

            .state('guidedMealsIndex', {
                url: '/recipes',
                views: {
                    '': {
                        controller: 'GuidedMealsIndexController',
                        templateUrl: 'partials/recipes/guidedMealsIndex.html'
                    },
                    'personalPath@guidedMealsIndex': {
                        templateUrl: 'partials/personalPath.html',
                    },
                    'header@guidedMealsIndex': {
                        templateUrl: 'partials/headerPartial.html',
                        controller: 'HeaderController'
                    },
                    'footer@guidedMealsIndex': {
                        templateUrl: 'partials/footerPartial.html'
                    }
                },
                resolve: {
                    userInit: ['userFactory', '$q', '$rootScope', function (userFactory, $q, $rootScope) {
                        var deferred = $q.defer();
                        userFactory.getUser().then(function(data) {
                            // Append Image Size Data to User Object
                            data.userMonj.oneToOne = $rootScope.oneToOne;
                            data.userMonj.fourToThree = $rootScope.fourToThree;
                            data.userMonj.sixteenToNine = $rootScope.sixteenToNine;
                            deferred.resolve(data);
                        });
                        return deferred.promise;
                    }],
                    recipesInit: ['userFactory', 'contentfulFactory', '$q', async function (userFactory, contentfulFactory, $q) {
                      // Get the recipe data before the page loads.
                      var deferred = $q.defer();
                      let result = await userFactory.getUser();
                      let recipes = await userFactory.getRecipes(
                        result.userMonj.userId
                      );
                      let contentArr = [];
                      
                      await Promise.all(
                        recipes.map(async (recipe) => {
                          let contentObj = {
                            cmsSpaceId: recipe.cmsSpaceId,
                            cmsContentType: recipe.cmsContentType,
                            cmsAccessToken: recipe.cmsAccessToken,
                            cmsUrl: recipe.cmsUrl,
                          };
                          let isContentAvailable = false;
                          contentArr.map(async (conent) => {
                            if (
                              conent.cmsSpaceId == contentObj.cmsSpaceId &&
                              conent.cmsContentType ==
                                contentObj.cmsContentType &&
                              conent.cmsAccessToken ==
                                contentObj.cmsAccessToken &&
                              conent.cmsUrl == contentObj.cmsUrl
                            ) {
                              isContentAvailable = true;
                            }
                          });
                          if (!isContentAvailable) contentArr.push(contentObj);
                        })
                      );

                      await Promise.all(
                        contentArr.map(async (content) => {
                            // console.log("content",...content);
                          contentfulFactory.setContentfulClient(
                            content.cmsSpaceId,
                            content.cmsAccessToken,
                            content.cmsUrl
                          );
                          await contentfulFactory.getContentfulData(
                            content.cmsContentType
                          );
                        })
                      );

                      await Promise.all(
                        recipes.map(async (recipe) => {
                            contentfulFactory.setContentfulClient(
                              recipe.cmsSpaceId,
                              recipe.cmsAccessToken,
                              recipe.cmsUrl
                            );
                            let data =
                              await contentfulFactory.getContentfulData(
                                recipe.cmsContentType,
                                recipe.cmsId
                              );
                            data = data[0];
                            // Append required monjAPI data to cms data
                            data.fields.hasCheatSheet = recipe.hasCheatSheet;
                            data.fields.isSaved = recipe.isSaved;
                            data.fields.recipeId = recipe.recipeId;
                            data.fields.gameValue = recipe.gameValue;
                            recipe.contentFul = data;
                        })
                      );

                      deferred.resolve(recipes);
                      return deferred.promise;
                    }]
                }
            })

            .state('guidedMealsBlueprintIndex', {
                url: '/blueprints',
                views: {
                    '': {
                        controller: 'GuidedMealsIndexBlueprintController',
                        templateUrl: 'partials/recipes/guidedMealsBlueprintsIndex.html'
                    },
                    'header@guidedMealsBlueprintIndex': {
                        templateUrl: 'partials/headerPartial.html',
                        controller: 'HeaderController'
                    },
                    'footer@guidedMealsBlueprintIndex': {
                        templateUrl: 'partials/footerPartial.html'
                    }
                },
                resolve: {
                    userInit: ['userFactory', '$q', '$rootScope', function (userFactory, $q, $rootScope) {
                        var deferred = $q.defer();
                        userFactory.getUser().then(function(data) {
                            // Append Image Size Data to User Object
                            data.userMonj.oneToOne = $rootScope.oneToOne;
                            data.userMonj.fourToThree = $rootScope.fourToThree;
                            data.userMonj.sixteenToNine = $rootScope.sixteenToNine;
                            deferred.resolve(data);
                        });
                        return deferred.promise;
                    }],
                    recipesInit: ['userFactory', '$q', function (userFactory, $q) {
                        // Get the recipe data before the page loads.
                        var deferred = $q.defer();

                        userFactory.getUser().then(function(result) {
                            userFactory.getRecipes(result.userMonj.userId).then(function(data) {
                                deferred.resolve(data);
                            });
                        });

                        return deferred.promise;
                    }],
                    blueprintsInit: ['userFactory', '$q', function (userFactory, $q) {
                        // Get the recipe data before the page loads.
                        var deferred = $q.defer();

                        userFactory.getUser().then(function(result) {
                            userFactory.getBlueprints(result.userMonj.userId).then(function(data) {
                                deferred.resolve(data);
                            });
                        });

                        return deferred.promise;
                    }]
                }
            })

            .state('progressPage', {
                url: '/goals',
                views: {
                    '': {
                        controller: 'ProgressPageController', //to be replaced with ProgressPageController
                        templateUrl: 'partials/progressPage.html'
                    },
                    'header@progressPage': {
                        templateUrl: 'partials/headerPartial.html',
                        controller: 'HeaderController'
                    },
                    'progress-circle@progressPage': {
                        templateUrl: 'partials/progressCirclePartial.html'
                    },
                    'footer@progressPage': {
                        templateUrl: 'partials/footerPartial.html'
                    }
                },
                resolve: {
                    userInit: ['userFactory', '$q', '$rootScope', function (userFactory, $q, $rootScope) {
                        var deferred = $q.defer();
                        userFactory.getUser().then(function(data) {
                            // Append Image Size Data to User Object
                            data.userMonj.oneToOne = $rootScope.oneToOne;
                            data.userMonj.fourToThree = $rootScope.fourToThree;
                            data.userMonj.sixteenToNine = $rootScope.sixteenToNine;
                            deferred.resolve(data);
                        });
                        return deferred.promise;
                    }]
                }
            })

            .state('savedList', {
                url: '/saved',
                resolve: {
                    userInit: ['userFactory', '$q', '$rootScope', function (userFactory, $q, $rootScope) {
                        var deferred = $q.defer();
                        userFactory.getUser().then(function(data) {
                            // Append Image Size Data to User Object
                            data.userMonj.oneToOne = $rootScope.oneToOne;
                            data.userMonj.fourToThree = $rootScope.fourToThree;
                            data.userMonj.sixteenToNine = $rootScope.sixteenToNine;
                            deferred.resolve(data);
                        });
                        return deferred.promise;
                    }],
                    queueMeals: ['userFactory', '$q', function(userFactory, $q) {
                        var deferred = $q.defer();

                        userFactory.getUser().then(function (result) {
                            userFactory.getQueueMeals(result.userMonj.userId).then(function(result) {
                                deferred.resolve(result);
                            });
                        });

                        return deferred.promise;
                    }],
                    queueLessons: ['userFactory', '$q', function(userFactory, $q) {
                        var deferred = $q.defer();

                        userFactory.getUser().then(function (result) {
                            userFactory.getSavedLessons(result.userMonj.userId).then(function(result) {
                                deferred.resolve(result);
                            });
                        });

                        return deferred.promise;
                    }],
                    queueMissions: ['userFactory', '$q', function(userFactory, $q) {
                        var deferred = $q.defer();

                        userFactory.getUser().then(function (result) {
                            userFactory.getQueueMissions(result.userMonj.userId).then(function(result) {
                                deferred.resolve(result);
                            });
                        });

                        return deferred.promise;
                    }],
                    queuePowerups: ['userFactory', '$q', function(userFactory, $q) {
                        var deferred = $q.defer();

                        userFactory.getUser().then(function (result) {
                            userFactory.queuePowerUp(result.userMonj.userId).then(function(result) {
                                deferred.resolve(result);
                            });
                        });

                        return deferred.promise;
                    }]
                },
                views: {
                    '': {
                        controller: 'SavedList',
                        templateUrl: 'partials/savedList.html'
                    },
                    'header@savedList': {
                        templateUrl: 'partials/headerPartial.html',
                        controller: 'HeaderController'
                    },
                    'footer@savedList': {
                        templateUrl: 'partials/footerPartial.html'
                    },
                    'mission@savedList': {
                        templateUrl: 'partials/dailyMissionPartial.html',
                    },
                    'savedCoachBody@savedList': {
                        templateUrl: 'partials/savedAndCoachingBody.html'
                    },
                    'completed@savedList': {
                        templateUrl: 'partials/completedAnimationPartial.html',
                        controller: 'UgcPostController'
                    }
                }
            })

            .state('essentials', {
                url: '/essentials/:essentialsID',
                reloadOnSearch: false,
                views: {
                    '': {
                        controller: 'EssentialsController',
                        templateUrl: 'partials/essentialsSteps.html'
                    },
                    'header@essentials': {
                        templateUrl: 'partials/headerPartial.html',
                        controller: 'HeaderController'
                    },
                    'footer@essentials': {
                        templateUrl: 'partials/footerPartial.html'
                    },
                    'sharePopup@essentials': {
                        templateUrl: 'partials/popups/sharePopup.html'
                    }
                },
                resolve: {
                    userInit: ['userFactory', '$q', '$rootScope', function (userFactory, $q, $rootScope) {
                        var deferred = $q.defer();
                        userFactory.getUser().then(function(data) {
                            // Append Image Size Data to User Object
                            data.userMonj.oneToOne = $rootScope.oneToOne;
                            data.userMonj.fourToThree = $rootScope.fourToThree;
                            data.userMonj.sixteenToNine = $rootScope.sixteenToNine;
                            deferred.resolve(data);
                        });
                        return deferred.promise;
                    }],
                    essentialsInit: ['$q', '$timeout', 'contentfulFactory', 'userFactory', '$stateParams', function($q, $timeout, contentfulFactory, userFactory, $stateParams) {
                        var deferred = $q.defer();
                        userFactory.getUser().then(function (result) {
                            userFactory.getEssentials(result.userMonj.userId).then(function (result) {
                                var essentials = result.filter(function (e) {
                                    return e.moduleId == $stateParams.essentialsID;
                                })[0];
                                if (essentials) {
                                    contentfulFactory.setContentfulClient(result.cmsSpaceId, result.cmsAccessToken, result.cmsUrl);
                                    contentfulFactory.getContentfulData('rFLtIpS4gKKqIWeI40aC2').then(function(data) {
                                        deferred.resolve(data);
                                    });
                                } else {
                                    deferred.reject();
                                }
                            });
                        });

                        return deferred.promise;
                    }]
                }
            })

            .state('inspiration', {
                url: '/inspiration',
                reloadOnSearch: false,
                views: {
                    '': {
                        controller: 'InspirationController',
                        templateUrl: 'partials/inspirationPartial.html'
                    },
                    'header@inspiration': {
                        templateUrl: 'partials/headerPartial.html',
                        controller: 'HeaderController'
                    },
                    'footer@inspiration': {
                        templateUrl: 'partials/footerPartial.html'
                    },
                    'post@inspiration': {
                        templateUrl: 'partials/ugcPostPartial.html',
                        controller: 'UgcPostController'
                    }
                },
                resolve: {
                    userInit: ['userFactory', '$q', '$rootScope', function (userFactory, $q, $rootScope) {
                        var deferred = $q.defer();
                        userFactory.getUser().then(function(data) {
                            // Append Image Size Data to User Object
                            data.userMonj.oneToOne = $rootScope.oneToOne;
                            data.userMonj.fourToThree = $rootScope.fourToThree;
                            data.userMonj.sixteenToNine = $rootScope.sixteenToNine;
                            deferred.resolve(data);
                        });
                        return deferred.promise;
                    }]
                }
            })

            /********************************************
             *
             * WIDGET STATES
             *
             *******************************************/
            .state('widget404', {
                url: '/widget/page-not-found',
                templateUrl: 'partials/widgets/widget404.html',
                controller: 'MiscPagesController'
            })
            .state('dailyMissionWidget', {
                url: '/widget/daily-mission',
                resolve: {
                    userInit: ['userFactory', '$q', '$rootScope', function (userFactory, $q, $rootScope) {
                        var deferred = $q.defer();
                        userFactory.getUser().then(function(data) {
                            // Append Image Size Data to User Object
                            data.userMonj.oneToOne = $rootScope.oneToOne;
                            data.userMonj.fourToThree = $rootScope.fourToThree;
                            data.userMonj.sixteenToNine = $rootScope.sixteenToNine;
                            deferred.resolve(data);
                        });
                        return deferred.promise;
                    }]
                },
                views: {
                    '': {
                        templateUrl: 'partials/widgets/dailyMissionWidget.html',
                        controller: 'widgetController'
                    },
                    'mission@dailyMissionWidget': {
                        templateUrl: 'partials/dailyMissionPartial.html'
                    },
                    'quiz@dailyMissionWidget': {
                        templateUrl: 'partials/quizPartial.html'
                    },
                    'completed@dailyMissionWidget': {
                        templateUrl: 'partials/completedAnimationPartial.html',
                        controller: 'UgcPostController'
                    }
                }
            })
            .state('essentialsWidget', {
                url: '/widget/essentials/:essentialsID',
                reloadOnSearch: false,
                views: {
                    '': {
                        controller: 'EssentialsController',
                        templateUrl: 'partials/widgets/essentialsStepsWidget.html'
                    },
                    'completed@essentialsWidget': {
                        templateUrl: 'partials/completedAnimationPartial.html',
                        controller: 'UgcPostController'
                    }
                },
                resolve: {
                    userInit: ['userFactory', '$q', '$rootScope', function (userFactory, $q, $rootScope) {
                        var deferred = $q.defer();
                        userFactory.getUser().then(function(data) {
                            // Append Image Size Data to User Object
                            data.userMonj.oneToOne = $rootScope.oneToOne;
                            data.userMonj.fourToThree = $rootScope.fourToThree;
                            data.userMonj.sixteenToNine = $rootScope.sixteenToNine;
                            deferred.resolve(data);
                        });
                        return deferred.promise;
                    }],
                    essentialsInit: ['$q', '$timeout', 'contentfulFactory', 'userFactory', '$stateParams', function($q, $timeout, contentfulFactory, userFactory, $stateParams) {
                        var deferred = $q.defer();
                        userFactory.getUser().then(function (result) {
                            userFactory.getEssentials(result.userMonj.userId).then(function (result) {
                                var essentials = result.filter(function (e) {
                                    return e.moduleId == $stateParams.essentialsID;
                                })[0];
                                if (essentials) {
                                    contentfulFactory.setContentfulClient(result.cmsSpaceId, result.cmsAccessToken, result.cmsUrl);
                                    contentfulFactory.getContentfulData('rFLtIpS4gKKqIWeI40aC2').then(function(data) {
                                        deferred.resolve(data);
                                    });
                                } else {
                                    deferred.reject();
                                }
                            });
                        });

                        return deferred.promise;
                    }]
                }
            })
            .state('powerUpViewWidget', {
                url: '/widget/power-ups/:powerupId',
                views: {
                    '': {
                        controller: 'PowerUpViewController',
                        templateUrl: 'partials/powerUpView.html'
                        //templateUrl: 'partials/widgets/powerUpViewWidget.html'
                    },
                    'powerupView@powerUpViewWidget': {
                        templateUrl: 'partials/powerUpView.html'
                    },
                    'completed@powerUpViewWidget': {
                        templateUrl: 'partials/completedAnimationPartial.html',
                        controller: 'UgcPostController'
                    }
                },
                resolve: {
                    powerupInit: ['$q', '$timeout', 'contentfulFactory', 'userFactory', '$stateParams', function($q, $timeout, contentfulFactory, userFactory, $stateParams) {
                        var deferred = $q.defer();

                        userFactory.getUser().then(function(user) {
                            userFactory.getPowerUp(user.userMonj.userId, $stateParams.powerupId, true).then(function(powerup) {
                                contentfulFactory.setContentfulClient(powerup.cmsSpaceId, powerup.cmsAccessToken, powerup.cmsUrl);
                                contentfulFactory.getContentfulData(powerup.cmsContentType, powerup.cmsId).then(function(data) {
                                    data = data[0];

                                    data.fields.isSaved = powerup.isSaved;
                                    data.fields.monjId = powerup.powerUpId;

                                    deferred.resolve(data);
                                });
                            });
                        });

                        return deferred.promise;
                    }],
                    userInit: ['userFactory', '$q', '$rootScope', function (userFactory, $q, $rootScope) {
                        var deferred = $q.defer();
                        userFactory.getUser().then(function(data) {
                            // Append Image Size Data to User Object
                            data.userMonj.oneToOne = $rootScope.oneToOne;
                            data.userMonj.fourToThree = $rootScope.fourToThree;
                            data.userMonj.sixteenToNine = $rootScope.sixteenToNine;
                            deferred.resolve(data);
                        });
                        return deferred.promise;
                    }],
                    colorInit: ['userFactory', '$q', 'dataTransferFactory', function (userFactory, $q, dataTransferFactory) {
                        var deferred = $q.defer();
                        var partnerId = dataTransferFactory.get('applicationPartnerId');
                        if(partnerId) {
                            userFactory.getWidgetColors(partnerId).then(function (data) {
                                deferred.resolve(data);
                            }, function () {
                                deferred.resolve();
                            });
                        } else {
                            deferred.resolve();
                        }
                        return deferred.promise;
                    }]
                }
            })
            .state('expandedRecipeWidget', {
                url: '/widget/recipes/:recipeId',
                resolve: {
                    userInit: ['userFactory', '$q', '$rootScope', function (userFactory, $q, $rootScope) {
                        var deferred = $q.defer();
                        userFactory.getUser().then(function(data) {
                            // Append Image Size Data to User Object
                            data.userMonj.oneToOne = $rootScope.oneToOne;
                            data.userMonj.fourToThree = $rootScope.fourToThree;
                            data.userMonj.sixteenToNine = $rootScope.sixteenToNine;
                            deferred.resolve(data);
                        });
                        return deferred.promise;
                    }],
                    factoryInit: ['$q', '$timeout', 'contentfulFactory', 'userFactory', '$stateParams', function($q, $timeout, contentfulFactory, userFactory, $stateParams) {
                        var deferred = $q.defer();

                        userFactory.getUser().then(function(user) {
                            userFactory.getRecipe(user.userMonj.userId, $stateParams.recipeId).then(function(recipe) {
                                contentfulFactory.setContentfulClient(recipe.cmsSpaceId, recipe.cmsAccessToken, recipe.cmsUrl);
                                contentfulFactory.getContentfulData(recipe.cmsContentType, recipe.cmsId).then(function(data) {
                                    data = data[0];

                                    // Append required monjAPI data to cms data
                                    data.fields.hasCheatSheet = recipe.hasCheatSheet;
                                    data.fields.isSaved = recipe.isSaved;
                                    data.fields.recipeId = recipe.recipeId;
                                    data.fields.gameValue = recipe.gameValue;
                                    deferred.resolve(data);
                                });
                            });
                        });

                        return deferred.promise;
                    }],
                    colorInit: ['userFactory', '$q', 'dataTransferFactory', function (userFactory, $q, dataTransferFactory) {
                        var deferred = $q.defer();
                        var partnerId = dataTransferFactory.get('applicationPartnerId');
                        if(partnerId) {
                            userFactory.getWidgetColors(partnerId).then(function (data) {
                                deferred.resolve(data);
                            }, function () {
                                deferred.resolve();
                            });
                        } else {
                            deferred.resolve();
                        }
                        return deferred.promise;
                    }]
                },
                views: {
                    '': {
                        templateUrl: 'partials/recipes/expandedRecipe.html',
                        controller: 'ExpandedRecipeController'
                    },

                    'ingredient@expandedRecipeWidget': {
                        templateUrl: 'partials/recipes/expandedRecipeIngredientPartial.html'
                    },

                    'flavorFramework@expandedRecipeWidget': {
                        templateUrl: 'partials/recipes/expandedRecipeFlavorFrameworkPartial.html'
                    },

//                    'question@expandedRecipeWidget': {
//                        templateUrl: 'partials/expandedRecipeQuestionPartial.html'
//                    },
                    'sharePopup@expandedRecipeWidget': {
                        templateUrl: 'partials/popups/sharePopup.html'
                    }
                }
            })
            .state('guidedRecipeWidget', {
                url: '/widget/guided-recipe/:recipeId',
                resolve: {
                    userInit: ['userFactory', '$q', '$rootScope', function (userFactory, $q, $rootScope) {
                        var deferred = $q.defer();
                        userFactory.getUser().then(function(data) {
                            // Append Image Size Data to User Object
                            data.userMonj.oneToOne = $rootScope.oneToOne;
                            data.userMonj.fourToThree = $rootScope.fourToThree;
                            data.userMonj.sixteenToNine = $rootScope.sixteenToNine;
                            deferred.resolve(data);
                        });
                        return deferred.promise;
                    }],
                    factoryInit: ['$q', '$timeout', 'contentfulFactory', 'userFactory', '$stateParams', function($q, $timeout, contentfulFactory, userFactory, $stateParams) {
                        var deferred = $q.defer();

                        userFactory.getUser().then(function(user) {
                            userFactory.getRecipe(user.userMonj.userId, $stateParams.recipeId, true).then(function(recipe) {
                                contentfulFactory.setContentfulClient(recipe.cmsSpaceId, recipe.cmsAccessToken, recipe.cmsUrl);
                                contentfulFactory.getContentfulData(recipe.cmsContentType, recipe.cmsId).then(function(data) {
                                    data = data[0];
                                    // Append required monjAPI data to cms data
                                    data.fields.recipeId = recipe.recipeId;
                                    deferred.resolve(data);
                                });
                            });
                        });
                        return deferred.promise;
                    }],
                    dictionaryInit: ['$q', '$timeout', 'contentfulFactory', 'userFactory', '$stateParams', function($q, $timeout, contentfulFactory, userFactory, $stateParams) {
                        var deferred = $q.defer();

                        userFactory.getUser().then(function(user) {
                            userFactory.getRecipe(user.userMonj.userId, $stateParams.recipeId, true).then(function(recipe) {
                                contentfulFactory.setContentfulClient(recipe.cmsSpaceId, recipe.cmsAccessToken, recipe.cmsUrl);
                                contentfulFactory.getContentfulData('rFLtIpS4gKKqIWeI40aC2').then(function(data) {

                                    var terms = [];
                                    for(var x = 0; x < data.length; x++) {
                                        terms[x] = {
                                            'entry': data[x].fields.entry,
                                            'value': encodeURIComponent(data[x].fields.entry),
                                            'definition': data[x].fields.definition,
                                            'term': data[x].fields.entry
                                        };
                                    }

                                    deferred.resolve(terms);
                                });
                            });
                        });

                        return deferred.promise;
                    }],
                    colorInit: ['userFactory', '$q', 'dataTransferFactory', function (userFactory, $q, dataTransferFactory) {
                        var deferred = $q.defer();
                        var partnerId = dataTransferFactory.get('applicationPartnerId');
                        if(partnerId) {
                            userFactory.getWidgetColors(partnerId).then(function (data) {
                                deferred.resolve(data);
                            }, function () {
                                deferred.resolve();
                            });
                        } else {
                            deferred.resolve();
                        }
                        return deferred.promise;
                    }]
                },
                views: {
                    '': {
                        templateUrl: 'partials/recipes/guidedRecipe.html',
                        controller: 'GuidedRecipeController'
                    },

                    'gr-1@guidedRecipeWidget': {
                        template: function () {
                            var stringData = $.ajax({
                                url: "partials/recipes/guidedRecipePartial.html",
                                async: false
                            }).responseText;

                            return stringData.replace(/currStep/g, 'currStep[0]')
                                .replace(/delegate-handle="body-half"/g, 'delegate-handle="body-half-1"')
                                .replace(/image-slide-container/g, 'image-slide-container-0')
                                .replace(/my-auto-scroll-gr/g, 'my-auto-scroll-gr="0"');
                        },
                    },

                    'gr-2@guidedRecipeWidget': {
                        template: function () {
                            var stringData = $.ajax({
                                url: "partials/recipes/guidedRecipePartial.html",
                                async: false
                            }).responseText;

                            return stringData.replace(/currStep/g, 'currStep[1]')
                                .replace(/delegate-handle="body-half"/g, 'delegate-handle="body-half-2"')
                                .replace(/image-slide-container/g, 'image-slide-container-1')
                                .replace(/my-auto-scroll-gr/g, 'my-auto-scroll-gr="1"');
                        },
                    },

                    'gr-3@guidedRecipeWidget': {
                        template: function () {
                            var stringData = $.ajax({
                                url: "partials/recipes/guidedRecipePartial.html",
                                async: false
                            }).responseText;

                            return stringData.replace(/currStep/g, 'currStep[2]')
                                .replace(/delegate-handle="body-half"/g, 'delegate-handle="body-half-3"')
                                .replace(/image-slide-container/g, 'image-slide-container-2')
                                .replace(/my-auto-scroll-gr/g, 'my-auto-scroll-gr="2"');
                        },
                    },

                    'gr-menu@guidedRecipeWidget': {
                        templateUrl: 'partials/recipes/guidedRecipeMenuPartial.html',
                    },

                    'ingr-equip@guidedRecipeWidget': {
                        templateUrl: 'partials/recipes/expandedRecipeIngredientPartial.html'
                    },

                    'gr-comment@guidedRecipeWidget': {
                        templateUrl: 'partials/recipes/guidedRecipeCommentPartial.html',
                        controller: 'RibbonController'
                    },

                    'ribbon@guidedRecipeWidget': {
                        templateUrl: 'partials/recipes/guidedRecipeRibbon.html',
                        controller: 'RibbonController'
                    },
                    'completed@guidedRecipeWidget': {
                        templateUrl: 'partials/completedAnimationPartial.html',
                        controller: 'UgcPostController'
                    }
                }
            })
            .state('expandedLessonWidget', {
                url: '/widget/lessons/:id',
                resolve: {
                    userInit: ['userFactory', '$q', '$rootScope', function (userFactory, $q, $rootScope) {
                        var deferred = $q.defer();
                        userFactory.getUser().then(function(data) {
                            // Append Image Size Data to User Object
                            data.userMonj.oneToOne = $rootScope.oneToOne;
                            data.userMonj.fourToThree = $rootScope.fourToThree;
                            data.userMonj.sixteenToNine = $rootScope.sixteenToNine;
                            deferred.resolve(data);
                        });
                        return deferred.promise;
                    }],
                    factoryInit: ['$q', '$timeout', 'contentfulFactory', 'userFactory', '$stateParams', function($q, $timeout, contentfulFactory, userFactory, $stateParams) {
                        var deferred = $q.defer();

                        userFactory.getUser().then(function(user) {
                            userFactory.getLesson(user.userMonj.userId, $stateParams.id).then(function(lesson) {
                                contentfulFactory.setContentfulClient(lesson.cmsSpaceId, lesson.cmsAccessToken, lesson.cmsUrl);
                                contentfulFactory.getContentfulData(lesson.cmsContentType, lesson.cmsId).then(function(data) {
                                    data = data[0];

                                    // Append required monjAPI data to cms data
                                    data.fields.lessonId = lesson.lessonId;
                                    data.fields.name = lesson.name;
                                    data.fields.thumbUrl = lesson.thumbUrl;
                                    data.fields.gameValue = lesson.gameValue;
                                    data.fields.isSaved = lesson.isSaved;
                                    data.fields.pageCount = lesson.pageCount;
                                    data.fields.resumePage = lesson.resumePage;

                                    deferred.resolve(data);
                                });
                            });
                        });

                        return deferred.promise;
                    }],
                    colorInit: ['userFactory', '$q', 'dataTransferFactory', function (userFactory, $q, dataTransferFactory) {
                        var deferred = $q.defer();
                        var partnerId = dataTransferFactory.get('applicationPartnerId');
                        if(partnerId) {
                            userFactory.getWidgetColors(partnerId).then(function (data) {
                                deferred.resolve(data);
                            }, function () {
                                deferred.resolve();
                            });
                        } else {
                            deferred.resolve();
                        }
                        return deferred.promise;
                    }]
                },
                views: {
                    '': {
                        templateUrl: 'partials/lessons/expandedLesson.html',
                        controller: 'ExpandedLessonController'
                    },
                    'completed@expandedLessonWidget': {
                        templateUrl: 'partials/completedAnimationPartial.html',
                        controller: 'UgcPostController'
                    }
                }
            })
            .state('guidedLessonWidget', {
                url: '/widget/guided-lesson/:id',
                resolve: {
                    factoryInit: ['$q', '$timeout', 'contentfulFactory', 'userFactory', '$stateParams', function($q, $timeout, contentfulFactory, userFactory, $stateParams) {
                        var deferred = $q.defer();

                        userFactory.getUser().then(function(user) {
                            userFactory.getLesson(user.userMonj.userId, $stateParams.id, true).then(function(lesson) {
                                contentfulFactory.setContentfulClient(lesson.cmsSpaceId, lesson.cmsAccessToken, lesson.cmsUrl);
                                contentfulFactory.getContentfulData(lesson.cmsContentType, lesson.cmsId).then(function(data) {
                                    data = data[0];

                                    // Append required monjAPI data to cms data
                                    data.fields.previousAnswers = lesson.previousAnswers;
                                    data.fields.isSaved = lesson.isSaved;

                                    deferred.resolve(data);
                                });
                            });
                        });

                        return deferred.promise;
                    }],
                    userInit: ['userFactory', '$q', '$rootScope', function (userFactory, $q, $rootScope) {
                        var deferred = $q.defer();
                        userFactory.getUser().then(function(data) {
                            // Append Image Size Data to User Object
                            data.userMonj.oneToOne = $rootScope.oneToOne;
                            data.userMonj.fourToThree = $rootScope.fourToThree;
                            data.userMonj.sixteenToNine = $rootScope.sixteenToNine;
                            deferred.resolve(data);
                        });
                        return deferred.promise;
                    }],
                    colorInit: ['userFactory', '$q', 'dataTransferFactory', function (userFactory, $q, dataTransferFactory) {
                        var deferred = $q.defer();
                        var partnerId = dataTransferFactory.get('applicationPartnerId');
                        if(partnerId) {
                            userFactory.getWidgetColors(partnerId).then(function (data) {
                                deferred.resolve(data);
                            }, function () {
                                deferred.resolve();
                            });
                        } else {
                            deferred.resolve();
                        }
                        return deferred.promise;
                    }]
                },
                views: {
                    '': {
                        templateUrl: 'partials/lessons/lesson.html',
                        controller: 'LessonController'
                    },
                    'menu@guidedLessonWidget': {
                        templateUrl: 'partials/lessons/lessonsMenu.html',
                    },
                    'completed@guidedLessonWidget': {
                        templateUrl: 'partials/completedAnimationPartial.html',
                        controller: 'UgcPostController'
                    }
                }
            });
    }]);

    app.filter('reverse', function() {
      return function(items) {
        return items.reverse();
      }
    });

}());
