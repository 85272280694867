(function () {

    var userFactory = function ($timeout, ajaxWithCacheFactory, dataTransferFactory, appSettings, $q, $location, $rootScope, $translate, $state, awardsFactory) {
        var user = {};
        var factory = {};

        var ref;
        var auth;

        var monjApiKey = appSettings.monjApiKey;
        var monjURL = appSettings.monjURL;
        var monjUploadURL = appSettings.monjUploadURL;

        var token = $location.search().access_token;

        var isWidget = false;
        if($location.path().includes('/widget/')) {
            isWidget = true;
        }

        /*var sparkleId = appSettings.sparkleId;
        var sparkleSecret = appSettings.sparkleSecret;
        var sparkleUrl = "https://api.getsparkle.io/oauth/token";*/

        var stdTimeout = 10000;
        var initTimeout = 30000;

        if(appSettings.pageTitle) {
            var title = document.title;
            title = title.concat(appSettings.pageTitle);
            document.title = title;
        }

        /************************************
         * Init
         * Initialize firebase and test users authentication
         */

        factory.init = function() {
            // Initialize Firebase
            var config = {
                apiKey: appSettings.apiKey,
                authDomain: appSettings.authDomain,
                databaseURL: appSettings.databaseURL,
                projectId: appSettings.projectId,
                storageBucket: appSettings.storageBucket,
                messagingSenderId: appSettings.messagingSenderId
            };
            ref = firebase.initializeApp(config);

            //var ref = new Firebase(appSettings.firebaseURL);
            //var auth = $firebaseAuth(ref);
            auth = ref.auth();

            var authDataCallback = function (authData) { //what firebase returns for us to convert into our user object

                // @todo: Test SSO functionality with deprecated authData.auth.isSSORequest check
                if( authData && authData.isSSORequest )
                {
                    user.authData = authData;
                    user.email = authData.auth.email;
                    user.name = authData.auth.name;
                    user.authData.uid = authData.auth.firebaseUserId;
                    user.authData.monjUId = authData.auth.monjUserId;
                    authData.uid = user.authData.uid;
                }
                else
                {
                    user.authData = authData;
                }

                // Reroute the user to the proper page once authentication has been confirmed.
                if(authData) {
                    $location.path(dataTransferFactory.get('previousRoute'));
                    // Production Bug Fix -- JBB
                    $rootScope.$digest();
                }
                // If authentication fails the user remains on the public page.
            };

            // watch authData changes and update user object
            //ref.onAuth(authDataCallback);
            auth.onAuthStateChanged(authDataCallback);

            //
            // Check to see if a access_token was passed at the behest of a SSO call
            //
            if( token ) {
                factory.ssoLogin(token);
            } else {
                if(user.authData) {
                    if (user.authData.token && user.authData.monjUId) {
                        // console.log('Users token not found in url, but found in user.authData object:', user.authData.token, 'Calling factory.ssoLogin(user.authData.token)...');
                        // If the user still has a valid authToken, attempt to log them in as an SSO user.
                        factory.ssoLogin(user.authData.token);
                    }
                }
            }
        };

        factory.ssoLogin = function(access_token){
            // Loggout users first
            user = { authData: null };
            auth.signInWithCustomToken(access_token).then( function(authData){
                var payloadObj = KJUR.jws.JWS.readSafeJSONString(b64utoutf8(token.split(".")[1]));
                user.userMonjPromise = factory.getUserByUserId(payloadObj.claims.monjUserId).then(function (result) {
                    isUserExpired(result);
                    user.userMonj = result;
                    delete user.userMonjPromise;
                    $location.search('');
                    if($location.path() != $state.get('publicExperience').url) {
                        $location.path($location.path());
                    } else {
                        $location.path($state.get('authExperience').url);
                    }
                }, function (error) {
                    Raygun.send(error);
                    factory.logout();
                });
            }).catch(function(error) {
                console.error("Authentication failed:", error);
            });
        };

        factory.getUserData = function() {
            return user;
        };

        factory.getUser = function () {
            var deferred = $q.defer();

            if(!user.userMonj) {
                if(!user.userMonjPromise) {
                    if (user.authData) {
                        user.userMonjPromise = factory.getUserByPrimaryEmail(user.authData.email);

                        user.userMonjPromise.then(function (result) {
                            if (result) {
                                $rootScope.userId = result.userId;
                                user.userMonj = result;

                                $rootScope.lang = result.locale;
                                $translate.use($rootScope.lang);

                                if (isLocalStorage()) {
                                    localStorage.setItem("defaultLanguage", result.locale);
                                } else {
                                    setCookie('defaultLanguage', result.locale, 7);
                                }

                                // Delete promise (allows the user object to be fetched on next call)
                                delete user.userMonjPromise;

                                deferred.resolve(user);
                            } else {
                                deferred.reject('no_email');
                            }

                        }, function (error) {
                            Raygun.send(error);
                            factory.logout();

                            deferred.reject(error);
                        });
                    }/* else {
                        console.log('user.authData does not yet exist.', user.authData);
                    }*/
                } else {
                    user.userMonjPromise.then(function() {
                        deferred.resolve(user);
                    });
                    // Do nothing, the user data is already being fetched.
                }
            } else {
                deferred.resolve(user);
            }

            return deferred.promise;
        };

        function isLocalStorage(){
            try {
                localStorage.setItem('localStorageTest', 'test');
                localStorage.removeItem('localStorageTest');
                return true;
            } catch(e) {
                return false;
            }
        }

        // Sets a cookie
        function setCookie(cname, cvalue, exdays) {
            var d = new Date();
            d.setTime(d.getTime() + (exdays*24*60*60*1000));
            var expires = "expires="+ d.toUTCString();
            document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
        }

        /*factory.updateUser = function(key, value) {
         user.userMonj[key] = value;
         }*/

        factory.validateUserAuth = function () {
            if (!user.authData) {
                $location.path($state.get('publicExperience').url);
                return false;
            }
            return true;
        };

        factory.updateMonj = function () {
            if (user.authData) {
                if(user.authData.password) {
                    user.userMonj = factory.getUserByPrimaryEmail(user.authData.password.email, true);
                }
                /*if(user.userMonj) {
                 factory.getUserByUserId(user.userMonj.userId).then(function(result) {
                 user.userMonj = result;
                 });
                 } else {
                 user.userMonj = factory.getUserByPrimaryEmail(user.authData.password.email, true);
                 }*/
            }
            return user;
        };

        factory.signUp = function (inviteCode, activationCode, email, password, workEmail, company, name) {
            var deferred = $q.defer();

            //verify that the user doesn't exists
            var userPromise = factory.checkUserByPrimaryEmail(email);

            userPromise.then(function (result) {
                if (result.found) {
                    deferred.reject({ message: "found" });
                } else {
                    factory.contCreateUser(inviteCode, activationCode, email, password, workEmail, company, name).then(function() {
                        deferred.resolve();
                    }, function (error) {
                        deferred.reject(error);
                    });
                }
            }, function (error) {
                Raygun.send(error);
                // checkUserByPrimaryEmail threw an error
                deferred.reject({ message: "unknown" });
            });

            return deferred.promise;
        };

        // Create firebase user from conversion step
        factory.contCreateUser = function(inviteCode, activationCode, email, password, workEmail, company, name) {
            var deferred = $q.defer();
            user.email = email;

            //create user account in firebase
            /*var promise = auth.$createUser({
                email: email,
                password: password
            });

            promise.then(function (authData) {
                //save user account to Monj database
                var uid = authData.uid;*/

                // Create the user account.
                factory.createUser(password, activationCode, inviteCode, email, name, workEmail, $rootScope.lang).then(function() {
                    deferred.resolve(factory.login(email, password));
                }, function (error) {
                    Raygun.send(error.message);
                    // delete the newely created account from firebase
                    /*factory.deleteUser(email, password, uid).then(function () { }, function (error) {
                        // couldn't delete the account from firebase
                        // trying once again
                        Raygun.send(error);
                        factory.deleteUser(email, password, uid);
                    });*/

                    deferred.reject(error);
                });
            /*}, function (error) {
                Raygun.send(error);
                if (error.message.includes("email address is already in use")) {
                    deferred.reject({ message: "in use" });
                } else {
                    deferred.reject({ message: "unknown" });
                }
            });*/

            return deferred.promise;
        };


        factory.login = function (email, password) {

            var deferred = $q.defer();

            user.email = email;

            var promise = auth.signInWithEmailAndPassword(email, password);

            promise.then(function (result) {
                user.userMonjPromise = factory.getUserByPrimaryEmail(email);
                //user.userMonjPromise = factory.getUserByUserId(result.authData.uid);

                user.userMonjPromise.then(function (result) {
                    isUserExpired(result);
                    // Counteract an angular-bug where the page location changes, but the view remains the same
                    if(result.expired) {
                        if(isLocalStorage()) {
                            localStorage.setItem("expired", true);
                        } else {
                            document.cookie = "expired=true; expires=Thu, 18 Dec 2013 12:00:00 UTC";
                        }
                        location.reload();
                    }
                    user.userMonj = result;
                    delete user.userMonjPromise;
                    deferred.resolve(result);
                }, function (error) {
                    deferred.reject('noAccount');
                    Raygun.send(error);
                    factory.logout();
                });

                //user.userMonjCompletes = factory.getCompletions(user.authData.uid);
            }, function (error) {
                deferred.reject('incorrectInfo');
                Raygun.send(error);
                factory.logout();
            });

            return deferred.promise;
        };

        // Gets a cookie based on its name - thank you stack-overflow ;)
        /*function getCookie(name) {
            var value = "; " + document.cookie;
            var parts = value.split("; " + name + "=");
            if (parts.length == 2) return parts.pop().split(";").shift();
        }

        function setCookie(cname, cvalue, exdays) {
            var d = new Date();
            d.setTime(d.getTime() + (exdays*24*60*60*1000));
            var expires = "expires="+ d.toUTCString();
            document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
        }*/



        factory.logout = function () {
            //ref.unauth(); -- Deprecated
            if(user.authData) {
                auth.signOut().then(function () {
                    Raygun.setUser('public');
                    user.authData = null;
                    dataTransferFactory.remove('userData');
                    location.reload();
                });
            } // Otherwise the user is already logged out, no point calling the API.
        };

        /**
         * Removed delete user functionality - JBB
         * @todo: Confirm that we are doing this server-side, not in-app
         */

        /*factory.deleteUser = function (email, password, id) {
            //remove user from Firebase
            var promise =
                auth.delete({
                    email: email,
                    password: password
                });

            promise.then(function () {
                // remove user from Monj database
                if (id) {
                    factory.deleteUserByExternalId(id);
                }
                else {
                    var monjDataPromise = factory.getUserByUserId(user.userMonj.userId);

                    monjDataPromise.then(function (result) {
                        var eid = result.externalUserId;
                        if (eid)
                            factory.deleteUserByExternalId(eid);
                    });
                }
            });

            return promise;
        };*/

        /***********************************
         * @TODO: Reset and Change password workflows have changed and must be updated.
         *
         * @param email
         * @returns {*}
         */

        factory.resetPassword = function (email) {
            /*var promise =
                auth.$resetPassword({
                    email: email
                });*/
            var actionCodeSettings = {
                url: window.location.href,
                /*iOS: {
                    bundleId: 'com.example.ios'
                },
                android: {
                    packageName: 'com.example.android'
                },
                handleCodeInApp: true*/
            };
            var promise = auth.sendPasswordResetEmail(email, actionCodeSettings);

            return promise;
        };

        factory.changePassword = function (newPass) {
            /*var promise =
                auth.$changePassword({
                    email: email,
                    oldPassword: oldPass,
                    newPassword: newPass
                });*/
            var promise = auth.currentUser.updatePassword(newPass);

            return promise;
        };

        factory.changeEmail = function (externalUserId, oldPrimaryEmail, newPrimaryEmail, password) {
            var deferred = $q.defer();

            // Login to reauthenticate
            factory.login(oldPrimaryEmail, password).then(function(authData) {
                var newExternalUserId = authData.externalUserId;

                // change the externalUserId and the primary email in the MonjDB
                factory.updateExternalAccount(user.userMonj.userId, newExternalUserId, newPrimaryEmail)
                    .then(function (result) {
                        auth.currentUser.updateEmail(newPrimaryEmail).then(function () {
                            user.authData.email = newPrimaryEmail;
                            console.log(user);
                            deferred.resolve(user.authData);
                        });
                    }, function (error) {
                        Raygun.send(error);
                    });
            });


            // try to login with the email and password to check if it's valid
            /*var passCheckPromise =
                auth.$authWithPassword({
                    email: oldPrimaryEmail,
                    password: password
                });

            passCheckPromise.then(function (result) {
                //create user account in firebase
                var promise = auth.$createUser({
                    email: newPrimaryEmail,
                    password: password
                });

                promise.then(function (authData) {
                    var newExternalUserId = authData.uid;

                    // change the externalUserId and the primary email in the MonjDB
                    factory.updateExternalAccount(user.userMonj.userId, newExternalUserId, newPrimaryEmail)
                        .then(function (result) {
                            factory.logout();

                            // delete the old account from firebase
                            var promiseInner =
                                auth.$removeUser({
                                    email: oldPrimaryEmail,
                                    password: password
                                });

                            promiseInner.then(function () { }, function (error) {
                                Raygun.send(error);
                            });

                            deferred.resolve(factory.login(newPrimaryEmail, password));
                        }, function (error) {
                            Raygun.send(error);

                            // delete the new account from firebase
                            var promiseInner =
                                auth.$removeUser({
                                    email: newPrimaryEmail,
                                    password: password
                                });

                            promiseInner.then(function () { }, function (error) {
                                Raygun.send(error);
                            });

                            deferred.reject('delete');
                        });
                }, function (error) {
                    Raygun.send(error);

                    deferred.reject('create');
                });
            }, function (error) {
                Raygun.send(error);
                deferred.reject('password');
            });*/

            return deferred.promise;
        };


        /***********************************************
         MONJ API METHODS
         ***********************************************/

        // Check Company Domain (GET https://apidev.monj.com/v1/companies/checkdomain)
        factory.checkCompanyDomain = function (company) {
            var callSettings = {
                url: monjURL + "/companies/checkdomain",
                type: "GET",
                data: {
                    "domain": company,
                    "apiKey": monjApiKey,
                }
            };
            return ajaxWithCacheFactory.ajax(callSettings, 1);
        };

        // get User by ExternalId (GET https://apidev.monj.com/v1/users/byExternalId)
        factory.getUserByExternalId = function (externalUserId, synch) {
            var callSettings = {};
            if (synch) {
                callSettings = {
                    url: monjURL + "/users/byExternalId",
                    async: false,
                    type: "GET",
                    data: {
                        "externalUserId": externalUserId,
                        "apiKey": monjApiKey
                    }
                };
            } else {
                callSettings = {
                    url: monjURL + "/users/byExternalId",
                    type: "GET",
                    data: {
                        "externalUserId": externalUserId,
                        "apiKey": monjApiKey
                    }
                };
            }
            return ajaxWithCacheFactory.ajax(callSettings, 1);
        };

        function isUserExpired(data) {
            // Force expired == true for testing purposes
            //data.expired = true;
            if(data.expired) {
                // Log the user out and immediately redirect them to public experience with a dataTransfer Popup flag
                // Once on the Public Experience page, they will see an "Account Expired" popup

                // If widget, pass widget flag


                setCookie('expired', 'true', 7);
                localStorage.setItem("expired", 'true');
                dataTransferFactory.set('userExpired', true);
                if(isWidget) {
                    dataTransferFactory.set('isExpiredWidget', true);
                }

                factory.logout();
                $location.path($state.get('publicExperience').url);
            }
        }

        // get User by PrimaryEmail (GET https://apidev.monj.com/v1/users/byPrimaryEmail)
        factory.getUserByPrimaryEmail = function (email, synch) {
            //if(email) {
                var callSettings = {};
                if (synch) {
                    callSettings = {
                        url: monjURL + "/users/byPrimaryEmail",
                        async: false,
                        type: "GET",
                        data: {
                            "email": email,
                            "apiKey": monjApiKey,
                        }
                    };
                } else {
                    callSettings = {
                        url: monjURL + "/users/byPrimaryEmail",
                        type: "GET",
                        data: {
                            "email": email,
                            "apiKey": monjApiKey,
                        }
                    };
                }

                var promise = ajaxWithCacheFactory.ajax(callSettings, 1);

                promise.then(function (data) {
                    // Check if the users account has expired, and if so, log them out.
                    isUserExpired(data);
                });

                return promise;
            /*} else {
                return $timeout(function() { });
            }*/
            //return ajaxWithCacheFactory.ajax(callSettings, 1);
        };

        // Check User by PrimaryEmail (GET https://apidev.monj.com/v1/users/checkprimaryemail)
        factory.checkUserByPrimaryEmail = function (email) {
            var callSettings = {
                url: monjURL + "/users/checkprimaryemail",
                type: "GET",
                data: {
                    "email": email,
                    "apiKey": monjApiKey
                }
            };
            return ajaxWithCacheFactory.ajax(callSettings, 1);
        };

        // Check User by CompanyEmail (GET https://apidev.monj.com/v1/users/checkcompanyemail)
        factory.checkUserByCompanyEmail = function (email) {
            var callSettings = {
                url: monjURL + "/users/checkcompanyemail",
                type: "GET",
                data: {
                    "email": email,
                    "apiKey": monjApiKey
                },
            };
            return ajaxWithCacheFactory.ajax(callSettings, 1);
        };

        /*factory.deleteUserByExternalId = function (externalUserId) {
            var callSettings = {
                url: monjURL + "/users/byExternalId?" + $.param({
                    "apiKey": monjApiKey,
                    "externalUserId": externalUserId
                }),
                type: "DELETE"
            };
            return ajaxWithCacheFactory.ajax(callSettings, 1);
        };*/

        // Gets a user's activity feed (in reverse chron order) given an externalUserId
        factory.getUserActivity = function (externalUserId, pageSize, page) {
            var callSettings = {
                url: monjURL + "/users/activity?" + $.param({
                    "apiKey": monjApiKey,
                    "externalUserId": externalUserId
                }),
                type: "GET",
                data: {
                    pageSize: pageSize ? pageSize : 10,
                    page: page ? page : 1
                }
            };
            return ajaxWithCacheFactory.ajax(callSettings, 1);
        };

        // Submits a question on a recipe on behalf of a user
        factory.submitQuestion = function (userId, recipeId, shareQuestion, content) {
            var callSettings = {
                url: monjURL + "/support/question?" + $.param({
                    "apiKey": monjApiKey
                }),
                type: "POST",
                data: {
                    userId: userId,
                    recipeId: recipeId,
                    shareQuestion: shareQuestion,
                    content: content
                }
            };
            return ajaxWithCacheFactory.ajax(callSettings, 1);
        };

        // Submits a comment on a recipe on behalf of a user
        factory.submitComment = function (userId, recipeId, content) {
            var callSettings = {
                url: monjURL + "/support/comment?" + $.param({
                    "apiKey": monjApiKey
                }),
                type: "POST",
                data: {
                    userId: userId,
                    recipeId: recipeId,
                    content: content
                }
            };
            return ajaxWithCacheFactory.ajax(callSettings, 1);
        };

        factory.getImageUploadURL = function () {
            if (!user.authData) {
                return null;
            }
            else {
                var uid = user.userMonj.userId;
                return monjUploadURL + "/upload/media.ashx?apiKey=" + monjApiKey + "&relatedId=" + uid + "&mediaType=userProfileImage";
            }
        };

        // Undoes a recipe completion for a given user
        factory.undoRecipeActivity = function (activityId) {
            var callSettings = {
                url: monjURL + "/activity/" + activityId + "/undo?" + $.param({
                    "apiKey": monjApiKey,
                }),
                type: "DELETE",
            };
            return ajaxWithCacheFactory.ajax(callSettings, 1);
        };

        // Gets the current daily mission or poll to display for the specified user
        factory.getDailyMission = function (userId) {
            var callSettings = {
                url: monjURL + "/content/daily?" + $.param({
                    "apiKey": monjApiKey,
                    "userId": userId
                }),
                type: "GET",
            };
            return ajaxWithCacheFactory.ajax(callSettings, 1);
        };

        factory.DEVgetMission = function (userId, missionId) {
            var callSettings = {
                url: monjURL + "/content/missions/" + missionId + "?" + $.param({
                    "apiKey": monjApiKey,
                    "userId": userId
                }),
                type: "GET",
            };
            return ajaxWithCacheFactory.ajax(callSettings, 1);
        };

        factory.DEVgetPoll = function (userId, pollId) {
            var callSettings = {
                url: monjURL + "/content/polls/" + pollId + "?" + $.param({
                    "apiKey": monjApiKey,
                    "userId": userId
                }),
                type: "GET",
            };
            return ajaxWithCacheFactory.ajax(callSettings, 1);
        };

        factory.postViewMission = function (userId, missionId) {
            var callSettings = {
                url: monjURL + "/actions/viewmission?" + $.param({
                    "apiKey": monjApiKey,
                    "userId": userId,
                    'missionId': missionId
                }),
                type: "POST",
            };

            var promise = ajaxWithCacheFactory.ajax(callSettings, 1);

            promise.then(function(data) {
                factory.updateUserProfile(data.profile);
                //awardsFactory.checkForAwards(data.outcome);
            });

            return promise;
        };

        factory.postFlipMission = function (userId, missionId) {
            var callSettings = {
                url: monjURL + "/actions/flipmission?" + $.param({
                    "apiKey": monjApiKey,
                    "userId": userId,
                    'missionId': missionId
                }),
                type: "POST"
            };

            var promise = ajaxWithCacheFactory.ajax(callSettings, 1);

            promise.then(function(data) {
                factory.updateUserProfile(data.profile);
                //awardsFactory.checkForAwards(data.outcome);
            });

            return promise;
        };

        factory.postSaveMission = function (userId, missionId) {
            var callSettings = {
                url: monjURL + "/actions/savemission?" + $.param({
                    "apiKey": monjApiKey,
                    "userId": userId,
                    'missionId': missionId
                }),
                type: "POST",
            };

            var promise = ajaxWithCacheFactory.ajax(callSettings, 1);

            promise.then(function(data) {
                factory.updateUserProfile(data.profile);
                //awardsFactory.checkForAwards(data.outcome);
            });

            return promise;
        };

        factory.postUnsaveMission = function (userId, savedMissionId) {
            var callSettings = {
                url: monjURL + "/actions/unsavemission?" + $.param({
                    "apiKey": monjApiKey,
                    "userId": userId,
                    'missionId': savedMissionId
                }),
                type: "POST"
            };

            var promise = ajaxWithCacheFactory.ajax(callSettings, 1);

            promise.then(function(data) {
                factory.updateUserProfile(data.profile);
                //awardsFactory.checkForAwards(data.outcome);
            });

            return promise;
        };

        factory.postCompleteMission = function (userId, missionId) {
            var callSettings = {
                url: monjURL + "/actions/completemission?" + $.param({
                    "apiKey": monjApiKey,
                    'userId': userId,
                    'missionId': missionId
                }),
                type: "POST"
            };

            var promise = ajaxWithCacheFactory.ajax(callSettings, 1);

            promise.then(function(data) {
                factory.updateUserProfile(data.profile);
                awardsFactory.checkForAwards(data.outcome);
            });

            return promise;
        };

        factory.postViewPoll = function (userId, pollId) {
            var callSettings = {
                url: monjURL + "/actions/viewpoll?" + $.param({
                    "apiKey": monjApiKey,
                    'userId': userId,
                    'pollId': pollId
                }),
                type: "POST"
            };

            var promise = ajaxWithCacheFactory.ajax(callSettings, 1);

            promise.then(function(data) {
                factory.updateUserProfile(data.profile);
                //awardsFactory.checkForAwards(data.outcome);
            });

            return promise;
        };

        factory.postAnswerPoll = function (userId, pollId, answer) {
            var callSettings = {
                url: monjURL + "/actions/answerpoll?" + $.param({
                    "apiKey": monjApiKey,
                    'userId': userId,
                    'pollId': pollId,
                    'answer': answer
                }),
                type: "POST"
            };

            var promise = ajaxWithCacheFactory.ajax(callSettings, 1);

            promise.then(function(data) {
                factory.updateUserProfile(data.profile);
                awardsFactory.checkForAwards(data.outcome);
            });

            return promise;
        };

        // Reverses a user cooking a meal
        factory.postUndoMeal = function (userId, recipeId, activityId) {
            var callSettings = {
                url: monjURL + "/actions/undomeal?" + $.param({
                    "apiKey": monjApiKey,
                    "userId": userId,
                    "recipeId": recipeId,
                    "activityId": activityId
                }),
                type: "POST"
            };

            var promise = ajaxWithCacheFactory.ajax(callSettings, 1);

            promise.then(function(data) {
                factory.updateUserProfile(data.profile);
                //awardsFactory.checkForAwards(data.outcome);
            });

            return promise;
        };

        // Records the saving of a meal
        factory.saveMeal = function (userId, recipeId) {
            var callSettings = {
                url: monjURL + "/actions/savemeal?" + $.param({
                    "apiKey": monjApiKey,
                    "userId": userId,
                    "recipeId": recipeId
                }),
                type: "POST"
            };

            var promise = ajaxWithCacheFactory.ajax(callSettings, 1);

            promise.then(function(data) {
                factory.updateUserProfile(data.profile);
                //awardsFactory.checkForAwards(data.outcome);
            });

            return promise;
        };

        // Records the unsaving of a meal
        factory.unsaveMeal = function (userId, recipeId) {
            var callSettings = {
                url: monjURL + "/actions/unsavemeal?" + $.param({
                    "apiKey": monjApiKey,
                    "userId": userId,
                    "recipeId": recipeId
                }),
                type: "POST"
            };

            var promise = ajaxWithCacheFactory.ajax(callSettings, 1);

            promise.then(function(data) {
                factory.updateUserProfile(data.profile);
                //awardsFactory.checkForAwards(data.outcome);
            });

            return promise;
        };

        // Tells the server that the recipe has been advanced
        factory.advanceMeal = function (userId, recipeId, step) {
            var callSettings = {
                url: monjURL + "/actions/advancemeal?" + $.param({
                    "apiKey": monjApiKey,
                    "userId": userId,
                    "recipeId": recipeId,
                    "step": step
                }),
                type: "POST"
            };

            var promise = ajaxWithCacheFactory.ajax(callSettings, 1);

            promise.then(function(data) {
                factory.updateUserProfile(data.profile);
                //awardsFactory.checkForAwards(data.outcome);
            });

            return promise;
        };

        // gets a user's queue, filtered to show only meals (in reverse chron order)
        factory.getQueueMeals = function (userId) {
            var callSettings = {
                url: monjURL + "/queue/meals?" + $.param({
                    "apiKey": monjApiKey,
                    "userId": userId
                }),
                type: "GET"
            };

            return ajaxWithCacheFactory.ajax(callSettings, 1);
        };

        // gets a user's queue, filtered to show only missions (in reverse chron order)
        factory.getQueueMissions = function (userId) {
            var callSettings = {
                url: monjURL + "/queue/missions?" + $.param({
                    "apiKey": monjApiKey,
                    "userId": userId
                }),
                type: "GET"
            };

            return ajaxWithCacheFactory.ajax(callSettings, 1);
        };

        // gets a user's activity feed (in reverse chron order)
        factory.getAllActivities = function (userId, pageSize, page) {
            var callSettings = {
                url: monjURL + "/activity/all?" + $.param({
                    "apiKey": monjApiKey,
                    "userId": userId,
                    "pageSize": pageSize,   // Number of items to retreive (defaults to 10)
                    "page": page            // Which page of items to retrieve, as defined by the pageSize
                }),
                type: "GET"
            };

            return ajaxWithCacheFactory.ajax(callSettings, 1);
        };

        // gets a user's activity feed, filtered to show only meals (in reverse chron order)
        factory.getMealsActivities = function (userId, pageSize, page) {
            var callSettings = {
                url: monjURL + "/activity/meals?" + $.param({
                    "apiKey": monjApiKey,
                    "userId": userId,
                    "pageSize": pageSize,   // Number of items to retreive (defaults to 10)
                    "page": page            // Which page of items to retrieve, as defined by the pageSize
                }),
                type: "GET"
            };

            return ajaxWithCacheFactory.ajax(callSettings, 1);
        };

        // gets a user's activity feed, filtered to show only missions (in reverse chron order)
        factory.getMissionActivities = function (userId, pageSize, page) {
            var callSettings = {
                url: monjURL + "/activity/missions?" + $.param({
                    "apiKey": monjApiKey,
                    "userId": userId,
                    "pageSize": pageSize,   // Number of items to retreive (defaults to 10)
                    "page": page            // Which page of items to retrieve, as defined by the pageSize
                }),
                type: "GET"
            };

            return ajaxWithCacheFactory.ajax(callSettings, 1);
        };

        // Gets a categoriezed list of tags with which to filter recipes
        factory.getRecipeFilters = function (userId) {
            var callSettings = {
                url: monjURL + "/content/tags?" + $.param({
                    "apiKey": monjApiKey,
                    "userId": userId
                }),               
                type: "GET"
            };

            return ajaxWithCacheFactory.ajax(callSettings, 1);
        };

        // Gets a user by userId
        factory.getUserByUserId = function (userId) {
            var callSettings = {
                url: monjURL + "/users/"+ userId + "?" + $.param({
                    "apiKey": monjApiKey
                }),
                type: "GET"
            };

            return ajaxWithCacheFactory.ajax(callSettings, 1);
        };

        // Updates a user's display name by id
        factory.updateUserName = function (userId, newDisplayName) {
            var callSettings = {
                url: monjURL + "/users/"+ userId +"/displayName?" + $.param({
                    "apiKey": monjApiKey,
                    'newDisplayName': newDisplayName
                }),
                type: "PUT",
            };

            return ajaxWithCacheFactory.ajax(callSettings, 1);
        };

        // Updates a user's external account info
        factory.updateExternalAccount = function (userId, newExternalUserId, newPrimaryEmail) {
            var callSettings = {
                url: monjURL + "/users/"+ userId +"/externalAccount?" + $.param({
                    "apiKey": monjApiKey,
                    'newExternalUserId': newExternalUserId,
                    'newPrimaryEmail': newPrimaryEmail
                }),
                type: "PUT"
            };

            return ajaxWithCacheFactory.ajax(callSettings, 1);
        };

        /*******************************
         *
         * @param userId
         * @param challengeId
         * @returns {*}
         */
        // Enrolls a user in a challenge
        factory.postEnrollChallenge = function (userId, challengeId) {
            var callSettings = {
                url: monjURL + "/actions/enrollchallenge?" + $.param({
                    "apiKey": monjApiKey,
                    'userId': userId,
                    'challengeId': challengeId
                }),
                type: "POST"
            };

            var promise = ajaxWithCacheFactory.ajax(callSettings, 1);

            promise.then(function(data) {
                factory.updateUserProfile(data.profile);
                //awardsFactory.checkForAwards(data.outcome);
            });

            return promise;
        };

        // dimisses a challenge from the UI
        factory.postDismissChallenge = function (userId, challengeId) {
            var callSettings = {
                url: monjURL + "/actions/dismisschallenge?" + $.param({
                    "apiKey": monjApiKey,
                    'userId': userId,
                    'challengeId': challengeId
                }),
                type: "POST"
            };

            var promise = ajaxWithCacheFactory.ajax(callSettings, 1);

            promise.then(function(data) {
                factory.updateUserProfile(data.profile);
                //awardsFactory.checkForAwards(data.outcome);
            });

            return promise;
        };

        // Records a user signing in to their new account
        factory.postSignin = function (userId) {
            var callSettings = {
                url: monjURL + "/actions/signin?" + $.param({
                    "apiKey": monjApiKey,
                    'userId': userId
                }),
                type: "POST"
            };

            var promise = ajaxWithCacheFactory.ajax(callSettings, 1);

            promise.then(function(data) {
                factory.updateUserProfile(data.profile);
                //awardsFactory.checkForAwards(data.outcome);
            });

            return promise;
        };

        // Records a user viewing a recipe
        factory.postViewMeal = function (userId, recipeId) {
            var callSettings = {
                url: monjURL + "/actions/viewmeal?" + $.param({
                    "apiKey": monjApiKey,
                    'userId': userId,
                    'recipeId': recipeId
                }),
                type: "POST"
            };

            var promise = ajaxWithCacheFactory.ajax(callSettings, 1);

            promise.then(function(data) {
                factory.updateUserProfile(data.profile);
                //awardsFactory.checkForAwards(data.outcome);
            });

            return promise;
        };

        // Records a user starting a recipe
        factory.postStartMeal = function (userId, recipeId) {
            var callSettings = {
                url: monjURL + "/actions/startmeal?" + $.param({
                    "apiKey": monjApiKey,
                    'userId': userId,
                    'recipeId': recipeId
                }),
                type: "POST"
            };

            var promise = ajaxWithCacheFactory.ajax(callSettings, 1);

            promise.then(function(data) {
                factory.updateUserProfile(data.profile);
                //awardsFactory.checkForAwards(data.outcome);
            });

            return promise;
        };

        /******************************************
         * DEPRECATED
         */
        // Records a user resuming a recipe
        /*factory.postResumeMeal = function (userId, recipeId) {
            var callSettings = {
                url: monjURL + "/actions/resumemeal?" + $.param({
                    "apiKey": monjApiKey,
                    'userId': userId,
                    'recipeId': recipeId
                }),
                type: "POST"
            };

            return ajaxWithCacheFactory.ajax(callSettings, 1).then(function(data) {
                factory.updateUserProfile(data.profile);
                awardsFactory.checkForAwards(data.outcome);
            });
        };*/

        // Records a user viewing a specific step on the active meal
        factory.postStepMeal = function (userId, step) {
            var callSettings = {
                url: monjURL + "/actions/stepmeal?" + $.param({
                    "apiKey": monjApiKey,
                    'userId': userId,
                    'step': step
                }),
                type: "POST"
            };

            var promise = ajaxWithCacheFactory.ajax(callSettings, 1);

            promise.then(function(data) {
                factory.updateUserProfile(data.profile);
                //awardsFactory.checkForAwards(data.outcome);
            });

            return promise;
        };

        // Records a user cooking a recipe
        factory.postCookMeal = function (userId, recipeId, hadFun) {
            var callSettings = {
                url: monjURL + "/actions/cookmeal?" + $.param({
                    "apiKey": monjApiKey,
                    'userId': userId,
                    'recipeId': recipeId,
                    'hadFun': hadFun ? hadFun : 0
                }),
                type: "POST"
            };

            var promise = ajaxWithCacheFactory.ajax(callSettings, 1);

            promise.then(function(data) {
                // On complete calls, pass the award and profile data to local storage so that it will be
                // initiated when the user is returned to the next page or when they reload their browser.
                /*window.localStorage.setItem('updatedProfile', JSON.stringify(data.profile));
                window.localStorage.setItem('updatedOutcome', JSON.stringify(data.outcome));*/
                /*setCookie('updatedProfile', JSON.stringify(data.profile), 7);
                setCookie('updatedOutcome', JSON.stringify(data.outcome), 7);*/
                /*dataTransferFactory.set('updatedProfile', data.profile);
                dataTransferFactory.set('updatedOutcome', data.outcome);*/
                factory.updateUserProfile(data.profile);
                awardsFactory.checkForAwards(data.outcome);
            });

            return promise;
        };

        // Records a user dismissing the active followup card
        factory.postDismissFollowup = function (userId) {
            var callSettings = {
                url: monjURL + "/actions/dismissfollowup?" + $.param({
                    "apiKey": monjApiKey,
                    'userId': userId
                }),
                type: "POST"
            };

            var promise = ajaxWithCacheFactory.ajax(callSettings, 1);

            promise.then(function(data) {
                factory.updateUserProfile(data.profile);
                //awardsFactory.checkForAwards(data.outcome);
            });

            return promise;
        };

        // Emails a shopping list for a given recipe to a user
        factory.emailShoppingList = function (userId, recipeId) {
            var callSettings = {
                url: monjURL + "/content/shoppinglist/send?" + $.param({
                    "apiKey": monjApiKey,
                    'userId': userId,
                    'recipeId': recipeId
                }),
                type: "POST"
            };

            return ajaxWithCacheFactory.ajax(callSettings, 1);
        };

        // Checks if an account invitation code is valid
        factory.checkInvite = function (code) {
            var callSettings = {
                url: monjURL + "/users/checkInvite?" + $.param({
                    "apiKey": monjApiKey,
                    'code': code
                }),
                type: "GET"
            };

            var promise = ajaxWithCacheFactory.ajax(callSettings, 1);

            promise.then(function(data) {
                /*user.invite = data;
                user.inviteCode = code;
                var found = null;
                if(data.found){
                    found = true;
                }
                // Pass data along via query to pop-up form for account creation
                $location.path('/').search({
                    vip: 'true',
                    companyName: data.companyName,
                    convertUser: data.convertUser,
                    displayName: data.displayName,
                    found: found,
                    primaryEmail: data.primaryEmail,
                    inviteCode: code
                });*/
            });

            return promise;
        };

        // Resets a user to its original post signup state
        factory.resetUser = function (userId) {
            var callSettings = {
                url: monjURL + "/users/"+ userId +"/reset?" + $.param({
                    "apiKey": monjApiKey
                }),
                type: "PUT"
            };

            var promise = ajaxWithCacheFactory.ajax(callSettings, 1);

            promise.then(function(data) {
                user.userMonj = data;
            });

            return promise;
        };

        // Sends an activation code to a potential new user
        factory.sendActivationCode = function (companyId, email) {
            var callSettings = {
                url: monjURL + "/users/sendActivation?" + $.param({
                    "apiKey": monjApiKey,
                    "companyId": companyId,
                    "email": email
                }),
                type: "POST",
            };

            return ajaxWithCacheFactory.ajax(callSettings, 1);
        };

        // Checks if an account activation code is valid
        factory.checkActivation = function (code) {
            var callSettings = {
                url: monjURL + "/users/checkActivation?" + $.param({
                    "apiKey": monjApiKey,
                    'code': code
                }),
                type: "GET"
            };

            var promise = ajaxWithCacheFactory.ajax(callSettings, 1);

            promise.then(function(data) {
                user.activation = data;
                user.activationCode = code;
            });

            return promise;
        };

        // Gets a list of other users in the user's extended social network that are in the challenge
        factory.getChallengeBoard = function (userId, topN) {
            topN = topN || 7;

            var callSettings = {
                url: monjURL + "/users/" + userId + "/challengeboard?" + $.param({
                    "apiKey": monjApiKey,
                    'topN': topN
                }),
                type: "GET"
            };

            return ajaxWithCacheFactory.ajax(callSettings, 1);
        };

        // Gets a social activity feed for a given user
        factory.getSocialActivity = function (userId, topN) {
            topN = topN || 0;

            var callSettings = {
                url: monjURL + "/activity/network?" + $.param({
                    "apiKey": monjApiKey,
                    'userId': userId,
                    'topN': topN
                }),
                type: "GET"
            };

            return ajaxWithCacheFactory.ajax(callSettings, 1);
        };

        // Sends an invitation to join a challenge
        factory.challengeInvite = function (challengeId, fromUserId, toEmail, message) {
            var callSettings = {
                url: monjURL + "/content/challengeinvite/send?" + $.param({
                    "apiKey": monjApiKey
                }),
                type: "POST",
                data: {
                    'challengeId': challengeId,
                    'fromUserId': fromUserId,
                    'toEmail': toEmail,
                    'message': message
                }
            };

            return ajaxWithCacheFactory.ajax(callSettings, 1);
        };

        // Gets a short (abbreviated) profile by userId
        factory.getShortUserProfile = function (userId) {
            var callSettings = {
                url: monjURL + "/users/"+ userId +"/shortprofile?" + $.param({
                    "apiKey": monjApiKey
                }),
                type: "GET"
            };

            return ajaxWithCacheFactory.ajax(callSettings, 1);
        };

        // Creates a follow relationships in both directions between userId and followUserId
        factory.followUserReciprocal = function (userId, followUserId) {
            var callSettings = {
                url: monjURL + "/users/"+ userId +"/follow/"+ followUserId +"/reciprocal?" + $.param({
                    "apiKey": monjApiKey
                }),
                type: "POST"
            };

            return ajaxWithCacheFactory.ajax(callSettings, 1);
        };

        // Creates a follow relationship from userId to followUserId
        factory.followUser = function (userId, followUserId) {
            var callSettings = {
                url: monjURL + "/users/"+ userId +"/follow/"+ followUserId +"?" + $.param({
                    "apiKey": monjApiKey
                }),
                type: "POST"
            };

            return ajaxWithCacheFactory.ajax(callSettings, 1);
        };

        // dimisses the orientation video from the UI
        factory.dismissOrientation = function (userId) {
            var callSettings = {
                url: monjURL + "/actions/dismissorientation?" + $.param({
                    "apiKey": monjApiKey,
                    'userId': userId
                }),
                type: "POST"
            };

            var promise = ajaxWithCacheFactory.ajax(callSettings, 1);

            promise.then(function(data) {
                factory.updateUserProfile(data.profile);
                //awardsFactory.checkForAwards(data.outcome);
            });

            return promise;
        };

        // emails a cheat sheet for a given recipe to a user
        factory.sendCheatSheet = function (userId, recipeId) {
            var callSettings = {
                url: monjURL + "/content/cheatsheet/send?" + $.param({
                    "apiKey": monjApiKey,
                    'recipeId': recipeId,
                    'userId': userId
                }),
                type: "POST"
            };
            return ajaxWithCacheFactory.ajax(callSettings, 1);
        };

        // Updates a user's locale - supported vaues are 'en' and 'es'. Other values will result in a 400 Bad Request error
        factory.updateLocale = function (userId, newLocale) {
            var callSettings = {
                url: monjURL + "/users/"+ userId +"/locale?" + $.param({
                    "apiKey": monjApiKey,
                    'newLocale': newLocale
                }),
                type: "PUT"
            };

            return ajaxWithCacheFactory.ajax(callSettings, 1);
        };

        // creates a new user - specifiy EITHER an activationCode OR an inviteCode
        factory.createUser = function (password, activationCode, inviteCode, primaryEmail, displayName, companyEmail, locale) {
            var requestData = {};

            if(activationCode) {
                requestData = {
                    'activationCode': activationCode,
                    'password': password,
                    'primaryEmail': primaryEmail,
                    'displayName': displayName,
                    'companyEmail': companyEmail,
                    'locale': $rootScope.lang
                };
            } else {
                requestData = {
                    'inviteCode': inviteCode,
                    'password': password,
                    'primaryEmail': primaryEmail,
                    'displayName': displayName,
                    'companyEmail': companyEmail,
                    'locale': $rootScope.lang
                };
            }

            var callSettings = {
                url: monjURL + "/users?" + $.param({
                    "apiKey": monjApiKey
                }),
                data: requestData,
                type: "POST"
            };

            return ajaxWithCacheFactory.ajax(callSettings, 1);
        };

        // ------------------------------------------------------------
        // NEW API CALLS for Monj v2 ---------------------------------
        // ------------------------------------------------------------
        // NOTE: All action calls will return a userProfile object reflecting
        // the results of the action. This info is used to update the user
        // profile and gamification data.

        // Updates a user's social opt out status
        factory.socialOptOut = function (userId, optOut) {
            var callSettings = {
                url: monjURL + "/users/"+ userId +"/socialOptOut?" + $.param({
                    "apiKey": monjApiKey,
                    'optOut': optOut
                }),
                type: "PUT"
            };

            return ajaxWithCacheFactory.ajax(callSettings, 1);
        };

        // Updates a user's dietary preferences
        factory.dietaryPrefs = function (userId, glutenFree, vegetarian, dairyFree, lowSugar, noRedMeat) {
            glutenFree = glutenFree ? glutenFree : false;
            vegetarian = vegetarian ? vegetarian : false;
            dairyFree = dairyFree ? dairyFree : false;
            lowSugar = lowSugar ? lowSugar : false;
            noRedMeat = noRedMeat ? noRedMeat : false;
            var callSettings = {
                url: monjURL + "/users/"+ userId +"/DietaryPrefs?" + $.param({
                    "apiKey": monjApiKey,
                    'glutenFree': glutenFree,
                    'vegetarian': vegetarian,
                    'dairyFree': dairyFree,
                    'lowSugar': lowSugar,
                    'noRedMeat': noRedMeat
                }),
                type: "PUT"
            };

            return ajaxWithCacheFactory.ajax(callSettings, 1);
        };

        factory.diseasePrefs = function (userId, lowSalt, lowSugar, lowCarbs, lowCalorie) {
            lowSalt = lowSalt ? lowSalt : false;
            lowSugar = lowSugar ? lowSugar : false;
            lowCarbs = lowCarbs ? lowCarbs : false;
            lowCalorie = lowCalorie ? lowCalorie : false;
            var callSettings = {
                url: monjURL + "/users/"+ userId +"/diseasePrefs?" + $.param({
                    "apiKey": monjApiKey,
                    'lowSalt': lowSalt,
                    'lowSugar': lowSugar,
                    'lowCarbs': lowCarbs,
                    'lowCalorie': lowCalorie
                }),
                type: "PUT"
            };

            var promise = ajaxWithCacheFactory.ajax(callSettings, 1);
            promise.then(function(profile) {
                factory.updateUserProfile(profile);
            });
            return promise;
        };
        
        factory.allergyPrefs = function (userId, dairyFree, glutenFree, shellfishFree, soyFree, treeNutFree, eggFree, nightshadeFree, cornFree, grainFree, meatFree) {
            dairyFree = dairyFree ? dairyFree : false;
            glutenFree = glutenFree ? glutenFree : false;
            shellfishFree = shellfishFree ? shellfishFree : false;
            soyFree = soyFree ? soyFree : false;
            treeNutFree = treeNutFree ? treeNutFree : false;
            eggFree = eggFree ? eggFree : false;
            nightshadeFree = nightshadeFree ? nightshadeFree : false;
            cornFree = cornFree ? cornFree : false;
            grainFree = grainFree ? grainFree : false;
            meatFree = meatFree ? meatFree : false;
            var callSettings = {
                url: monjURL + "/users/"+ userId +"/allergyPrefs?" + $.param({
                    "apiKey": monjApiKey,
                    'dairyFree': dairyFree,
                    'glutenFree': glutenFree,
                    'shellfishFree': shellfishFree,
                    'soyFree': soyFree,
                    'treeNutFree': treeNutFree,
                    'eggFree': eggFree,
                    'nightshadeFree': nightshadeFree,
                    'cornFree': cornFree,
                    'grainFree': grainFree,
                    'meatFree': meatFree,
                }),
                type: "PUT"
            };

            var promise = ajaxWithCacheFactory.ajax(callSettings, 1);
            promise.then(function(profile) {
                factory.updateUserProfile(profile);
            });
            return promise;
        };

        factory.proteinPrefs = function (userId, pescatarian, vegan, vegetarian) {
            pescatarian = pescatarian ? pescatarian : false;
            vegan = vegan ? vegan : false;
            vegetarian = vegetarian ? vegetarian : false;
            var callSettings = {
                url: monjURL + "/users/"+ userId +"/proteinPrefs?" + $.param({
                    "apiKey": monjApiKey,
                    'pescatarian': pescatarian,
                    'vegan': vegan,
                    'vegetarian': vegetarian
                }),
                type: "PUT"
            };

            var promise = ajaxWithCacheFactory.ajax(callSettings, 1);
            promise.then(function(profile) {
                factory.updateUserProfile(profile);
            });
            return promise;
        };

        /************************************************
         Power-Ups
         ************************************************/

        // Records a user viewing the powerup
        factory.viewPowerUp = function (userId, powerUpId) {
            var callSettings = {
                url: monjURL + "/actions/viewpowerup?" + $.param({
                    "apiKey": monjApiKey,
                    "userId": userId,
                    "powerupId": powerUpId
                }),
                type: "POST"
            };

            var promise = ajaxWithCacheFactory.ajax(callSettings, 1);

            promise.then(function(data) {
                factory.updateUserProfile(data.profile);
                //awardsFactory.checkForAwards(data.outcome);
            });

            return promise;
        };

        // Records a user completing a powerup
        factory.completePowerUp = function (userId, powerUpId, answer) {
            var callSettings = {
                url: monjURL + "/actions/completepowerup?" + $.param({
                    "apiKey": monjApiKey,
                    "userId": userId,
                    "powerupId": powerUpId,
                    "answer": answer
                }),
                type: "POST"
            };

            var promise = ajaxWithCacheFactory.ajax(callSettings, 1);

            promise.then(function(data) {
                // On complete calls, pass the award and profile data to local storage so that it will be
                // initiated when the user is returned to the next page or when they reload their browser.
                /*window.localStorage.setItem('updatedProfile', JSON.stringify(data.profile));
                window.localStorage.setItem('updatedOutcome', JSON.stringify(data.outcome));*/
                /*setCookie('updatedProfile', JSON.stringify(data.profile), 7);
                setCookie('updatedOutcome', JSON.stringify(data.outcome), 7);
                dataTransferFactory.set('updatedProfile', data.profile);
                dataTransferFactory.set('updatedOutcome', data.outcome);*/
                factory.updateUserProfile(data.profile);
                awardsFactory.checkForAwards(data.outcome);
            });

            return promise;
        };

        // Records a user saving a powerup to complete later
        factory.savePowerUp = function (userId, powerUpId) {
            var callSettings = {
                url: monjURL + "/actions/savepowerup?" + $.param({
                    "apiKey": monjApiKey,
                    "userId": userId,
                    "powerupId": powerUpId
                }),
                type: "POST"
            };

            var promise = ajaxWithCacheFactory.ajax(callSettings, 1);

            promise.then(function(data) {
                factory.updateUserProfile(data.profile);
                //awardsFactory.checkForAwards(data.outcome);
            });

            return promise;
        };

        // Records a user unsaving a powerup
        factory.unsavePowerUp = function (userId, powerUpId) {
            var callSettings = {
                url: monjURL + "/actions/unsavepowerup?" + $.param({
                    "apiKey": monjApiKey,
                    "userId": userId,
                    "powerupId": powerUpId
                }),
                type: "POST"
            };

            var promise = ajaxWithCacheFactory.ajax(callSettings, 1);

            promise.then(function(data) {
                factory.updateUserProfile(data.profile);
                //awardsFactory.checkForAwards(data.outcome);
            });

            return promise;
        };

        // Retrieves all power-ups from Monj API
        factory.getPowerUps = function (userId) {
            var callSettings = {
                url: monjURL + "/content/powerUps?" + $.param({
                    "apiKey": monjApiKey,
                    "userId": userId
                }),
                type: "GET"
            };

            return ajaxWithCacheFactory.ajax(callSettings);
        };

        // Retrieves a specific power-up from Monj API
        factory.getPowerUp = function (userId, powerUpId, getCached) {
            var callSettings = {
                url: monjURL + "/content/powerUps/"+ powerUpId +"?" + $.param({
                    "apiKey": monjApiKey,
                    "userId": userId
                }),
                type: "GET"
            };

            if(getCached) {
                return ajaxWithCacheFactory.ajax(callSettings);
            } else {
                return ajaxWithCacheFactory.ajax(callSettings, 1);
            }
        };

        // Gets a powerup's id given its CMS id
        factory.getPowerupId = function (userId, cmsId) {
            var callSettings = {
                url: monjURL + "/content/powerupIds/"+ cmsId +"?" + $.param({
                    "apiKey": monjApiKey,
                    "userId": userId
                }),
                type: "GET"
            };

            return ajaxWithCacheFactory.ajax(callSettings);
        };

        // gets a powerup's queue (in reverse chron order)
        factory.queuePowerUp = function (userId, fromCoach) {
            var callSettings = {
                url: monjURL + "/queue/powerups?" + $.param({
                    "apiKey": monjApiKey,
                    "userId": userId,
                    //"fromCoach": fromCoach
                }),
                type: "GET"
            };

            return ajaxWithCacheFactory.ajax(callSettings, 1);
        };

        /************************************************
         How Tos / Modules / Essentials
         ************************************************/
        // Retrieves all modules from Monj API
        factory.getEssentials = function (userId) {
            var callSettings = {
                url: monjURL + "/content/modules?" + $.param({
                    "apiKey": monjApiKey,
                    "userId": userId
                }),
                type: "GET"
            };

            return ajaxWithCacheFactory.ajax(callSettings);
        };

        // Retrieves a specific module
        factory.getEssential = function (userId, moduleId) {
            var callSettings = {
                url: monjURL + "/content/modules/"+ moduleId +"?" + $.param({
                    "apiKey": monjApiKey,
                    "userId": userId
                }),
                type: "GET"
            };

            return ajaxWithCacheFactory.ajax(callSettings);
        };

        // gets a module's queue (in reverse chron order)
        factory.queueEssentials = function (userId, fromCoach) {
            var callSettings = {
                url: monjURL + "/queue/modules?" + $.param({
                    "apiKey": monjApiKey,
                    "userId": userId,
                    //"fromCoach": fromCoach
                }),
                type: "GET"
            };

            return ajaxWithCacheFactory.ajax(callSettings, 1);
        };

        // Records a user viewing the module
        factory.viewEssential = function (userId, moduleId) {
            var callSettings = {
                url: monjURL + "/actions/viewmodule?" + $.param({
                    "apiKey": monjApiKey,
                    "userId": userId,
                    "moduleId": moduleId
                }),
                type: "POST"
            };

            var promise = ajaxWithCacheFactory.ajax(callSettings, 1);

            promise.then(function(data) {
                factory.updateUserProfile(data.profile);
                //awardsFactory.checkForAwards(data.outcome);
            });

            return promise;
        };

        // Records a user completing a module
        factory.completeEssential = function (userId, moduleId, answer) {
            var callSettings = {
                url: monjURL + "/actions/completemodule?" + $.param({
                    "apiKey": monjApiKey,
                    "userId": userId,
                    "moduleId": moduleId,
                    "answer": answer
                }),
                type: "POST"
            };

            var promise = ajaxWithCacheFactory.ajax(callSettings, 1);

            promise.then(function(data) {
                // On complete calls, pass the award and profile data to local storage so that it will be
                // initiated when the user is returned to the next page or when they reload their browser.
                /*window.localStorage.setItem('updatedProfile', JSON.stringify(data.profile));
                window.localStorage.setItem('updatedOutcome', JSON.stringify(data.outcome));*/
                /*setCookie('updatedProfile', JSON.stringify(data.profile), 7);
                setCookie('updatedOutcome', JSON.stringify(data.outcome), 7);*/
                /*dataTransferFactory.set('updatedProfile', data.profile);
                dataTransferFactory.set('updatedOutcome', data.outcome);*/
                factory.updateUserProfile(data.profile);
                awardsFactory.checkForAwards(data.outcome);
            });

            return promise;
        };

        // Records a user saving a module to complete later
        factory.saveEssential = function (userId, moduleId) {
            var callSettings = {
                url: monjURL + "/actions/savemodule?" + $.param({
                    "apiKey": monjApiKey,
                    "userId": userId,
                    "moduleId": moduleId
                }),
                type: "POST"
            };

            var promise = ajaxWithCacheFactory.ajax(callSettings, 1);

            promise.then(function(data) {
                factory.updateUserProfile(data.profile);
                //awardsFactory.checkForAwards(data.outcome);
            });

            return promise;
        };

        // Records a user unsaving a module
        factory.unsaveEssential = function (userId, moduleId) {
            var callSettings = {
                url: monjURL + "/actions/unsavemodule?" + $.param({
                    "apiKey": monjApiKey,
                    "userId": userId,
                    "moduleId": moduleId
                }),
                type: "POST"
            };

            var promise = ajaxWithCacheFactory.ajax(callSettings, 1);

            promise.then(function(data) {
                factory.updateUserProfile(data.profile);
                //awardsFactory.checkForAwards(data.outcome);
            });

            return promise;
        };

        // Gets a recommended recipe, powerup and module for the specified user based on their goals, preferences and history
        factory.getRecommendations = function (userId, previousRecipeId, previousPowerUpId, previousModuleId) {
            var callSettings = {
                url: monjURL + "/content/recommendations?" + $.param({
                    "apiKey": monjApiKey,
                    "userId": userId,
                    "previousRecipeId": previousRecipeId,
                    "previousPowerUpId": previousPowerUpId,
                    "previousModuleId": previousModuleId
                }),
                type: "GET"
            };

            return ajaxWithCacheFactory.ajax(callSettings, 1);
        };

        // Gets a list of all recipes
        factory.getRecipes = function (userId) {
            var callSettings = {
                url: monjURL + "/content/recipes?" + $.param({
                    "apiKey": monjApiKey,
                    "userId": userId
                }),
                type: "GET"
            };

            return ajaxWithCacheFactory.ajax(callSettings);
        };

        // Gets a single recipe
        factory.getRecipe = function (userId, recipeId, getCached) {
            var callSettings = {
                url: monjURL + "/content/recipes/"+ recipeId +"?" + $.param({
                    "apiKey": monjApiKey,
                    "userId": userId
                }),
                type: "GET"
            };

            if(getCached) {
                return ajaxWithCacheFactory.ajax(callSettings);
            } else {
                return ajaxWithCacheFactory.ajax(callSettings, 1);
            }
        };

        // Gets a guided meal's id given its CMS id
        factory.getRecipeId = function (userId, cmsId) {
            var callSettings = {
                url: monjURL + "/content/recipeids/"+ cmsId +"?" + $.param({
                    "apiKey": monjApiKey,
                    "userId": userId
                }),
                type: "GET"
            };

            return ajaxWithCacheFactory.ajax(callSettings);
        };

        // Gets a list of all lessons
        factory.getlessons = function (userId) {
            var callSettings = {
                url: monjURL + "/content/lessons?" + $.param({
                    "apiKey": monjApiKey,
                    "userId": userId
                }),
                type: "GET"
            };

            return ajaxWithCacheFactory.ajax(callSettings);
        };

        // Gets a single lesson
        factory.getLesson = function (userId, lessonId, getCache) {
            var callSettings = {
                url: monjURL + "/content/lessons/"+ lessonId +"?" + $.param({
                    "apiKey": monjApiKey,
                    "userId": userId
                }),
                type: "GET"
            };

            if(getCache){
                return ajaxWithCacheFactory.ajax(callSettings);
            } else {
                return ajaxWithCacheFactory.ajax(callSettings, 1);
            }
        };

        // Gets a lesson's id given its CMS id
        factory.getLessonId = function (userId, cmsId) {
            var callSettings = {
                url: monjURL + "/content/lessonIds/"+ cmsId +"?" + $.param({
                    "apiKey": monjApiKey,
                    "userId": userId
                }),
                type: "GET"
            };

            return ajaxWithCacheFactory.ajax(callSettings);
        };

        // Gets a users' list of saved lessons
        factory.getSavedLessons = function (userId, lessonId) {
            var callSettings = {
                url: monjURL + "/queue/lessons?" + $.param({
                    "apiKey": monjApiKey,
                    "userId": userId
                }),
                type: "GET"
            };

            return ajaxWithCacheFactory.ajax(callSettings);
        };

        // Tells the server that the lesson has been advanced
        factory.advanceLesson = function (userId, lessonId, page) {
            var callSettings = {
                url: monjURL + "/actions/advancelesson?" + $.param({
                    "apiKey": monjApiKey,
                    "userId": userId,
                    "lessonId": lessonId,
                    "page": page
                }),
                type: "POST"
            };

            var promise = ajaxWithCacheFactory.ajax(callSettings, 1);

            promise.then(function(data) {
                factory.updateUserProfile(data.profile);
                //awardsFactory.checkForAwards(data.outcome);
            });

            return promise;
        };

        // Submits an answer to a quiz from within the lesson
        factory.answerLesson = function (userId, lessonId, questionId, answer, answerText, isOther) {
            isOther = isOther ? isOther : false;
            var callSettings = {
                url: monjURL + "/actions/answerlesson?" + $.param({
                    "apiKey": monjApiKey,
                    "userId": userId,
                    "lessonId": lessonId,
                    "questionId": questionId,
                    "answer": answer,
                    "answerText": answerText,
                    "isOther": isOther
                }),
                type: "POST"
            };

            var promise = ajaxWithCacheFactory.ajax(callSettings, 1);

            promise.then(function(data) {
                factory.updateUserProfile(data.profile);
                //awardsFactory.checkForAwards(data.outcome);
            });

            return promise;
        };

        // Tells Monj that the user has completed the lesson.
        factory.completelesson = function (userId, lessonId) {
            var callSettings = {
                url: monjURL + "/actions/completelesson?" + $.param({
                    "apiKey": monjApiKey,
                    "userId": userId,
                    "lessonId": lessonId
                }),
                type: "POST"
            };

            var promise = ajaxWithCacheFactory.ajax(callSettings);

            promise.then(function(data) {
                factory.updateUserProfile(data.profile);
                awardsFactory.checkForAwards(data.outcome);
            });

            return promise;
        };

        // Save the lesson
        factory.saveLesson = function (userId, lessonId) {
            var callSettings = {
                url: monjURL + "/actions/savelesson?" + $.param({
                    "apiKey": monjApiKey,
                    "userId": userId,
                    "lessonId": lessonId
                }),
                type: "POST"
            };

            var promise = ajaxWithCacheFactory.ajax(callSettings);

            promise.then(function(data) {
                factory.updateUserProfile(data.profile);
                //awardsFactory.checkForAwards(data.outcome);
            });

            return promise;
        };

        // Removes the lesson from the users queue
        factory.unsavelesson = function (userId, lessonId) {
            var callSettings = {
                url: monjURL + "/actions/unsavelesson?" + $.param({
                    "apiKey": monjApiKey,
                    "userId": userId,
                    "lessonId": lessonId
                }),
                type: "POST"
            };

            var promise = ajaxWithCacheFactory.ajax(callSettings);

            promise.then(function(data) {
                factory.updateUserProfile(data.profile);
                //awardsFactory.checkForAwards(data.outcome);
            });

            return promise;
        };

        // Tells Monj that the user has viewed the lesson
        factory.viewlesson = function (userId, lessonId) {
            var callSettings = {
                url: monjURL + "/actions/viewlesson?" + $.param({
                    "apiKey": monjApiKey,
                    "userId": userId,
                    "lessonId": lessonId
                }),
                type: "POST"
            };

            var promise = ajaxWithCacheFactory.ajax(callSettings);

            promise.then(function(data) {
                factory.updateUserProfile(data.profile);
                //awardsFactory.checkForAwards(data.outcome);
            });

            return promise;
        };

        // Retrieves a list of all Contenful space information
        factory.getDomains = function () {
            var callSettings = {
                url: monjURL + "/content/domains?" + $.param({
                    "apiKey": monjApiKey
                }),
                type: "GET"
            };

            return ajaxWithCacheFactory.ajax(callSettings);
        };

        // Gets the Contenful space information for the specified domain
        factory.getDomain = function (domainId) {
            var callSettings = {
                url: monjURL + "/content/domains/" + domainId + "?" + $.param({
                    "apiKey": monjApiKey
                }),
                type: "GET"
            };

            return ajaxWithCacheFactory.ajax(callSettings);
        };

        // Sets a user's wisdomGoal by its topicId
        factory.setWisdomGoal = function (userId, topicId) {
            var callSettings = {
                url: monjURL + "/users/"+ userId +"/wisdomGoal?" + $.param({
                    "apiKey": monjApiKey,
                    "topicId": topicId
                }),
                type: "PUT"
            };

            return ajaxWithCacheFactory.ajax(callSettings, 1);
        };

        // Sets a user's wisdomGoal by its topicId
        factory.setPowerUpGoal = function (userId, topicId) {
            var callSettings = {
                url: monjURL + "/users/"+ userId +"/powerUpGoal?" + $.param({
                    "apiKey": monjApiKey,
                    "topicId": topicId
                }),
                type: "PUT"
            };

            return ajaxWithCacheFactory.ajax(callSettings, 1);
        };

        // Sets a user's wisdomGoal by its topicId
        factory.setMethodGoal = function (userId, topicId) {
            var callSettings = {
                url: monjURL + "/users/"+ userId +"/methodGoal?" + $.param({
                    "apiKey": monjApiKey,
                    "topicId": topicId
                }),
                type: "PUT"
            };

            return ajaxWithCacheFactory.ajax(callSettings, 1);
        };

        // Removes a user's methodGoal by userId
        factory.deleteMethodGoal = function (userId) {
            var callSettings = {
                url: monjURL + "/users/"+ userId +"/methodGoal?" + $.param({
                    "apiKey": monjApiKey
                }),
                type: "DELETE"
            };

            return ajaxWithCacheFactory.ajax(callSettings, 1);
        };

        // Removes a user's wisdomGoal by userId
        factory.deleteWisdomGoal = function (userId) {
            var callSettings = {
                url: monjURL + "/users/"+ userId +"/wisdomGoal?" + $.param({
                    "apiKey": monjApiKey
                }),
                type: "DELETE"
            };

            return ajaxWithCacheFactory.ajax(callSettings, 1);
        };

        // Removes a user's powerUpGoal by userId
        factory.deletePowerUpGoal = function (userId) {
            var callSettings = {
                url: monjURL + "/users/"+ userId +"/powerUpGoal?" + $.param({
                    "apiKey": monjApiKey
                }),
                type: "DELETE"
            };

            return ajaxWithCacheFactory.ajax(callSettings, 1);
        };

        // Submits an answer to a quiz from within the lesson
        factory.submitPostComment = function (ugcId, comment) {
            var callSettings = {
                url: monjURL + "/ugc/comment?" + $.param({
                    "apiKey": monjApiKey
                }),
                data: {
                    "ugcId": ugcId,
                    "comment" : comment
                },
                type: "PUT"
            };

            return ajaxWithCacheFactory.ajax(callSettings, 1);
        };

        // Retrieves a list of purposes for onboarding process
        factory.getPurposes = function (userId) {
            var callSettings = {
                url: monjURL + "/content/purposes?" + $.param({
                    "apiKey": monjApiKey,
                    "userId": userId
                }),
                type: "GET"
            };

            return ajaxWithCacheFactory.ajax(callSettings);
        };

        // Submits a users purpose for the onboarding process
        factory.postPurpose = function (userId, purposeId, purposeText) {
            var callSettings = {
                url: monjURL + "/users/" + userId + "/purpose?" + $.param({
                    "apiKey": monjApiKey
                }),
                data: {
                    "userId": userId,
                    "purposeId" : purposeId,
                    "purposeText": purposeText
                },
                type: "PUT"
            };

            return ajaxWithCacheFactory.ajax(callSettings, 1);
        };

        // ------------------------------------------------------------
        // END API UPDATES --------------------------------------
        // ------------------------------------------------------------
        

        factory.getVTTs = function() {
            var callSettings = {
                url: monjURL + "/content/vtt?" + $.param({
                    "apiKey": monjApiKey
                }),
                //async: false,
                type: "GET"
            };
            return ajaxWithCacheFactory.ajax(callSettings, 0);
        };

        // Gets a list of all game levels
        factory.getUserLevels = function(userId) {
            var callSettings = {
                url: monjURL + "/content/levels?" + $.param({
                    "apiKey": monjApiKey,
                    "userId": userId
                }),
                type: "GET"
            };
            return ajaxWithCacheFactory.ajax(callSettings, 0);
        };

        // ------------------------------------------------------------
        // CHALLENGES
        // ------------------------------------------------------------

        // Gets a ranked of teams by percent complete for a given challenge
        factory.getChallengeTeams = function (challengeId, search, pageSize, page) {
            var params = {
                "apiKey": monjApiKey
            };
            if (search && search.trim().length > 0) { params.search = search.trim(); }
            if (pageSize >= 0) { params.pageSize = pageSize; }
            if (page >= 0) { params.page = page; }

            var callSettings = {
                url: monjURL + "/challenges/" + challengeId + "/teams?" + $.param(params),
                type: "GET"
            };

            return ajaxWithCacheFactory.ajax(callSettings, 1);
        };

        // Gets a list oif teams open for new members to join for a given challenge
        factory.getChallengeTeamsOpen = function (challengeId, search, pageSize, page) {
            var params = {
                "apiKey": monjApiKey
            };
            if (search && search.trim().length > 0) { params.search = search.trim(); }
            // if (search) { params.search = search.trim(); }
            if (pageSize >= 0) { params.pageSize = pageSize; }
            if (page >= 0) { params.page = page; }

            var callSettings = {
                url: monjURL + "/challenges/" + challengeId + "/teams/open?" + $.param(params),
                type: "GET"
            };

            return ajaxWithCacheFactory.ajax(callSettings, 1);
        };

        // Gets a detailed profile of a given team including its rules, members and task completions
        factory.getTeamProfileDetailed = function (teamId, userId) {
            var callSettings = {
                url: monjURL + "/teams/" + teamId + "?" + $.param({
                    "apiKey": monjApiKey,
                    "userId": userId
                }),
                type: "GET"
            };

            return ajaxWithCacheFactory.ajax(callSettings, 1);
        };

        // Gets an abbreviated profile of a given team including its overall progress in its challenge
        factory.getTeamProfileShort = function (teamId, userId) {
            var callSettings = {
                url: monjURL + "/teams/" + teamId + "/shortprofile?" + $.param({
                    "apiKey": monjApiKey,
                    "userId": userId
                }),
                type: "GET"
            };

            return ajaxWithCacheFactory.ajax(callSettings, 1);
        };

        // Retrieves a list of custom colors to apply to the widget UI for a given API key
        factory.getWidgetColors = function (partnerId) {
            var callSettings = {
                url: monjURL + "/content/widgetcolors/bypartner?" + $.param({
                    "apiKey": monjApiKey,
                    "partnerId": partnerId
                }),
                type: "GET"
            };

            return ajaxWithCacheFactory.ajax(callSettings);
        };

        // Creates a new team
        factory.createTeam = function (challengeId, teamName, userId) {
            var callSettings = {
                url: monjURL + "/teams?" + $.param({
                    "apiKey": monjApiKey,
                    "challengeId": challengeId,
                    "teamName": teamName,
                    "userId": userId
                }),
                type: "POST"
            };

            // Update the users profile when they join their team data is updated on redirect
            var promise = ajaxWithCacheFactory.ajax(callSettings, 1);
            promise.then(function(data) {
                if (data.profile) {
                    factory.updateUserProfile(data.profile);
                }
            });
            return promise;
        };

        // Sends an email message to each member of the team
        factory.sendTeamMessage = function (teamId, userId, message) {
            var callSettings = {
                url: monjURL + "/teams/" + teamId + "/sendmessage?" + $.param({
                    "apiKey": monjApiKey,
                    "userId": userId,
                    "message": message
                }),
                type: "POST"
            };

            return ajaxWithCacheFactory.ajax(callSettings, 1);
        };

        // Assigns a user to an open team on a given challenge
        factory.assignToOpenTeam = function (challengeId, userId) {
            var callSettings = {
                url: monjURL + "/teams/assign?" + $.param({
                    "apiKey": monjApiKey,
                    "challengeId": challengeId,
                    "userId": userId
                }),
                type: "PUT",
            };

            var promise = ajaxWithCacheFactory.ajax(callSettings, 1);
            promise.then(function(data) {
                if (data.profile) {
                    factory.updateUserProfile(data.profile);
                }
            });
            return promise;
        };

        // Joins a user to a team
        factory.joinATeam = function (teamId, userId) {
            var callSettings = {
                url: monjURL + "/teams/" + teamId + "/join?" + $.param({
                    "apiKey": monjApiKey,
                    "userId": userId
                }),
                type: "PUT",
            };

            var promise = ajaxWithCacheFactory.ajax(callSettings, 1);

            promise.then(function(data) {
                if (data.profile) {
                    factory.updateUserProfile(data.profile);
                }
            });

            return promise;
        };

        // Removes a user from a team
        factory.leaveATeam = function (teamId, userId) {
            var callSettings = {
                url: monjURL + "/teams/" + teamId + "/leave?" + $.param({
                    "apiKey": monjApiKey,
                    "userId": userId
                }),
                type: "PUT",
            };

            var promise = ajaxWithCacheFactory.ajax(callSettings, 1);

            promise.then(function(data) {
                if (data.profile) {
                    if(data.profile.challenge) {
                        data.profile.challenge.team = null;
                    }
                    factory.updateUserProfile(data.profile);
                }
            });

            return promise;
        };

        // Updates a team's name
        factory.updateTeamName = function (teamId, teamName, userId) {
            var callSettings = {
                url: monjURL + "/teams/" + teamId + "/name?" + $.param({
                    "apiKey": monjApiKey,
                    "teamName": teamName,
                    "userId": userId
                }),
                type: "PUT",
            };

            var promise = ajaxWithCacheFactory.ajax(callSettings, 1);
            /*promise.then(function(data) {
                if (data.profile) {
                    factory.updateUserProfile(data.profile);
                }
            });*/
            return promise;
        };

        String.prototype.capitalizeFirstLetter = function() {
            return this.charAt(0).toUpperCase() + this.slice(1);
        };

        factory.localResetTourItems = function() {
          user.userMonj.touredGoals = false;
          user.userMonj.touredHome = false;
          user.userMonj.touredInspiration = false;
          user.userMonj.touredMeals = false;
          user.userMonj.touredModules = false;
          user.userMonj.touredPowerUps = false;
          user.userMonj.touredSaved = false;
          user.userMonj.touredCustomize = true;
        };

        factory.updateTouredPages = function (page, userId) {
            var callSettings = {
                url: monjURL + "/users/" + userId + "/toured/" + page + "?" + $.param({
                    "apiKey": monjApiKey,
                    "userId": userId
                }),
                type: "PUT",
            };

            // if(page == 'customize') {
            //   factory.localResetTourItems();
            // }

            return ajaxWithCacheFactory.ajax(callSettings, 1).then(function(data) {
              //user.userMonj["toured"+page.capitalizeFirstLetter()] = true;
              if (data) {
                factory.updateUserProfile(data);
              }
            });
        };

        /*******************************************
         * Topic Data
         */

        // Gets a list of all powerup topics
        factory.getPowerUpTopics = function(userId) {
            var callSettings = {
                url: monjURL + "/content/topics/powerups?" + $.param({
                    "apiKey": monjApiKey,
                    "userId": userId
                }),
                type: "GET"
            };
            return ajaxWithCacheFactory.ajax(callSettings);
        };

        // Gets a list of all lesson topics
        factory.getLessonTopics = function(userId) {
            var callSettings = {
                url: monjURL + "/content/topics/lessons?" + $.param({
                    "apiKey": monjApiKey,
                    "userId": userId
                }),
                type: "GET"
            };
            return ajaxWithCacheFactory.ajax(callSettings);
        };

        factory.updateUserProfile = function(profile) {
            if(profile) {
                user.userMonj = profile;                    // Update user profile object
                isUserExpired(profile);
            }
        };

        // Initializes a demo challenge/team up for the company Monj
        factory.initChallenge = function () {
            var callSettings = {
                url: monjURL + "/demos/initchallenge?" + $.param({
                    "apiKey": monjApiKey
                }),
                type: "PUT",
            };

            var promise = ajaxWithCacheFactory.ajax(callSettings, 1);
            promise.then(function(data) {
                if (data.profile) {
                    factory.updateUserProfile(data.profile);
                }
            });
            return promise;
        };

        // Opens a demo challenge/team up for the company Monj
        factory.openChallenge = function () {
            var callSettings = {
                url: monjURL + "/demos/openchallenge?" + $.param({
                    "apiKey": monjApiKey
                }),
                type: "PUT",
            };

            var promise = ajaxWithCacheFactory.ajax(callSettings, 1);
            promise.then(function(data) {
                if (data.profile) {
                    factory.updateUserProfile(data.profile);
                }
            });
            return promise;
        };

        /******************
         * SHOPPING LISTS *
         ******************/

        // Adds the recipe to the user's shopping list
        factory.saveShoppingList = function (userId, recipeId) {
            var callSettings = {
                url: monjURL + "/shoppinglists/" + userId + "/recipes?" + $.param({
                    "apiKey": monjApiKey,
                    "recipeId": recipeId
                }),
                type: "PUT"
            };

            return ajaxWithCacheFactory.ajax(callSettings, 1);
        };

        // Returns the recipes in the user's shopping list
        factory.getShoppingLists = function (userId) {
            var callSettings = {
                url: monjURL + "/shoppinglists/" + userId + "/recipes?" + $.param({
                    "apiKey": monjApiKey
                }),
                type: "GET"
            };

            return ajaxWithCacheFactory.ajax(callSettings, 1);
        };

        // Sends the user's consolidated shopping list to the user's primary email address
        factory.sendShoppingLists = function (userId) {
            var callSettings = {
                url: monjURL + "/shoppinglists/" + userId + "/send?" + $.param({
                    "apiKey": monjApiKey
                }),
                type: "POST"
            };

            return ajaxWithCacheFactory.ajax(callSettings, 1);
        };

        // Removes the recipe from the user's shopping list
        factory.deleteShoppingList = function (userId, recipeId) {
            var callSettings = {
                url: monjURL + "/shoppinglists/" + userId + "/recipes?" + $.param({
                    "apiKey": monjApiKey,
                    "recipeId": recipeId
                }),
                type: "DELETE"
            };

            return ajaxWithCacheFactory.ajax(callSettings, 1);
        };

        // Removes all recipes from the user's shopping list
        factory.deleteShoppingLists = function (userId) {
            var callSettings = {
                url: monjURL + "/shoppinglists/" + userId + "?" + $.param({
                    "apiKey": monjApiKey
                }),
                type: "DELETE"
            };

            return ajaxWithCacheFactory.ajax(callSettings, 1);
        };

        /*********************************************
         * Blueprint APIs
         */

        factory.getBlueprints = function (userId) {
            var callSettings = {
                url: monjURL + "/content/blueprints?" + $.param({
                    "apiKey": monjApiKey,
                    "userId": userId
                }),
                type: "GET",
            };
            return ajaxWithCacheFactory.ajax(callSettings, 1);
        };

        factory.getPreferenceName = function(stateName) {
            switch(stateName) {
                case 'lowsalt':
                    return "reduced salt";
                case 'lowsugar':
                    return "reduced sugar";
                case 'lowcarbs':
                    return "reduced carbs";
                case 'lowcalories':
                    return "reduced calories";
                case 'dairyfree':
                    return "dairy free";
                case 'glutenfree':
                    return "gluten free";
                case 'shellfishfree':
                    return "shellfish free";
                case 'meatfree':
                    return "meat free";
                case 'treenutfree':
                    return "treenut free";
            }
        }
        /**
         * End Blueprint APIs
         ********************************************/

        factory.init();

        return factory;
    };

    angular.module('monjApp').factory('userFactory', ['$timeout', 'ajaxWithCacheFactory', 'dataTransferFactory', 'appSettings', '$q', '$location', '$rootScope', '$translate', '$state', 'awardsFactory', userFactory]);

}());
