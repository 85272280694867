(function() {

    var ChallengeTeamController = function ($scope, $timeout, $translate, $location, $sce, $q, dataTransferFactory, userFactory, teamInit, userInit, $state, appSettings) {
        $scope.user = userInit;
        $scope.teamProfile = teamInit;
        $scope.currTab = 0;
        $scope.messageErrorMsg = [];
        $scope.triggerAnimation = false;
        $scope.hashtag = '#MonjLife';

        // Log this page view for analytics.
        analytics.page({
            title: 'ChallengeTeamController',
            url: $location.absUrl(),
            path: $location.path(),
            referrer: document.referrer
        });

        var monjApiKey = appSettings.monjApiKey;
        var monjURL = appSettings.monjURL;
        var ugcId = null;

        // If the user doesn't have a challenge, display no challenge message
        if(!$scope.user.userMonj.challenge) {
            $location.path($state.get('authExperience').url);
        }

        // If the user is not a member of the specified team, redirect them to the challenge page
        if(!$scope.user.userMonj.challenge.team || $scope.user.userMonj.challenge.team.teamId != $scope.teamProfile.teamId) {
            $location.path($state.get('challenge').url);
        }

        dataTransferFactory.set('challengeId', $scope.user.userMonj.challenge.challengeId);

        $scope.isPhone = function() {
            return $(window).width() <= 768;
        };

        $scope.ourTeamProfile = $scope.teamProfile.teamMembers.filter(function (e) { return e.userId == $scope.user.userMonj.userId; })[0];
        $scope.waiting = false;
        $scope.teamName = $scope.teamProfile.teamName;

        $scope.progressParamsColors = ["#FFC411", "#8CDECB", "#21C2DB", "#FFE581", "#1EA182", "#A6E7F1"];

        function createUGCContent() {
            $('#inspiration-items').empty();
            monjugc.init({
                contentType: 'challenges',
                id: $scope.user.userMonj.challenge.challengeId,
                featured: false,
                carousel: false,
                disableInfiniteScroll: false,
                monjApiKey: monjApiKey,
                monjUrl: monjURL,
                userId: $scope.user.userMonj.userId,
                navigationArrows: false
            }, "inspiration-items");
        }

        function init() {

            var challengeJoined = dataTransferFactory.get('joinChallengeSuccess');
            if(challengeJoined) {
                dataTransferFactory.remove('joinChallengeSuccess');
                openModal('join-challenge-success-container');
            }


            // If a hashtag exists for this challenge, override the default hashtag
            if($scope.user.userMonj.challenge.hashtag) {
                $scope.hashtag = $scope.user.userMonj.challenge.hashtag;
            }

            $timeout(function() {
                createUGCContent();
            });

            if($location.hash()) {
                $scope.switchTab(1);
                $timeout(function() {
                    $('.page-tabs li').removeClass('active');
                    $('.page-tabs li:last-child').addClass('active');
                    $('.page-tabs li a').removeClass('active');
                    $('.page-tabs li:last-child a').addClass('active');
                });
            } else {
                $scope.switchTab(0);
            }
            $('.page-tabs li:first-child').addClass('active');

            dataTransferFactory.set('contentTitle', $scope.teamProfile.challengeName);
        }

        $('#invite').on("focus", function(e) {
            e.target.select();
            $(e.target).one('mouseup', function(e) {
                e.preventDefault();
            });
        });

        // Sets the route and configures the data for rerouting the user to the appropriate page.
        $scope.setRouteAndDataFromContentType = function(contentType, data) {
            // Set the data to be retrieve by the redirected page.
            dataTransferFactory.set('challengeData', data);

            contentType = contentType.toLowerCase();
            switch(contentType) {
                case 'module':
                    $scope.waiting = true;
                    return 'howTos';
                    break;
                case 'meal':
                    $scope.waiting = true;
                    return 'guidedMealsIndex';
                    break;
                case 'powerup':
                    $scope.waiting = true;
                    return 'powerUps';
                    break;
                case 'lesson':
                    $scope.waiting = true;
                    return 'lessons';
                    break;
                default:
                    $scope.waiting = true;
                    return 'guidedMealsIndex';
                    break;
            }

        };

        $scope.switchTab = function(tNum) {
            /*$('.tag-tab-wrapper').toggleClass('zero-op');
            $timeout(function() {*/
                $scope.currTab = tNum;
                /*$('.tag-tab-wrapper').toggleClass('zero-op');
            }, 400);*/
        };

        $scope.getMemberName = function(userId) {
            var memberData = $scope.teamProfile.teamMembers.filter(function(result) {
                return result.userId == userId;
            })[0];

            if(memberData) {
                return memberData.displayName;
            }
        };

        $scope.getMemberImage = function(userId) {
            var memberData = $scope.teamProfile.teamMembers.filter(function(result) {
                return result.userId == userId;
            })[0];

            if(memberData) {
                return memberData.profileImageUrl;
            }
        };

        $scope.isCaptain = function() {
            var memberData = $scope.teamProfile.teamMembers.filter(function(result) {
                return result.userId == $scope.user.userMonj.userId;
            })[0];

            if(memberData) {
                return memberData.isCaptain;
            }
        };

        $scope.editTeamName = function(teamName) {
            $scope.waiting = true;
            userFactory.updateTeamName($scope.teamProfile.teamId, teamName, $scope.user.userMonj.userId).then(function() {
                $scope.teamProfile.teamName = teamName;
                $scope.waiting = false;
            }, function() {
                $scope.waiting = false;
            });
        };

        $scope.getHtml = function(html){
            return $sce.trustAsHtml(html);
        };

        $scope.leaveTeam = function(userId, teamId) {
            $scope.waiting = true;
            userFactory.leaveATeam(teamId, userId).then(function() {
                if($scope.isCaptain && $scope.user.userMonj.userId != userId) {
                    $scope.waiting = false;
                    // Manually remove user from the teamMembers list if the user is not the one doing the removing (aka the team captain removed the user)
                    var i = 0;
                    $scope.teamProfile.teamMembers.filter(function(data) {
                        if(data.userId == userId) {
                            $scope.teamProfile.teamMembers.splice(i, 1);
                        }
                        i++;
                    });
                } else {
                    $location.path($state.get('authExperience').url);
                }
            });
        };

        $scope.renderHtml = function(html_code, element)
        {
            $(element).html(html_code);
            return true;
        };

        $scope.redirectTo = function (url) {
            if($scope.user.userMonj.challenge.state == 1) {
                $location.path($state.get(url).url);
            } else {
                $scope.waiting = false;
            }
        };

        $scope.redirectToContentType = function(contentType, id) {
            $scope.waiting = true;
            switch(contentType.toLowerCase()) {
                case 'meal':
                    $location.path($state.href('expandedRecipe', {recipeId: id}).replace('#', ''));
                    break;
                case 'powerup':
                    $location.path($state.href('powerUpView', {powerupId: id}).replace('#', ''));
                    break;
                case 'lesson':
                    $location.path($state.href('lesson', {id: id}).replace('#', ''));
                    break;
            }
        };

        $scope.getInviteLink = function() {
            if($scope.user.userMonj.challenge.team) {
                return 'https://' + $location.host() + '/' + $state.href('inviteToTeam', {teamId: $scope.user.userMonj.challenge.team.teamId});
            }
        };

        $scope.getTimeSince = function(time) {
            return moment(time).fromNow();
        };

        $scope.membersCompleted = function(index) {
            for(var x = 0; x < $scope.teamProfile.individualRules[index].teamMembers.length; x++) {
                if($scope.teamProfile.individualRules[index].teamMembers[x].isComplete) {
                    return true;
                }
            }
        };

        $scope.sendMessageToTeam = function (messageText) {
            $scope.messageErrorMsg.visible = false;
            var trimmedMessage = messageText;
            if(trimmedMessage){
                if (trimmedMessage.length > 0) {
                    userFactory.sendTeamMessage($scope.teamProfile.teamId, $scope.user.userMonj.userId, trimmedMessage).then(function() {
                        $scope.messageSent = true;
                        // Clear message
                        $scope.messageText = ''; // Angular doesn't seem to be working properly with ng-model. This is has no affect.
                        document.getElementById('teamMessageBox').value = "";
                        // Reset after five seconds
                        $timeout(function() {
                            $('.message-team-container').removeClass('active');
                            $('.modal-background').addClass('hide');
                            $scope.messageSent = false;
                        }, 5000);
                    });
                }
            } else {
                $scope.messageErrorMsg.visible = true;
                $translate('challenge.noMessageError').then(function(translation) {
                    $scope.messageErrorMsg.msg = translation;
                });
            }
        };

        function openModal(className, delay) {
            $timeout(function() {
                jQuery.event.trigger('pauseVideo');
                $('.' + className).addClass('active');
                $('.modal-background').removeClass('hide');
            }, Math.max(delay | 0, 0));
        };

        $scope.createProgressBar = function(percentage, elemName, trailWidth, strokeWidth, trailColor, color) {
            color = color ? color : '#30C2D9';
            trailColor = trailColor ? trailColor : '#ffffff';
            trailWidth = trailWidth ? trailWidth : '8';
            strokeWidth = strokeWidth ? strokeWidth : '8';

            var from = Math.min(strokeWidth, 10);
            var to = Math.min(strokeWidth, 8);

            $scope.bar = new ProgressBar.Circle(elemName, {
                color: color,
                trailColor: trailColor,
                // This has to be the same size as the maximum width to
                // prevent clipping
                strokeWidth: strokeWidth,
                trailWidth: trailWidth,
                easing: 'easeInOut',
                duration: 1400,
                text: {
                    autoStyleContainer: false
                },
                from: { width: from },
                to: { width: to },
                // Set default step function for all animate calls
                step: function(state, circle) {
                    circle.path.setAttribute('stroke', circle._opts.color);
                    circle.path.setAttribute('stroke-width', state.width);

                    //var value = Math.round(circle.value());
                }
            });
            $scope.bar.animate(percentage);
        };

        // Recursive animation function for the teammates and progress-bars
        $scope.animateUsersProgress = function(x, delay) {
            // Peform the animation
            var teammate = $('#teamMember-' + x).parent().parent().parent();
            teammate.addClass('pop-in');
            teammate.removeClass('invisible');

            // If statement to check if the progress bar has already been created... Fix for back button bug to prevent instantiating multiple progress bars
            if($('#teamMember-' + x).children('svg').length === 0) {
                // Animate progress circles
                $scope.createProgressBar($scope.teamProfile.teamMembers[x].contributionPercent / 100, '#teamMember-' + x, 8, 6, '#FFFFFF', $scope.progressParamsColors[x]);
                // Animate progress bars
                for (var i = 0; i < $scope.teamProfile.completedTasks.length; i++) {
                    if ($scope.teamProfile.completedTasks[i].userId == $scope.teamProfile.teamMembers[x].userId) {
                        var progressBar = $('#completed-task-' + i);
                        progressBar.css('width', (1 / $scope.teamProfile.totalTaskCount) * 100 + '%');
                    }
                }
            }

            var singleTeammate = $('.team-row .teammates .teammate.member-selection-' + x);
            var recount = x;
            singleTeammate.mouseenter(function() {
                $('.team-progress .progress .progress-bar.member-selection-' + recount).addClass('bubble');
            });
            singleTeammate.mouseleave(function() {
                $('.team-progress .progress .progress-bar.member-selection-' + recount).removeClass('bubble');
            });

            // Once the animation is complete, perform the next one, and the next, until complete
            x++;
            if($scope.teamProfile.teamMembers[x]) {
                $timeout(function () {
                    $scope.animateUsersProgress(x,delay);
                }, delay);
            } else {
                $timeout(function () {
                    var allTeammates = $('.team-row .teammates .teammate');
                    allTeammates.removeClass('pop-in');
                    $scope.teamAnimationComplete = true;
                    //allTeammates.addClass('bubble');
                    //$('.team-progress .progress .progress-bar').addClass('bubble');
                    
                    // Perform hover effects.
                    
                }, delay);
            }
        };

        $(document).ready(function() {
            // check to see if the elements are already within the users view, and if so, immediate initiate the animation.
            $timeout(function() {
                /*if(document.body.scrollTop >= $('#task-list-top').offset().top - window.innerHeight && $scope.user.userMonj.challenge.state != 1) {
                    $scope.triggerAnimation = true; // Only trigger the animation once. After it's triggered, do not perform anymore scroll calculations.
                    $scope.animateUsersProgress(0, 400); // Start animation at index 0, 400ms delay
                }*/
                var topPos = document.body.scrollTop;
                var progressBar = $('.team-progress');
                if(progressBar.offset()) {
                    var triggerPos = progressBar.offset().top - window.innerHeight; // remove the viewable window height to pad the viewable area, ensuring the animation is within the window rather than past it
                    if (topPos >= triggerPos) {
                        $scope.triggerAnimation = true; // Only trigger the animation once. After it's triggered, do not perform anymore scroll calculations.
                        $scope.animateUsersProgress(0, 400); // Start animation at index 0, 1 second delay
                    }
                } else {
                    $scope.triggerAnimation = true; // Only trigger the animation once. After it's triggered, do not perform anymore scroll calculations.
                    $scope.animateUsersProgress(0, 400); // Start animation at index 0, 1 second delay
                }
            }, 400);

            // When the user scrolls into view, initialize animations
            document.onscroll = function() {
                if(!$scope.triggerAnimation) {
                    var topPos = document.body.scrollTop;
                    var progressBar = $('.team-progress');
                    if(progressBar.offset()) {
                        var triggerPos = progressBar.offset().top - window.innerHeight; // remove the viewable window height to pad the viewable area, ensuring the animation is within the window rather than past it
                        if (topPos >= triggerPos) {
                            $scope.triggerAnimation = true; // Only trigger the animation once. After it's triggered, do not perform anymore scroll calculations.
                            $scope.animateUsersProgress(0, 400); // Start animation at index 0, 1 second delay
                        }
                    } else {
                        $scope.triggerAnimation = true; // Only trigger the animation once. After it's triggered, do not perform anymore scroll calculations.
                        $scope.animateUsersProgress(0, 400); // Start animation at index 0, 400ms delay
                    }
                }
            };
        });

        init();
    };

    angular.module('monjApp')
        .controller('ChallengeTeamController', ['$scope', '$timeout', '$translate', '$location', '$sce', '$q', 'dataTransferFactory', 'userFactory', 'teamInit', 'userInit', '$state', 'appSettings', ChallengeTeamController]);

}());
