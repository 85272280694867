(function() {

	var PublicExperienceController = function ($scope, $location, $timeout, appSettings, dataTransferFactory, $state, cookieFactory) {
		$scope.isTouch = Modernizr.touch;
		$scope.currTestimonialIndex = 0;

		function init() {

			$scope.segmentKey = appSettings.segmentKey;

			//$('#app-wrapper').addClass('pe-max-width-override');

			// Default Language Settings
			if(cookieFactory.isLocalStorage()){
				$scope.lang = localStorage.getItem('defaultLanguage');
			} else {
				$scope.lang  = cookieFactory.getCookie('defaultLanguage');
			}

			if(!$scope.lang){
				$scope.lang = 'en';
			}

			// Check for and initiate Why Sign Up pop-up
			/*var signUpFirst = dataTransferFactory.get('whySignUp');
			if(signUpFirst) {
				$timeout(function() {
					$scope.openModal('log-in-container');
				}, 500);
			}*/

			// Check for expired user Pop-up
			var isExpired = dataTransferFactory.get('userExpired');
            dataTransferFactory.remove('userExpired');

            if(cookieFactory.isLocalStorage() && !isExpired){
                isExpired = localStorage.getItem('expired');
                localStorage.removeItem("expired");
            } else {
                isExpired = cookieFactory.getCookie('expired');
                document.cookie = "expired=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
            }

            $scope.fromWidget = dataTransferFactory.get('isExpiredWidget');

            if(isExpired) {
                dataTransferFactory.remove('whySignUp');
                $timeout(function () {
                    $scope.openModal('user-expired-popup-container');
                });
            }

			// Check for and initiate VIP Registration pop-up
			var vip = dataTransferFactory.get('vipRegistration');
			if(vip) {
				$timeout(function() {
					// Assign values for VIP Registration form
					$scope.found = vip.found;
					$scope.companyName = vip.companyName;
					$scope.convertUser = vip.convertUser;
					$scope.displayName = vip.displayName;
					$scope.primaryEmail = vip.primaryEmail;
					$scope.inviteCode = vip.inviteCode;
					$scope.sponsor = vip.sponsor;
					$scope.isSoleraMDPP = vip.isSoleraMDPP;

					dataTransferFactory.remove('vipRegistration');
					$scope.openModal('vip-registration-container');
				}, 500);
			}

			// Get registration/sign-in/reset-password routes
			$scope.userRegistration = dataTransferFactory.get('generalUserRegistration');
			$scope.isSSO = dataTransferFactory.get('isSSO');
			$scope.whySignUp = dataTransferFactory.get('whySignUp');
			$scope.resetPass = dataTransferFactory.get('resetPassword');

			// Before displaying sign-in pop-up, check for other routes
			if($scope.userRegistration) {
				$scope.whySignUp = null;

				// Decide which pop-up to open
				if(!$scope.userRegistration.convertUser) {	// General registration
					$timeout(function() {
						$scope.openModal('vip-sign-up-sponsored-container');
					});
				} else {	// SSO Conversion
					$timeout(function() {
						$scope.openModal('vip-convert-user-container');
					});
				}
			}
			
			// Load reset password pop-up
			if($scope.resetPass) {
				$scope.whySignUp = null;
				$timeout(function() {
					$scope.openModal('pass-reset-container');
				});
			}

			// load sign-in pop-up if no other pop-ups found.
			if($scope.whySignUp) {
				dataTransferFactory.remove('whySignUp');
				$timeout(function() {
					dataTransferFactory.set('rerouteUser', $location.path());
					$scope.openModal('log-in-container');
				});
			}

		}

		init();

		$scope.clearQuery = function() {
			$location.search({});
		};

		$scope.redirectTo = function (url) {
			$location.path($state.get(url).url);
		};

		$scope.openModal = function(className) {
            jQuery.event.trigger('pauseVideo');
			$('.' + className).addClass('active');
			$('.modal-background').removeClass('hide');
		};

		$scope.closeModal = function() {
			$('.popup-modal').removeClass('active');
			$('.modal-background').addClass('hide');
			$scope.clearQuery();
			dataTransferFactory.remove('generalUserRegistration');
			dataTransferFactory.remove('whySignUp');
			$scope.ssoEnabled = false;
			$scope.activationEmailSent = false;
		};

		// This method closes the current modal and loads a new one after 300ms.
		$scope.openModalFromWithinModal = function(className) {
			$scope.closeModal();
			$timeout(function() {
				$scope.openModal(className);
			}, 300);
		};
	};

	angular.module('monjApp')
		.controller('PublicExperienceController', ['$scope', '$location', '$timeout', 'appSettings', 'dataTransferFactory', '$state', 'cookieFactory', PublicExperienceController]);

}());
