(function() {

    var HeaderController = function ($templateCache, $route, $scope, $q, $log, $location, $state, $sce, $timeout, $translate, dataTransferFactory, userFactory, awardsFactory) {
        $scope.user = userFactory.getUserData();
        $scope.waiting = false;

        $scope.recipesLimit = 3;
        $scope.powerUpsLimit = 3;
        $scope.essentialsLimit = 3;

        var seeAll = '';
        var seeLess = '';

        $scope.searchHighlighterTimeout = true;

        if($scope.user.userMonj) {
            $scope.levIndex = $scope.user.userMonj.levelIndex;
        }

        if($state.current.name === "authExperience") {
            $scope.showChallengeHeader = true;
        }

        $scope.chefHatLevels = ['newbie-color','fearless-foodie-color','fearless-foodie-p-color','fearless-foodie-pp-color',
            'confident-cook-color','confident-cook-p-color','confident-cook-pp-color','super-chef-color',
            'super-chef-p-color','super-chef-pp-color','monj-master-filled'];

        $scope.progressParamsColors = ["#FFC411", "#8CDECB", "#21C2DB", "#FFE581", "#1EA182", "#A6E7F1"];

        Beacon('on', 'open', function() {
            $('#beacon-container').css('opacity', '1');
        });

        Beacon('on', 'close', function() {
            $('#beacon-container').css('opacity', '0');
        });

        function init() {

            $translate('challenge.seeAll').then(function (translation) {
                seeAll = translation;
            });
            $translate('challenge.seeLess').then(function (translation) {
                seeLess = translation;
            });

            /*if(!$scope.user.beacon) {
                $scope.user.beacon = true;

                $scope.helpscout = [];
                // Get translation data before initiating beacon
                $translate('helpscout.culinary').then(function (translation) {
                    $scope.helpscout.culinary = translation;
                });
                $translate('helpscout.account').then(function (translation) {
                    $scope.helpscout.account = translation;
                });
                $translate('helpscout.features').then(function (translation) {
                    $scope.helpscout.features = translation;
                });
                $translate('helpscout.bug').then(function (translation) {
                    $scope.helpscout.bug = translation;
                });
                $translate('helpscout.other').then(function (translation) {
                    $scope.helpscout.other = translation;
                });
                $translate('helpscout.instructions').then(function (translation) {
                    $scope.helpscout.instructions = translation;
                });
                $translate('helpscout.fileLabel').then(function (translation) {
                    $scope.helpscout.fileLabel = translation;
                });
                $translate('helpscout.contactSuccess').then(function (translation) {
                    $scope.helpscout.contactSuccess = translation;
                });
                $translate('helpscout.attachFileLabel').then(function (translation) {
                    $scope.helpscout.attachFileLabel = translation;
                });
                $translate('helpscout.attachFileError').then(function (translation) {
                    $scope.helpscout.attachFileError = translation;
                });
                $translate('helpscout.nameLabel').then(function (translation) {
                    $scope.helpscout.nameLabel = translation;
                });
                $translate('helpscout.nameError').then(function (translation) {
                    $scope.helpscout.nameError = translation;
                });
                $translate('helpscout.emailLabel').then(function (translation) {
                    $scope.helpscout.emailLabel = translation;
                });
                $translate('helpscout.emailError').then(function (translation) {
                    $scope.helpscout.emailError = translation;
                });
                $translate('helpscout.topicLabel').then(function (translation) {
                    $scope.helpscout.topicLabel = translation;
                });
                $translate('helpscout.topicError').then(function (translation) {
                    $scope.helpscout.topicError = translation;
                });
                $translate('helpscout.subjectLabel').then(function (translation) {
                    $scope.helpscout.subjectLabel = translation;
                });
                $translate('helpscout.subjectError').then(function (translation) {
                    $scope.helpscout.subjectError = translation;
                });
                $translate('helpscout.messageLabel').then(function (translation) {
                    $scope.helpscout.messageLabel = translation;
                });
                $translate('helpscout.messageError').then(function (translation) {
                    $scope.helpscout.messageError = translation;
                });
                $translate('helpscout.sendLabel').then(function (translation) {
                    $scope.helpscout.sendLabel = translation;
                });
                $translate('helpscout.contactSuccessLabel').then(function (translation) {
                    $scope.helpscout.contactSuccessLabel = translation;
                });
                $translate('helpscout.contactSuccess').then(function (translation) {
                    $scope.helpscout.contactSuccess = translation;
                });

                !function(e,o,n){window.HSCW=o,window.HS=n,n.beacon=n.beacon||{};var t=n.beacon;t.userConfig={},t.readyQueue=[],t.config=function(e){this.userConfig=e},t.ready=function(e){this.readyQueue.push(e)},o.config={docs:{enabled:!1,baseUrl:""},contact:{enabled:!0,formId:"9c0b3b11-663a-11e5-8846-0e599dc12a51"}};var r=e.getElementsByTagName("script")[0],c=e.createElement("script");c.type="text/javascript",c.async=!0,c.src="https://djtflbt20bdde.cloudfront.net/",r.parentNode.insertBefore(c,r)}(document,window.HSCW||{},window.HS||{});

                $timeout(function() {
                    HS.beacon.config({
                        modal: true,
                        color: '#FA9C03',
                        icon: 'question',
                        translation: {
                            attachFileLabel: $scope.helpscout.attachFileLabel,
                            attachFileError: $scope.helpscout.attachFileError,
                            nameLabel: $scope.helpscout.nameLabel,
                            nameError: $scope.helpscout.nameError,
                            emailLabel: $scope.helpscout.emailLabel,
                            emailError: $scope.helpscout.emailError,
                            topicLabel: $scope.helpscout.topicLabel,
                            topicError: $scope.helpscout.topicError,
                            subjectLabel: $scope.helpscout.subjectLabel,
                            subjectError: $scope.helpscout.subjectError,
                            messageLabel: $scope.helpscout.messageLabel,
                            messageError: $scope.helpscout.messageError,
                            sendLabel: $scope.helpscout.sendLabel,
                            contactSuccessLabel: $scope.helpscout.contactSuccessLabel,
                            contactSuccessDescription: $scope.helpscout.contactSuccess
                        },
                        topics: [
                            { val: 'culinary', label: $scope.helpscout.culinary },
                            { val: 'account', label: $scope.helpscout.account },
                            { val: 'features', label: $scope.helpscout.features },
                            { val: 'bug', label: $scope.helpscout.bug },
                            { val: 'other', label: $scope.helpscout.other }
                        ],
                        attachment: true,
                        instructions: $scope.helpscout.instructions,
                        poweredBy: false,
                        zIndex: 99999
                    });
                });

            }

            $timeout(function() {
                HS.beacon.ready(function() {
                    HS.beacon.identify({
                        name: $scope.user.userMonj.displayName,
                        email: $scope.user.userMonj.companyEmail,
                        Company: $scope.user.userMonj.companyName
                    });
                });
            });*/
            /*Beacon('init', '2dadce78-ad0c-4281-870d-fb3d44041250');
            Beacon('prefill', {
                name: $scope.user.userMonj.displayName,
                email: $scope.user.userMonj.companyEmail,
            });*/

            // Check for and initiate awards
            $timeout(function() {   // Give the application time for the data to transfer
                var profile = dataTransferFactory.get('updatedProfile');
                var outcome = dataTransferFactory.get('updatedOutcome');
                if(outcome && profile) {    // If updated award and user data is found....
                    awardsFactory.checkForAwards(outcome, profile, $scope.user, false, false); // Initiate Award ceremony
                    dataTransferFactory.remove('updatedProfile');
                    dataTransferFactory.remove('updatedOutcome');
                }
            }, 1000);

            $timeout(function() {
                if($scope.user.userMonj.challenge) {
                    if ($scope.user.userMonj.challenge.team.teamId) {
                        $('.ae-challenge-container').css('height', 'auto');
                    }
                }
            });
        }

        $scope.teamUpActive = false;
        $scope.toggleTeamUp = function() {
            if(!$scope.teamUpActive) {
                $scope.teamUpActive = true;
                $('.ae-challenge-container').css('height', $('.challenge-background').outerHeight(false) + 'px');
                $('.ae-challenge-container .arrows').addClass('active');
            } else {
                $scope.teamUpActive = false;
                $('.ae-challenge-container').css('height', '80px');
                $('.ae-challenge-container .arrows').removeClass('active');
            }
        };

        $scope.toggleTeamEngaged = function() {
            if($scope.teamUpActive) {
                $scope.teamUpActive = false;
                $('.teamup-content').addClass('small-on-mobile');
                $('.teamup-content').css('height', '62px');
                $('.ae-challenge-container .arrows').addClass('active');
            } else {
                $scope.teamUpActive = true;
                $('.teamup-content').css('height', 'auto');
                $('.ae-challenge-container .arrows').removeClass('active');
                $('.teamup-content').removeClass('small-on-mobile');
            }
        };

        $scope.recipeRedirect = function(recipeId) {
            $scope.waiting = true;
            $location.path($state.href('expandedRecipe', {recipeId: recipeId}).replace('#', ''));
        };
        $scope.powerUpRedirect = function(powerUpId) {
            $scope.waiting = true;
            $location.path($state.href('powerUpView', {powerupId: powerUpId}).replace('#', ''));
        };
        $scope.essentialRedirect = function(id, type) {
            if(type) {
                $scope.waiting = true;
                $location.path($state.href('lesson', {id: id}).replace('#', ''));
            } else {
                $scope.waiting = true;
                $location.path($state.href('lesson', {id: id}).replace('#', ''));
            }
        };

        $scope.seeMoreRecipes = function(event) {
            if(showLess(event, 'recipes')) {
                $scope.recipesLimit = 200;
                $scope.powerUpsLimit = 3;
                $scope.essentialsLimit = 3;
            }
        };
        $scope.seeMorePowerUps = function(event) {
            if(showLess(event, 'powerups')) {
                $scope.recipesLimit = 3;
                $scope.powerUpsLimit = 200;
                $scope.essentialsLimit = 3;
            }
        };
        $scope.seeMoreEssentials = function(event) {
            if(showLess(event, 'essentials')) {
                $scope.recipesLimit = 3;
                $scope.powerUpsLimit = 3;
                $scope.essentialsLimit = 200;
            }
        };

        function showLess(event, item) {
            //if(event) {
                switch(item) {
                    case 'recipes':
                        return resetLimits(event, $scope.recipesLimit);
                        break;
                    case 'powerups':
                        return resetLimits(event, $scope.powerUpsLimit);
                        break;
                    case 'essentials':
                        return resetLimits(event, $scope.essentialsLimit);
                        break;
                    default:
                        return resetLimits();
                }
            //}
        }

        function resetLimits(event, limit) {
            if(limit == 3 && event) {
                $('.see-all').html(seeAll);
                $('.number-to-display').show();
                $(event.currentTarget).html(seeLess);
                $(event.currentTarget).parent().find('.number-to-display').hide();
                return true;
            } else {
                $scope.recipesLimit = 3;
                $scope.powerUpsLimit = 3;
                $scope.essentialsLimit = 3;
                $('.see-all').html(seeAll);
                $('.number-to-display').show();
                return false;
            }
        }


        $scope.getLevelColor = function(level) {
            $('.' + $scope.chefHatLevels[level]).css('background-color');
        };

        $scope.openBeacon = function() {
            //HS.beacon.open();
            Beacon('toggle');
        };

        $scope.wait = function(isSet, stateName) {
            if(!$scope.getCurrentState(stateName)) {
                $scope.waiting = isSet;
            }
        };

        $scope.toggleMenu = function(menuName) {
            $('.header-menu .' + menuName).toggleClass('invisible');
        };

        $scope.closeAllDropDowns = function() {
            $('.header-menu .drop-down').addClass('invisible');
        };

        $scope.getCurrentState = function(stateName) {
            return $state.current.name == stateName;
        };

        $scope.logout = function () {
            userFactory.logout();
        };

        $scope.toggleSearch = function() {
            // Initialize Search Object
            // Ensure content is cached to limit number of API calls.
            $scope.searchRecipes = [];
            $scope.searchPowerUps = [];
            $scope.searchEssentials = [];
            userFactory.getRecipes($scope.user.userMonj.userId).then(function (recipes) {
                // Remove all extra fields not used in our global search.
                for(var x = 0; x < recipes.length; x++) {
                    var recipe = {};
                    recipe.name = recipes[x].name;
                    recipe.ingredients = recipes[x].ingredients;
                    recipe.thumbUrl = recipes[x].thumbUrl;
                    recipe.recipeId = recipes[x].recipeId;
                    $scope.searchRecipes.push(recipe);
                }
                userFactory.getPowerUps($scope.user.userMonj.userId).then(function (powerUps) {
                    // Split the ingredients by ',' and create an array
                    powerUps = powerUps.filter(function(result) {
                        if(typeof result.ingredients === 'string') {
                            result.ingredients = result.ingredients.split(',');
                        }
                        return result;
                    });

                    // Remove all extra fields not used in our global search.
                    for(var x = 0; x < powerUps.length; x++) {
                        var powerup = {};
                        powerup.name = powerUps[x].name;
                        powerup.ingredients = powerUps[x].ingredients;
                        powerup.thumbUrl = powerUps[x].thumbUrl;
                        powerup.powerUpId = powerUps[x].powerUpId;
                        $scope.searchPowerUps.push(powerup);
                    }
                    userFactory.getlessons($scope.user.userMonj.userId).then(function (essentials) {

                        // Remove all extra fields not used in our global search and store it into our first array
                        var arr1 = [];
                        for(var x = 0; x < essentials.length; x++) {
                            var essential = {};
                            essential.name = essentials[x].name;
                            //essential.ingredients = essentials[x].ingredients;
                            essential.thumbUrl = essentials[x].thumbUrl;
                            essential.moduleId = essentials[x].lessonId;
                            //arr1.push(essential);
                            $scope.searchEssentials.push(essential);
                        }

                        /*$scope.videos = recipesFactory.getResourceLibrary().filter(function (e) { return e.type == "Video"; });

                        // Remove all extra fields not used in our global search and store it in our second array
                        var arr2 = [];
                        // update video data to match displayable fields
                        for(var x = 0; x < $scope.videos.length; x++) {
                            var video = {};
                            video.name = $scope.videos[x].title;
                            video.ingredients = $scope.videos[x].ingredients;
                            video.thumbUrl = $scope.videos[x].thumbnail;
                            video.moduleId = $scope.videos[x].id;
                            video.type = $scope.videos[x].type;
                            arr2.push(video);
                        }

                        // combine them to create a single searchable array
                        $scope.searchEssentials = combineArrays(arr1, arr2);*/
                    });
                });
            });

            $('.header-container input[type="text"]').toggleClass('active').focus();
            $('.header-container .search-bar').toggleClass('active');
            $('.header-container li > a').toggleClass('invisible');
            $('.header-container .header-monj-icon').toggleClass('invisible');
            $('.header-container .fa-search.mobile').toggleClass('invisible');
            $('.header-container .search-icon-header.mobile').toggleClass('invisible');
            $('.header-container .hamburger-menu-icon').toggleClass('invisible');
            $('.header-container .my-account-item').toggleClass('invisible');
            $scope.closeAllDropDowns();

            $timeout(function() {
                $('.header-container input[type="text"]').focus();
            }, 400);
        };

        function combineArrays(arr1, arr2) {
            var arr3 = [];
            for(var i in arr1){
                var shared = false;
                for (var j in arr2)
                    if (arr2[j].name == arr1[i].name) {
                        shared = true;
                        break;
                    }
                if(!shared) arr3.push(arr1[i])
            }
            return arr3.concat(arr2);
        }
        
        $scope.highlightText = function(searchString, displayText, index) {
            if(displayText) {
                var comma = '';
                if (index >= 0) {
                    comma = ', ';
                    if (index >= 2) {
                        comma = '...';
                    }
                }
                displayText = displayText.replace(new RegExp('(' + searchString + ')', 'gi'), '<span class="bold">$1</span>');
                return $sce.trustAsHtml(displayText + comma);
            }
        };

        $scope.toggleMobileMenu = function() {
            $('.header-container .mobile-header-menu').toggleClass('active');
            $('.mobile-menu-background').toggleClass('hide');
        };
        $scope.closeMobileMenu = function() {
            $('.header-container .mobile-header-menu').removeClass('active');
            $('.mobile-menu-background').addClass('hide');
        };

        $scope.unfocusSearch = function() {
            var searchInput = document.getElementById('search-box').value;
            if(!searchInput) {
                $scope.hideSearch();
            }
        };

        $scope.resetSearch = function() {
            // Clear the input field when user hits the X button
            document.getElementById('search-box').value = "";
            showLess();
            $scope.hideSearch();
        };

        // Hide the search box and display the normal menu
        $scope.hideSearch = function() {
            $('.header-container input[type="text"]').removeClass('active');
            $('.header-container .search-bar').removeClass('active');
            $('.header-container li > a').removeClass('invisible');
            $('.related-search-bar').addClass('hidden');
            $('.search-modal-background').addClass('hide');
            $('.header-container .header-monj-icon').removeClass('invisible');
            $('.header-container .fa-search.mobile').removeClass('invisible');
            $('.header-container .search-icon-header.mobile').removeClass('invisible');
            $('.header-container .hamburger-menu-icon').removeClass('invisible');
            $('.header-container .my-account-item').removeClass('invisible');
        };

        // Waiting until the userMonj is set to initialize the data
        function seqInit () {
            if ($scope.user.userMonj) {
                init();
            }
            else {
                if($scope.user.authData && $scope.user.userMonjPromise) {
                    $scope.user.userMonjPromise.then(function (result) {
                        init();
                    });
                }
            }
        }

        seqInit();

        // Load on document ready
        $(document).ready(function() {
            // Monitor search-box text input for changes
            $('#search-box').keyup(function() {
                if(document.getElementById('search-box').value.length >= 2) {
                    $('.related-search-bar').removeClass('hidden');
                    $('.search-modal-background').removeClass('hide');
                } else {
                    $('.related-search-bar').addClass('hidden');
                    $('.search-modal-background').addClass('hide');
                }
            });
        });

        $scope.closeDisallowedMessage = function() {
            $('.add-action-not-allowed').removeClass('show');
            $('.awards-background').addClass('hide');
        };

        $scope.scrollToTop = function () {
            $('html, body').animate({ scrollTop: 0 }, 100);
        };

        $scope.redirectTo = function (url) {
            $location.path(url);
        };

        $scope.homeButton = function() {
            $scope.redirectTo('home');
        };

        $scope.recipeFilter = function(item, index, ingredientsArray) {
            for(var x = 0; x < ingredientsArray.length; x++) {
                if(ingredientsArray[x].includes($scope.searchString)) {
                    return false;
                }
            }
            return true;
        };
    };

    angular.module('monjApp')
        .controller('HeaderController', ['$templateCache', '$route','$scope', '$q', '$log', '$location', '$state', '$sce', '$timeout', '$translate', 'dataTransferFactory', 'userFactory', 'awardsFactory', HeaderController]);

    // Create a new filter for ng-repeats that allows us to order by search query.
    angular.module('monjApp').filter('ingredientRank', function() {
        return function (obj, searchString) {
            if(searchString) {
                if (searchString.length > 1) {
                    if (!obj) {
                        return obj;
                    }

                    for (var x = 0; x < obj.length; x++) {
                        var y = 0;
                        if (obj[x].toLowerCase().includes(searchString.toLowerCase()) && x > y) {
                            y = matchIndex(obj, y, searchString);
                            var b = obj[y];
                            obj[y] = obj[x];
                            obj[x] = b;
                            return obj;
                        }
                    }
                    return obj;
                } else {
                    return obj;
                }
            }
        }
    });

    // Loop through the object until last sequential item that doesn't include the search string and return that index.
    function matchIndex(obj, index, searchString) {
        if(obj[index].toLowerCase().includes(searchString.toLowerCase())) {
            index++;
            matchIndex(obj, index, searchString);
        }
        return index;
    }
}());
