(function () {

    var filterFactory = function ($q, $timeout) {
        var factory = {};

        factory.updateMasonry = false;

        factory.filteredTags = {};
        factory.recipeFilteredTags = {};
        factory.recipeFilters = [];
        factory.transparentItems = {};
        factory.completedRecipes = {};

        /*********************************
         * Getters and Setters for factory fields defined above
         * @param key
         * @param value
         */

        factory.setFilteredTag = function(key, value) {
            if(key)
            {
                let filter = {
                    name: value,
                    id: key
                };
                factory.recipeFilters.forEach((category)=>{
                    category.tags.forEach((tag)=>{
                        if(tag.tag == key){
                            if(category.categoryKey == 2){
                                filter.category = "preference";
                            }
                        } 
                    })
                });
                factory.filteredTags[key] = filter;
                // console.log("setFilteredTag",factory.recipeFilters,factory.filteredTags);

            }
        };

        factory.getFilteredTag = function(key) {
            return factory.filteredTags[key];
        };

        factory.getFilteredTags = function() {
            return factory.filteredTags;
        };

        factory.clearFilteredTags = function() {
            factory.filteredTags = {};
        };

        factory.clearRecipeFilteredTags = function() {
            factory.recipeFilteredTags = {};
        };
        factory.setTransparentItem = function(key, value) {
            factory.transparentItems[key] = value;
        };

        factory.getTransparentItem = function(key) {
            return factory.transparentItems[key];
        };

        factory.getTransparentItems = function() {
            return factory.transparentItems;
        };

        // This setter always sets the recipe id key to true, marking that recipe as completed.
        factory.setCompletedRecipe = function(key) {
            factory.completedRecipes[key] = true;
        };

        factory.getCompletedRecipe = function(key) {
            return factory.completedRecipes[key];
        };

        factory.getCompletedRecipes = function() {
            return factory.completedRecipes;
        };

        /**
         * End Getters and Setters
         ***********************************************/


        /************************************************
         * Perform a filter event, updating the filtered tags based on the selection
         * @param evt
         * @param alwaysClear
         * @returns {{}|*}
         */
        factory.filterClick = function(evt, clearAllSiblings) {
            // Tell masonry to reload it's layout once angular has finished updating the list
            factory.updateMasonry = true;
            var div = $(evt.currentTarget);

            var p = div.find('p');
            var iconDiv = div.find('div');

            // If filter is already active, then toggle it.
            if(p.hasClass('active-filter') && iconDiv.hasClass('active-filter')) {
                p.removeClass('active-filter');
                iconDiv.removeClass('active-filter');
                delete factory.filteredTags[div.attr('id')];
            } else {    // Otherwise, add the filter to the list
                // If this is checked, all items within the UL block will only allow a single item to be selected at a time.
                if(clearAllSiblings) {
                    // Clear all other selections
                    clearFilters(evt);
                }

                // If the tag doesn't already exist, add it to the list.
                if (!factory.filteredTags[div.attr('id')]) {
                    factory.setFilteredTag(div.attr('id'), div.text().trim());
                }

                // Update HTML elements with active classes to be controlled via CSS.
                p.addClass('active-filter');
                iconDiv.addClass('active-filter');
            }

            return factory.filteredTags;
        };

        /***********************************************
         * Updates the recipe list with completed items
         * @param comp - Expects the user object's completedRecipeIds field
         * @returns {{}|*}
         */
        factory.updateCompleted = function(comp) {
            for(var i = 0; i < comp.length; i++) {
                factory.completedRecipes[comp[i]] = true;
            }
            return factory.completedRecipes;
        };

        factory.updateLabelHeights = function(itemLabels) {
            itemLabels = $(itemLabels).find('p');
            var maxHeight = 0;
            // Reset heights
            itemLabels.css('height', 'auto');
            itemLabels.each(function() {
                // Compare heights
                maxHeight = Math.max($(this).innerHeight(), maxHeight);
            });
            // Update heights
            itemLabels.css('height', maxHeight +'px');
        };

        /**********************************************
         * Clears ALL filters related to the specified element (all the elements siblings)
         * @param evt - The event object from the selected element
         * @returns {{}|*}
         */
        function clearFilters(evt) {
            // Select the parent UL to clear all sibling LI filters
            var parent = evt.currentTarget.parentNode;
            var siblings = $(parent).find('li');
            siblings.each(function() {
                // Remove selections
                var p = $(this).find('p');
                var ex = $(this).find('.filter-x');
                p.removeClass('active-filter');
                ex.removeClass('active-filter');

                // Remove items from tag list
                delete factory.filteredTags[$(this).attr('id')];
                /*var tag = factory.filteredTags[$(this).attr('id')];
                if (tag >= 0) {
                    factory.filteredTags.splice(tag, 1);
                }*/
            });

            // Return updated tags
            return factory.filteredTags;
        }

        /************************************
         * Clears a single filter, for when user clicks the "X" icon next to their selection
         * @param evt
         * @returns {{}|*}
         */
        factory.filterClear = function(evt, clearTag) {
            factory.updateMasonry = true;
            var tagRelation = $(evt.currentTarget.parentNode);
            var iconDiv = $(evt.currentTarget);
            var p = $(evt.currentTarget.parentNode).find('p');
            if(clearTag) {
                tagRelation = $('#'+ $(evt.currentTarget.parentNode).find('p').attr('data-id'));
                iconDiv = tagRelation.parent().find('div');
                p = tagRelation.parent().find('p');
            }

            p.removeClass('active-filter');
            iconDiv.removeClass('active-filter');

            delete factory.filteredTags[tagRelation.attr('id')];

            // Return updated tags
            return factory.filteredTags;
        };


        factory.updateMasonryList = function(itemGrid) {
            $timeout(function() {
                factory.updateLabelHeights(itemGrid);
                if(itemGrid.masonry()) {
                    itemGrid.masonry('reloadItems');
                    $timeout(function() {
                        itemGrid.masonry('layout');
                        $timeout(function() {
                            itemGrid.masonry('layout');
                        }, 400);
                    }, 100);
                }
            }, 100);
        }
        factory.checkIfSwapAvailable = function(preference,recipe){
            isSwapAvailable= false;            
            if(recipe && recipe.contentFul && recipe.contentFul.fields.ingredients){
                recipe.contentFul.fields.ingredients.forEach((ingredient) => {
                    if(ingredient.fields.substitution){
                        ingredient.fields.substitution.forEach((substitution)=>{
                            if(substitution.fields.because){
                                if(substitution.fields.because.join(",").toLowerCase().indexOf(preference)>=0){
                                    isSwapAvailable=true;
                                }                                
                            }
                        });
                    }                    
                });
            }
            return isSwapAvailable;
        }
                
        factory.itemsFilter = function(item, index, array, itemGrid) {
            if(index == 0){
                factory.transparentItems = [];
            }
            // Works in IE 9 and higher
            var numOfFilters = Object.keys(factory.filteredTags).length;

            if(numOfFilters > 0) {
                for(var j = 0; j < numOfFilters; j++) {
                    if(item.tags.indexOf(Object.keys(factory.filteredTags)[j]) < 0) {
                        factory.transparentItems.push(item);
                        if(index >= array.length - 1 && factory.updateMasonry) {
                            factory.updateMasonry = false;
                            factory.updateMasonryList(itemGrid);
                        }
                        return false;
                    }
                }
            }
            if(index >= array.length - 1 && factory.updateMasonry) {
                factory.updateMasonry = false;
                factory.updateMasonryList(itemGrid);
            }
            return true;
        };

        factory.itemsFilterRecipes = function(item, index, array, itemGrid) {
            if(index == 0){
                factory.transparentItems = [];
            }
            // Works in IE 9 and higher
            var preferenceFilters= {};
            var otherTags={}
            for (const key in factory.filteredTags) {
                if (Object.hasOwnProperty.call(factory.filteredTags, key)) {
                    const filteredTag=  factory.filteredTags[key];
                    if(filteredTag.category == "preference"){
                        preferenceFilters[key] = filteredTag;
                    }
                    else{
                        otherTags[key] = filteredTag;
                    }
                }
            }
            var numOfFilters = Object.keys(otherTags).length;
            var numOfPrefFilters = Object.keys(preferenceFilters).length;
            
            var isFilteredFromOthers = false;
            var isFilteredPreference = false;
            if(numOfFilters > 0) {
                for(var j = 0; j < numOfFilters; j++) {
                    if(item.tagsV2.indexOf(Object.keys(otherTags)[j]) < 0) {
                        isFilteredFromOthers=true;
                    }
                    else{
                        isFilteredFromOthers=false;
                        break;
                    }
                }
            }

            if(numOfPrefFilters > 0) {
                for(var j = 0; j < numOfPrefFilters; j++) {
                    let pref=Object.keys(preferenceFilters)[j];
                    if(item.tagsV2.indexOf(pref) < 0) {
                        // if avoid protein then vegan/vegetarian items goes
                        if(pref=="meatfree" && 
                        (/* item.tagsV2.indexOf("vegan") >= 0 
                        || */item.tagsV2.indexOf("vegetarian") >= 0
                       /*  ||factory.checkIfSwapAvailable("vegan",item)
                        ||factory.checkIfSwapAvailable("vegetarian",item) */
                        )){   
                            isFilteredPreference=false;
                        }
                        else{
                            isFilteredPreference=true;
                            //if not match then no need to check for other tag value
                            break;
                        }
                        /* if(isFilteredPreference){
                            //check if ingredient swap available for preference
                            if(factory.checkIfSwapAvailable(pref,item)){
                                isFilteredPreference=false;
                            }
                            else{
                                isFilteredPreference=true;
                                break;
                            }
                        } */
                    }
                    else{
                        isFilteredPreference=false;
                    }
                }
            }

            if(isFilteredFromOthers ||  isFilteredPreference){
                factory.transparentItems.push(item);
                if(index >= array.length - 1 && factory.updateMasonry) {
                    factory.updateMasonry = false;
                    factory.updateMasonryList(itemGrid);
                }
                return false;
            }

            if(index >= array.length - 1 && factory.updateMasonry) {
                factory.updateMasonry = false;
                factory.updateMasonryList(itemGrid);
            }
            return true;
        };

        /************************************************
         * Clear filters in UI
         * @returns {}
         */
         factory.filterUIClearAll = function() {
            // Tell masonry to reload it's layout once angular has finished updating the list
            factory.updateMasonry = true;
            var filterList = $(".js-filter-list");
            filterList.each((index, element)=>{               
                $(element).each((i,filter)=>{
                    var p = $(filter).find('p');
                    var iconDiv = $(filter).find('div');
                    // If filter is already active, then toggle it.
                    if(p.hasClass('active-filter') && iconDiv.hasClass('active-filter')) {
                        p.removeClass('active-filter');
                        iconDiv.removeClass('active-filter');
                        // delete factory.filteredTags[div.attr('id')];
                    } 
                })

            });
            

            return true;
        };

        return factory;
    };

    angular.module('monjApp').factory('filterFactory', ['$q', '$timeout', filterFactory]);

}());
