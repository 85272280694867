(function() {

    var UgcPostController = function ($scope, $location, $timeout, $state, $stateParams, userFactory, $translate, appSettings, awardsFactory, dataTransferFactory) {

        var monjApiKey = appSettings.monjApiKey;
        var monjURL = appSettings.monjURL;

        $scope.isWidget = false;
        var route = $state.current.name;
        if(route === 'guidedLessonWidget' || route === 'expandedLessonWidget' || route === 'guidedRecipeWidget' ||
        route === 'expandedRecipeWidget' || route === 'powerUpViewWidget' || route === 'essentialsWidget' ||
        route === 'dailyMissionWidget') {
            $scope.isWidget = true;
        }

        $scope.completedMessage = dataTransferFactory.get('completedMessage');
        dataTransferFactory.remove('completedMessage');

        $scope.getNewLevel = function() {
            return awardsFactory.getNewLevel();
        };

        $scope.getCompletedMessage = function() {
            return awardsFactory.getCompletedMessage();
        };

        $scope.closeAwards = function() {
            $('.popup-modal').removeClass('active');
            $('.modal-background').addClass('hide');
            $('.ugc-post-tools').removeClass('active');
            if($state.current.name !== 'authExperience' && $state.current.name !== 'inspiration' && $state.current.name !== 'teamChallenge') {
                $location.path($state.get('authExperience').url);
            } else {
                $('.completed-animation').removeClass('active');
                if($state.current.name !== 'authExperience') {
                    // Reload data if there's any data to reload.
                    monjugc.reload();
                }
            }
        };

        var ugcId = null;
        var relatedId = 1;
        var mediaType = false;
        $('.ugc-image-upload').fileupload({
            dataType: 'json',
            add: function (e, data) {
                // Check for allowed file types
                var fileType = data.files[0].name.toLowerCase().split('.').pop(), allowdtypes = 'jpeg,jpg,png,gif';
                if (allowdtypes.indexOf(fileType) < 0) {
                    console.log('File Upload Aborted due to Invalid File Type');
                    return false;
                }

                var contentTitle = dataTransferFactory.get('contentTitle');
                dataTransferFactory.remove('contentTitle');

                switch($state.current.name) {
                    case 'authExperience':
                        mediaType = 'communityimage';
                        $translate('ugc.mission', {title: contentTitle}).then(function (translation) {
                            $("#userComments").val(translation);
                        });
                        break;
                    case 'guidedRecipe':
                        mediaType = 'mealimage';
                        relatedId = $stateParams.recipeId;
                        $translate('ugc.recipe', {title: contentTitle}).then(function (translation) {
                            $("#userComments").val(translation);
                        });
                        break;
                    case 'lesson':
                        mediaType = 'communityimage';
                        $translate('ugc.lesson', {title: contentTitle}).then(function (translation) {
                            $("#userComments").val(translation);
                        });
                        break;
                    case 'guidedLesson':
                        mediaType = 'communityimage';
                        $translate('ugc.lesson', {title: contentTitle}).then(function (translation) {
                            $("#userComments").val(translation);
                        });
                        break;
                    case 'powerUpView':
                        mediaType = 'powerupimage';
                        relatedId = $stateParams.powerupId;
                        $translate('ugc.powerup', {title: contentTitle}).then(function (translation) {
                            $("#userComments").val(translation);
                        });
                        break;
                    case 'teamChallenge':
                        mediaType = 'companychallengeimage';
                        relatedId = dataTransferFactory.get('challengeId');
                        $translate('ugc.challenge', {title: contentTitle}).then(function (translation) {
                            $("#userComments").val(translation);
                        });
                        break;
                    default:
                        mediaType = 'communityimage';
                        break;
                }

                data.url = monjURL.replace("/v1", "") + "/upload/media.ashx?apiKey=" + monjApiKey +
                    "&userId=" + $scope.user.userMonj.userId + "&relatedId=" + relatedId + "&mediaType=" + mediaType;

                $('.post-popup-container').addClass('active');
                $('.modal-background.awards').removeClass('hide');

                $(".uploaded-image").css("background-image", "");
                $(".ugc-image-progress").css("width", "0").removeClass("processing").show();
                data.submit();
            },
            progressall: function (e, data) {
                var progress = parseInt(data.loaded / data.total * 100, 10);
                if (progress < 100) {
                    $(".ugc-image-progress").css("width", progress + "%");
                }
                else {
                    $(".ugc-image-progress").css("width", "100%").addClass("processing");
                }
            },
            done: function (e, data) {
                $(".ugc-image-progress").hide().removeClass("processing");
                $.each(data.result, function (index, file) {
                    // Check for error
                    if (file.error) {
                        $(".ugc-image-progress").hide().removeClass("processing");
                        //alert(file.error);
                    }
                    else {
                        $(".uploaded-image")
                            .css("background-image", "url(" + file.thumbnail_url + ")")
                            .show();

                        ugcId = data.result[0].mediaFileId;
                    }
                });
            },
            fail: function (e, data) {
                $(".ugc-image-progress").hide().removeClass("processing");
                console.log(data.jqXHR.responseText);
            }
        });

        $('.ugc-post-comment').click(function() {
            var comment = $("#userComments").val();

            if(ugcId) {
                userFactory.submitPostComment(ugcId, comment).then(function() {
                    $(".uploaded-image").css("background-image", "none");
                    $('.popup-modal').removeClass('active');
                    $('.modal-background.awards').addClass('hide');

                    // Redirect to view uploaded content and close window (if it's still open - case, redirect is to same page)
                    /*if(mediaType == 'communityimage') { $location.path($state.get('inspiration').url); }
                    if(mediaType == 'mealimage') { $location.path($state.href('guidedRecipe', {recipeId: $stateParams.recipeId}).replace('#', '')); }
                    if(mediaType == 'powerupimage') { $location.path($state.href('powerUpView', {powerupId: $stateParams.powerupId}).replace('#', '')); }*/

                    if($state.current.name != 'inspiration' && $state.current.name != 'teamChallenge') {
                        $location.path($state.get('authExperience').url);
                    }

                    // Reload data if there's any data to reload.
                    monjugc.reload();

                    $('.completed-animation').removeClass('active');
                });
            } else {
                console.log("error: no ugcId was found for the uploaded image");
            }
        });
    };

    angular.module('monjApp')
        .controller('UgcPostController', ['$scope', '$location', '$timeout', '$state', '$stateParams', 'userFactory', '$translate', 'appSettings', 'awardsFactory', 'dataTransferFactory', UgcPostController]);

}());
