/**
 * Created by Jared Beckerman on 4/20/2017.
 */

(function () {

    var filterTextFactory = function ($rootScope, userFactory, appSettings, $q, $timeout) {
        var factory = {};
        var flowplayerSubtitles = false;

        factory.filterText = function(data) {
            data = data.replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"');
            var temp = marked(data);
            temp = factory.markdownToHtml(temp);
            return temp ? temp.replace(/<b>/g, '<strong>').replace(/<\/b>/g, '<\/strong>')
                .replace(/<i>/g, '<em>').replace(/<\/i>/g, '<\/em>') : ' ';
        };

        factory.filterDictionaryEntry = function(entry) {
            return entry ? escape(entry.replace(/\s+/g, " ").trim().toLowerCase()) : null;
        };

        factory.markdownToHtml = function(original) {
            if (original) {
                var markedHtml = marked(original);
                var imageFixed = markedHtml.indexOf('img') != -1 && markedHtml.indexOf('width') == -1 ? markedHtml.replace(/<img/g, '<img width=\"100%\"') : markedHtml;

                if (original.indexOf('http') == -1 || original.indexOf('monj.com') == -1) {
                    return imageFixed.replace(/<a/g, '<a target=\"_blank\"');
                }
                else {
                    return imageFixed;
                }
            }
            else {
                return null;
            }
        };

        factory.flowplayerFilter = function(original) {
            return original ? original.replace(/src=\"\/\//g, "src=\"https://") : null;
            /*if (appSettings.isProduction) {
                return original ? original.replace(/src=\"\/\//g, "src=\"https://") : null;
            } else {
                return original;
            }*/
        };

        factory.updateProtocols = function(original) {
            return original ? original.replace(/\/\//g, "https://") : null;
        };

        factory.addSubtitlesToVideo = function(embedCode) {
            var deferred = $q.defer();

            userFactory.getVTTs().then(function(data) {
                if (embedCode) {
                    var sourceStart = embedCode.indexOf('235928/');	// Our Flowplayer ID
                    if (sourceStart > -1) { // If the ID is found...
                        sourceStart += 7; // Point to the end of the searched string (after the "/")
                        var sourceEnd = embedCode.indexOf('-', sourceStart); // Look for the end of the video id (sometimes a "-")
                        if (sourceEnd == -1) {
                            sourceEnd = embedCode.indexOf('.', sourceStart); // Othertimes, it's a "." for the filetype
                        }

                        var idString = embedCode.substring(sourceStart, sourceEnd); // Grab the video id

                        // Find position of intial div closing tag and append ID Subtitle data
                        var closingDiv = embedCode.indexOf('>');

                        // Find position of final source call to append VTT <track> data
                        var lastSourceTag = embedCode.lastIndexOf('<source');
                        var finalSourcePos = embedCode.indexOf('>', lastSourceTag) + 1;

                        // Determine the default language for the user
                        var spanDefault = '';
                        if ($rootScope.lang == 'es') {
                            spanDefault = 'default';
                        }

                        var engUrl = '//monj.azureedge.net/vtt/' + idString + '-en.vtt';
                        var spanUrl = '//monj.azureedge.net/vtt/' + idString + '-es.vtt';

                        var englishTrack = '';
                        var spanishTrack = '';

                        var englishTranslationExists = data.filter(function (elem) {
                            return elem == idString + '-en.vtt';
                        });

                        var spanishTranslationExists = data.filter(function (elem) {
                            return elem == idString + '-es.vtt';
                        });

                        if (englishTranslationExists.length > 0) {
                            englishTrack = '<track kind="subtitles" srclang="en" label="English" src="' + engUrl + '">';
                        }

                        if (spanishTranslationExists.length > 0) {
                            spanishTrack = '<track kind="subtitles" ' + spanDefault + ' srclang="es" label="Spanish" src="' + spanUrl + '">';
                        }

                        var isAndroid = /(android)/i.test(navigator.userAgent);

                        if ($rootScope.lang == 'es') {
                            if (isAndroid) {
                                var output = [embedCode.slice(0, closingDiv), embedCode.slice(closingDiv, finalSourcePos), spanishTrack, embedCode.slice(finalSourcePos)].join('');

                            } else {
                                var output = [embedCode.slice(0, closingDiv), embedCode.slice(closingDiv, finalSourcePos), spanishTrack, englishTrack, embedCode.slice(finalSourcePos)].join('');
                            }
                        } else {
                            if ($rootScope.isSafari || isAndroid) {
                                var output = embedCode;
                            } else {
                                var output = [embedCode.slice(0, closingDiv), embedCode.slice(closingDiv, finalSourcePos), englishTrack, spanishTrack, embedCode.slice(finalSourcePos)].join('');
                            }
                        }
                        deferred.resolve(output);
                    }
                } else {
                    deferred.resolve(embedCode);
                }
            });

            return deferred.promise;
        };

        return factory;
    };

    angular.module('monjApp').factory('filterTextFactory', ['$rootScope', 'userFactory', 'appSettings', '$q', '$timeout', filterTextFactory]);

}());
