(function () {

	var ajaxWithCacheFactory = function ($q) {
		var factory = {};

		// Cached Data
		factory.cache = {};

		// Custom Flags to Be Used in "ajax" Function to Control Caching
		factory.AUTO_CACHE = 0;
		factory.NO_CACHE = 1;
		factory.CACHE_ONLY = 2;

		factory.setCache = function (key, value) {
			factory.cache[key] = value;
		};

		factory.getCache = function (key) {
			return factory.cache[key];
		};

		factory.removeCache = function (key) {
			if (factory.cache[key]) {
				delete factory.cache[key];
			}
		};

		factory.checkCache = function (key) {
			return (typeof factory.cache[key] != "undefined") ? true : false;
		};

		// Unified AJAX Call with Caching Integrated
		//
		// callSettings:			Object		[Required]	(Same Configuration Object Passed to AJAX Call)
		// cacheFlag:					function	[Optional]	(Custom Flag to Control Caching. Default Value = AUTO_CACHE)
		// callbackOnSuccess:	function	[Optional]	(Callback Function to Be Called Before Resolving on Success)
		// callbackOnFail:		function	[Optional]	(Callback Function to Be Called Before Rejecting on Fail)
		//
		factory.ajax = function (callSettings, cacheFlag, callbackOnSuccess, callbackOnFail) {
			var deferred = $q.defer();

			cacheFlag = cacheFlag ? cacheFlag : factory.AUTO_CACHE;

			if (cacheFlag === factory.CACHE_ONLY) { // If CACHE_ONLY
				if (factory.checkCache(callSettings.url)) { // If Already Cached, Resolve with Data
					var data = factory.getCache(callSettings.url);

					if (callbackOnSuccess && callbackOnSuccess instanceof Function) { // If Callback Function was Passed, Call it First
						callbackOnSuccess(data);
					}

					deferred.resolve(data);
				}
				else { // If Not Already Cached, Reject with a Message (No AJAX Call)
					if (callbackOnFail && callbackOnFail instanceof Function) { // If Callback Function was Passed, Call it First
						callbackOnFail("Not Available in the Cache");
					}

					deferred.reject("Not Available in the Cache");
				}
			}
			else if (cacheFlag === factory.AUTO_CACHE && factory.checkCache(callSettings.url)) { // If AUTO_CACHE and Already Cached, Otherwise Make the AJAX Call
				var data = factory.getCache(callSettings.url);

				if (callbackOnSuccess && callbackOnSuccess instanceof Function) { // If Callback Function was Passed, Call it First
					callbackOnSuccess(data);
				}

				deferred.resolve(data);
			}
			else { // Make the AJAx Call
				$.ajax(callSettings)
					.done(function(data, textStatus) {
						if (callbackOnSuccess && callbackOnSuccess instanceof Function) { // If Callback Function was Passed, Call it First
							callbackOnSuccess(data);
						}

						factory.setCache(callSettings.url, data);

						deferred.resolve(data);
					})
					.fail(function(jqXHR, textStatus, errorThrown) {
						if (callbackOnFail && callbackOnFail instanceof Function) { // If Callback Function was Passed, Call it First
							callbackOnFail(textStatus, errorThrown);
						}

						Raygun.send(new Error("AJAX Error: " + errorThrown + ": " + callSettings.url.split("?")[0]));

						deferred.reject(JSON.parse(jqXHR.responseText));
					});
			}

			return deferred.promise;
		};

		return factory;
	}

	angular.module('monjApp').factory('ajaxWithCacheFactory', ['$q', ajaxWithCacheFactory]);

}());
