//hello world
(function() {

    var InspirationController = function ($scope, userFactory, $state, userInit, appSettings, $location) {
        $scope.user = userInit;
        $scope.popUpImgThumb = null;
        $scope.currRecipe = null;
        $scope.hashtag = "#MonjLife";

        // Log this page view for analytics.
        analytics.page({
            title: 'InspirationController',
            url: $location.absUrl(),
            path: $location.path(),
            referrer: document.referrer
        });

        var monjApiKey = appSettings.monjApiKey;
        var monjURL = appSettings.monjURL;
        var ugcId = null;

        // If a hashtag exists for this challenge, override the default hashtag
        /*if($scope.user.userMonj.challenge.hashtag) {
            $scope.hashtag = $scope.user.userMonj.challenge.hashtag;
        }*/

        $scope.createUGCContent = function() {
            $('#featured-inspiration-items').empty();
            $('#inspiration-list').empty();

            monjugc.init({
                contentType: 'communities',
                id: '1',
                featured: true,
                carousel: true,
                disableInfiniteScroll: true,
                monjApiKey: monjApiKey,
                monjUrl: monjURL,
                userId: $scope.user.userMonj.userId,
                navigationArrows: true
            }, "featured-inspiration-items");

            // Initiate UGC
            monjugc.init({
                contentType: 'communities',
                id: '1',
                featured: false,
                carousel: false,
                disableInfiniteScroll: false,
                monjApiKey: monjApiKey,
                monjUrl: monjURL,
                userId: $scope.user.userMonj.userId,
                navigationArrows: false
            }, "inspiration-list");
        }

        function init() {
        };

        init();
    };

    angular.module('monjApp')
        .controller('InspirationController', ['$scope', 'userFactory', '$state', 'userInit', 'appSettings', '$location', InspirationController]);

}());
